<div class="order-summary">
  <h4 class="font-bold mb-4">{{ title }}</h4>
  <table aria-describedby="bounty-info">
    <tbody>
      <tr *ngIf="submissionFee">
        <td>
          <label class="flex items-center text-base font-semibold">
            {{ isDistributionPurchase ? 'Price' : 'Submission Fee' }}
          </label>
        </td>
        <ng-container *ngTemplateOutlet="amountInput; context: { $implicit: submissionFee }"></ng-container>
      </tr>
      <tr *ngIf="moderationFee">
        <td>
          <label class="flex items-center text-base font-semibold"> Moderation Fee </label>
        </td>
        <ng-container *ngTemplateOutlet="amountInput; context: { $implicit: moderationFee }"></ng-container>
      </tr>
      <tr *ngIf="resubmissionFee && !isDistributionPurchase">
        <td>
          <span class="text-base font-semibold">Resubmission Fee</span>
        </td>
        <ng-container *ngTemplateOutlet="amountInput; context: { $implicit: resubmissionFee }"></ng-container>
      </tr>
      <ng-container *ngIf="!isDistributionPurchase && showMinBounty">
        <tr *ngIf="!resubmissionFee">
          <td>
            <span class="text-base font-semibold">Reward Contribution</span>
          </td>
          <ng-container
            *ngTemplateOutlet="amountInput; context: { $implicit: minimumBounty + additional }"
          ></ng-container>
        </tr>
        <tr>
          <td *ngIf="additional || canAdd">
            <span class="text-base font-semibold">Additional Reward Contribution</span>
          </td>
          <td *ngIf="!canAdd && additional">
            <span class="text-base font-semibold"
              >${{ paymentService.calculateFilmToDollarPricing(additional) | number : '1.0-2' }}</span
            >
          </td>
          <td class="flex w-full justify-end" style="width: 100% !important" *ngIf="canAdd">
            <div class="form-group number">
              <input
                placeholder="0"
                id="userBounty"
                type="number"
                appAutoFocus
                [(ngModel)]="additional"
                (input)="recalculateBountyValue.emit({ action: 'type', value: $event.target.value })"
                appInputNumber
                appOnlyNumber
              />
              <span>
                <button (click)="recalculateBountyValue.emit({ action: 'add' })">
                  <app-icon icon="plus"></app-icon>
                </button>
                <button (click)="recalculateBountyValue.emit({ action: 'subtract' })">
                  <app-icon icon="minus"></app-icon>
                </button>
              </span>
              <app-icon [icon]="isExternalCheckout ? 'usd' : 'film'"></app-icon>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr class="total" *ngIf="isDistributionPurchase && !isExternalCheckout">
        <td>
          <span class="font-bold text-xl">Current Exchange</span>
        </td>
        <ng-container *ngTemplateOutlet="totalCalculation"></ng-container>
      </tr>
      <tr class="total">
        <td>
          <span class="font-bold text-xl">Total</span>
        </td>
        <ng-container *ngIf="isDistributionPurchase">
          <ng-container *ngTemplateOutlet="totalCalculation"></ng-container>
        </ng-container>
        <ng-container *ngIf="!isDistributionPurchase">
          <ng-container *ngTemplateOutlet="amountInput; context: { $implicit: total, total: true }"></ng-container>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #totalCalculation let-val>
  <td>
    <p
      class="text-base font-semibold whitespace-nowrap flex items-center justify-end"
      *ngIf="paymentService.calculateFilmToDollarPricing(total) as amountTotal"
    >
      <ng-container *ngIf="!isExternalCheckout"> ${{ amountTotal | number : '1.0-2' }} = </ng-container>
      <app-icon [icon]="isExternalCheckout ? 'usd' : 'film'" class="w-16 h-16 mx-4 inline-block"></app-icon>
      {{ (isExternalCheckout ? amountTotal : total) | number : '1.0-2' }}
    </p>
  </td>
</ng-template>
<ng-template #amountInput let-amount let-isTotal="total">
  <td>
    <span
      [ngClass]="isTotal ? 'text-base font-semibold' : 'text-sm font-light'"
      placement="top"
      *ngIf="paymentService.calculateFilmToDollarPricing(amount) as converted"
      [appTooltip]="
        isExternalCheckout ? (amount | number : '1.0-2') + ' FILM' : (converted | number : '1.0-2') + ' USD'
      "
    >
      <app-icon
        [icon]="isExternalCheckout ? 'usd' : 'film'"
        [ngClass]="isTotal ? 'w-16 h-16' : 'w-12 h-12'"
        class="inline-block"
      ></app-icon>
      {{ (isExternalCheckout ? converted : amount) | number : '1.0-2' }}
    </span>
  </td>
</ng-template>
