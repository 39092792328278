import {
  trigger,
  state,
  style,
  animate,
  keyframes,
  transition,
  query,
  stagger,
  group,
  animateChild,
  sequence,
} from '@angular/animations';
import { AnimationsService } from './animations.service';

export const openDown = trigger('openDown', [
  transition(':enter', [
    style({
      flex: 0,
    }),
    animate(
      '.3s',
      style({
        flex: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      flex: 1,
    }),
    animate(
      '.3s',
      style({
        flex: 0,
      })
    ),
  ]),
]);
export const tabsAnimation = trigger('tabsAnimation', [
  transition(':enter', [
    style({
      transform: 'translateX(100%)',
    }),
    animate(
      '.3s',
      style({
        transform: 'translateX(0)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0)',
    }),
    animate(
      '.3s',
      style({
        transform: 'translateX(100%)',
      })
    ),
  ]),
]);
export const dimOut = trigger('dimOut', [
  transition(':enter', [
    style({
      opacity: 1,
    }),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '.3s 1s',
      style({
        opacity: 0,
      })
    ),
  ]),
]);
export const fade = trigger('fade', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(
      '.3s .4s',
      style({
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '.3s ease-in',
      style({
        opacity: 0,
      })
    ),
  ]),
]);
export const fadeSlow = trigger('fadeSlow', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(
      '.3s .5s',
      style({
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '.3s',
      style({
        opacity: 0,
      })
    ),
  ]),
]);
export const hoverCard = trigger('hoverCard', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0.7)',
    }),
    animate(
      '.3s .5s',
      style({
        opacity: 1,
        transform: 'translate(-50%, -50%) scale(1)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    }),
    animate(
      '.1s',
      style({
        opacity: 0,
        transform: 'translate(-50%, -50%) scale(0.7)',
      })
    ),
  ]),
]);
export const dropdown = trigger('dropdown', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(15px)',
    }),
    animate(
      '.3s .1s',
      style({
        opacity: 1,
        transform: 'translateY(0)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '.0s',
      style({
        opacity: 0,
      })
    ),
  ]),
]);

export const announceMsg = trigger('announceMsg', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translate(-50%, -60px)',
    }),

    animate(
      '.3s 5s',
      style({
        opacity: 1,
        transform: 'translate(-50%, 0)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '.3s',
      style({
        opacity: 0,
      })
    ),
  ]),
]);
export const fadeOut = trigger('fadeOut', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(
      '0s',
      style({
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '.4s ease-in',
      style({
        opacity: 0,
      })
    ),
  ]),
]);
export const panelIn = trigger('panelIn', [
  transition(':enter', [
    style({
      transform: 'translateX(100%)',
    }),
    animate(
      '.3s',
      style({
        transform: 'translateX(0)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0)',
    }),
    animate(
      '.3s',
      style({
        transform: 'translateX(100%)',
      })
    ),
  ]),
]);

export const slideInLeft = trigger('slideInLeft', [
  transition(':enter', [
    style({
      'margin-left': '-100%',
    }),
    animate(
      '.3s',
      style({
        'margin-left': 0,
      })
    ),
  ]),
  transition(':leave', [
    style({
      'margin-left': 0,
    }),
    animate(
      '.3s',
      style({
        'margin-left': '-100%',
      })
    ),
  ]),
]);
export const slideInRight = trigger('slideInRight', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateX(150%)',
    }),
    animate(
      '.3s .3s',
      style({
        transform: 'translateX(0)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0)',
      opacity: 1,
    }),
    animate(
      '.3s',
      style({
        transform: 'translateX(-100%)',
        opacity: 0,
      })
    ),
  ]),
]);

export const teaserIn = trigger('teaserIn', [
  transition(':enter', [
    style({
      'margin-right': '-520px',
    }),
    animate(
      '.3s',
      style({
        'margin-right': 0,
      })
    ),
  ]),
  transition(':leave', [
    style({
      'margin-right': 0,
    }),
    animate(
      '.3s',
      style({
        'margin-right': '-520px',
      })
    ),
  ]),
]);

export const membersIn = trigger('membersIn', [
  transition(':enter', [
    style({
      'margin-right': '-520px',
    }),
    animate(
      '.3s',
      style({
        'margin-right': 0,
      })
    ),
  ]),
  transition(':leave', [
    style({
      'margin-right': 0,
    }),
    animate(
      '.3s',
      style({
        'margin-right': '-520px',
      })
    ),
  ]),
]);
export const notificationsIn = trigger('notificationsIn', [
  transition(':enter', [
    style({
      'margin-right': '-380px',
    }),
    animate(
      '.3s',
      style({
        'margin-right': 0,
      })
    ),
  ]),
  transition(':leave', [
    style({
      'margin-right': 0,
    }),
    animate(
      '.3s',
      style({
        'margin-right': '-380px',
      })
    ),
  ]),
]);
export const itemsAnimation = trigger('itemsAnimation', [
  transition(':enter', [
    query(
      '.item',
      [
        style({ opacity: 0, transform: 'translateY(100px)' }),

        stagger(200, [
          animate(
            '1s cubic-bezier(.19,1,.22,1)',
            keyframes([
              style({
                opacity: 0,
                transformOrigin: '50% 0',
                transform: 'translateX(-50px) scale3d(0.8,0.8,0.8)',
                offset: 0,
              }),
              style({
                opacity: 1,
                transformOrigin: '50% 0',
                transform: 'translateX(0) scale3d(1,1,1)',
                offset: 1,
              }),
            ])
          ),
        ]),
      ],
      {
        optional: true,
      }
    ),
  ]),
  transition(':leave', [
    query('.item', [
      style({ opacity: 1 }),
      stagger(0, [animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0 }))]),
    ]),
  ]),
]);
export const roundsAnimation = trigger('roundsAnimation', [
  transition(':enter', [
    query(
      '.round',
      [
        style({ opacity: 0, transform: 'translateY(100px)' }),

        stagger(200, [
          animate(
            '1s cubic-bezier(.19,1,.22,1)',
            keyframes([
              style({
                opacity: 0,
                transformOrigin: '50% 0',
                transform: 'translateX(-50px) scale3d(0.8,0.8,0.8)',
                offset: 0,
              }),
              style({
                opacity: 1,
                transformOrigin: '50% 0',
                transform: 'translateX(0) scale3d(1,1,1)',
                offset: 1,
              }),
            ])
          ),
        ]),
      ],
      {
        optional: true,
      }
    ),
  ]),
  transition(':leave', [
    query(
      '.round',
      [style({ opacity: 1 }), stagger(0, [animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0 }))])],
      {
        optional: true,
      }
    ),
  ]),
]);

// OLD Animations

export function listAnimation() {
  return trigger('listAnimation', [
    transition('* => *', [
      query(
        ':enter',
        style({
          opacity: 0,
          transform: 'rotateY(20deg) scale3d(0.8,0.8,0.8)',
        }),
        { optional: true }
      ),
      query(
        ':enter',
        stagger('100ms', [
          animate(
            '0.75s cubic-bezier(.19,1,.22,1)',
            keyframes([
              style({
                opacity: 0,
                transformOrigin: '50% 0',
                transform: 'rotateY(20deg) scale3d(0.8,0.8,0.8)',
                offset: 0,
              }),
              style({
                opacity: 1,
                transformOrigin: '50% 0',
                transform: 'rotateY(0deg) scale3d(1,1,1)',
                offset: 1,
              }),
            ])
          ),
        ]),
        { optional: true }
      ),
    ]),
  ]);
}

export function slideOpenAnimation() {
  return trigger('slideOpenAnimation', [
    transition(':enter', [style({ height: 0, overflow: 'hidden' }), animate('0.3s', style({ height: '*' }))]),
    transition(':leave', [
      style({ height: '*', overflow: 'hidden' }),
      animate('0.3s', style({ height: 0, overflow: 'hidden' })),
    ]),
  ]);
}

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),

    query(':leave', [style({ opacity: 1 }), animate('1s', style({ opacity: 0 }))], { optional: true }),

    query(':enter', [style({ opacity: 0 }), animate('1s', style({ opacity: 1 }))], { optional: true }),
  ]),
]);

const q = (s, a, o = { optional: true }) => query(s, a, o);

export function routerTransition() {
  return trigger('routerTransition', [
    transition('* => *', [
      query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
      query(':enter', style({ transform: 'translateX(100%)' })),
      sequence([
        q(':leave', animateChild()),
        group([
          query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('500ms cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateX(-100%)' })),
          ]),
          q(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('500ms cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateX(0%)' })),
          ]),
        ]),
        q(':enter', animateChild()),
      ]),
    ]),
  ]);
}

export const fadeInOut = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0,
    })
  ),
  transition('void <=> *', animate(1000)),
]);

export const ROUTE_ANIMATIONS_ELEMENTS = 'route-animations-elements';

const STEPS_ALL = [
  query(':enter > *', style({ opacity: 0, position: 'fixed' }), {
    optional: true,
  }),
  query(':enter .' + ROUTE_ANIMATIONS_ELEMENTS, style({ opacity: 0 }), {
    optional: true,
  }),
  sequence([
    query(
      ':leave > *',
      [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate('0.2s ease-in-out', style({ transform: 'translateY(-3%)', opacity: 0 })),
        style({ position: 'fixed' }),
      ],
      { optional: true }
    ),
    query(
      ':enter > *',
      [
        style({
          transform: 'translateY(-3%)',
          opacity: 0,
          position: 'static',
        }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 })),
      ],
      { optional: true }
    ),
  ]),
  query(
    ':enter .' + ROUTE_ANIMATIONS_ELEMENTS,
    stagger(75, [
      style({ transform: 'translateY(10%)', opacity: 0 }),
      animate('0.5s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 })),
    ]),
    { optional: true }
  ),
];
const STEPS_NONE = [];
const STEPS_PAGE = [STEPS_ALL[0], STEPS_ALL[2]];
const STEPS_ELEMENTS = [STEPS_ALL[1], STEPS_ALL[3]];

export const routeAnimations = trigger('routeAnimations', [
  transition(isRouteAnimationsAll, STEPS_ALL),
  transition(isRouteAnimationsNone, STEPS_NONE),
  transition(isRouteAnimationsPage, STEPS_PAGE),
  transition(isRouteAnimationsElements, STEPS_ELEMENTS),
]);

export function isRouteAnimationsAll() {
  return AnimationsService.isRouteAnimationsType('ALL');
}

export function isRouteAnimationsNone() {
  return AnimationsService.isRouteAnimationsType('NONE');
}

export function isRouteAnimationsPage() {
  return AnimationsService.isRouteAnimationsType('PAGE');
}

export function isRouteAnimationsElements() {
  return AnimationsService.isRouteAnimationsType('ELEMENTS');
}
export const slideIn = trigger('slideIn', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateX(100%)',
    }),
    animate(
      '.4s ease-out',
      style({
        opacity: 1,
        transform: 'translateX(0)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
      transform: 'translateX(0)',
    }),
    animate(
      '.4s ease-in',
      style({
        opacity: 0,
        transform: 'translateX(100%)',
      })
    ),
  ]),
]);
export const slideUp = trigger('slideUp', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(100%)',
    }),
    animate(
      '.4s ease-out',
      style({
        opacity: 1,
        transform: 'translateY(0)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
      transform: 'translateY(0)',
    }),
    animate(
      '.4s ease-in',
      style({
        opacity: 0,
        transform: 'translateY(100%)',
      })
    ),
  ]),
]);
export const scaleIn = trigger('scaleIn', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'scale(0)',
    }),
    animate(
      '.4s ease-out',
      style({
        opacity: 1,
        transform: 'scale(1)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
      transform: 'scale(1)',
    }),
    animate(
      '.4s ease-in',
      style({
        opacity: 0,
        transform: 'scale(0) ',
      })
    ),
  ]),
]);

export const heightAnimate = trigger('heightAnimate', [
  transition(':enter', [
    style({
      height: 0,
    }),
    animate(
      '.3s',
      style({
        height: 'auto',
      })
    ),
  ]),
  transition(':leave', [
    style({
      height: 'auto',
    }),
    animate(
      '.3s',
      style({
        height: 0,
      })
    ),
  ]),
]);
export const accordianAnimate = trigger('accordianAnimate', [
  state(
    'void',
    style({
      height: '0px',
    })
  ),
  transition(':enter', [
    animate(
      '300ms ease-in-out',
      style({
        height: '*',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '300ms ease-in-out',
      style({
        height: '0px',
      })
    ),
  ]),
]);
