import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SubmitTransactionRequest, Transaction, TransactionRequest } from '../../../../../src/app/app.datatypes';
import { environment } from '../../../../../src/environments';
import { ApiService } from '../../services';
import { TransactionQuery } from './transaction.query';
import { TransactionStore } from './transaction.store';

@Injectable({ providedIn: 'root' })
export class TransactionStateService {
  constructor(
    private transactionQuery: TransactionQuery,
    private transactionStore: TransactionStore,
    private apiService: ApiService
  ) {}

  handleError(error: any) {
    this.transactionStore.setError(error);
    this.transactionStore.setLoading(false);
    return this.apiService.catchError(error);
  }

  getUserTransactions(id = '', type = 'in', skip = 0, limit = 10, search = ''): Observable<Transaction[]> {
    this.transactionStore.setLoading(true);
    return this.apiService
      .get(
        'api/transactions/user-transactions/' +
          type +
          '/' +
          id +
          '?skip=' +
          skip +
          '&limit=' +
          limit +
          (search ? '&search=' + encodeURIComponent(search) : '')
      )
      .pipe(
        tap(transactions => {
          applyTransaction(() => {
            this.transactionStore.add(transactions);
            this.transactionStore.setLoading(false);
            if (transactions.length < environment.grid_skip_limit) {
              this.transactionStore.update({ apiEndReached: true });
            }
          });
        }),
        catchError(this.handleError.bind(this))
      );
  }

  updateTransactionState(id: string, transaction) {
    this.transactionStore.update(id, transaction);
  }

  updateTransactionStatus(id: string, status) {
    this.transactionStore.update(id, { status: status });
  }

  upsertTransaction(transaction: Transaction) {
    this.transactionStore.upsert(transaction.id, transaction);
  }

  postTransactions(transaction: TransactionRequest) {
    // create a user transfer (to be complete on client)
    return this.apiService.post('api/transactions', transaction);
  }

  postDeposit(transaction: TransactionRequest) {
    return this.apiService.post('api/transactions/deposit', transaction);
  }

  postWithdraw(transaction: TransactionRequest): Observable<Transaction> {
    return this.apiService.post('api/transactions/withdraw', transaction);
  }

  postSubmitWithdraw(submitRequest: SubmitTransactionRequest, id) {
    return this.apiService.post(`api/transactions/withdraw/submit/${id}`, submitRequest);
  }

  updateTransactionEvent(transaction: Transaction) {
    return this.apiService.put('api/transactions/event', {
      transaction_id: transaction.id,
      event: transaction.event,
    });
  }
}
