<div class="p-16 lg:p-24 h-full overflow-y-auto" *ngIf="user">
  <div class="lg:w-2/3 mx-auto bg-white rounded p-16">
    <div class="block sm:flex items-center justify-between mb-24 border-b border-gray-border pb-16">
      <div class="flex items-center mb-16 sm:mb-0">
        <app-user-avatar
          class="mr-16 max-w-64 max-h-64 min-w-64 h-64 w-64 md:max-w-128 md:max-h-128 md:min-w-128 md:h-128 md:w-128"
          [avatar_url]="user.avatar_url"
        ></app-user-avatar>

        <div class="flex-grow overflow-hidden">
          <div class="flex username">
            <h4 class="text-xl font-bold truncate capitalize">
              {{ user.name }}
            </h4>
            <img
              *ngIf="user.country"
              src="https://flagcdn.com/w20/{{ user.country | lowercase }}.png"
              class="h-12 ml-8"
              alt="{{ user.country }}"
              appTooltip="{{ user.country }}"
              placement="bottom"
            />
          </div>
          <div class="flex text-lg" *ngIf="user.reputation">
            <app-icon icon="reputations" class="w-24 h-24 mr-8"></app-icon>
            {{ user.reputation | filmPrice }}
          </div>
          <a *ngIf="user.personal_website_url" href="{{ user.personal_website_url }}" class="text-sm">
            Personal Website
          </a>
          <div *ngIf="badges?.length > 0" class="flex pt-8">
            <ng-container *ngFor="let badge of badges">
              <div *ngIf="badge?.is_visible" class="flex justify-center mr-8">
                <img [src]="badge?.image_url" class="w-32 h-32 img-badge" alt="minor-badge" />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="flex h-full flex-col justify-between">
        <ul class="social-links">
          <li class="fb" *ngIf="user.facebook_url">
            <a href="{{ user.facebook_url }}" target="_blank">
              <img src="assets/img/social-icons/fb.svg" alt="" />
            </a>
          </li>
          <li class="yt" *ngIf="user.youtube_url">
            <a href="{{ user.youtube_url }}" target="_blank">
              <img src="assets/img/social-icons/yt.svg" alt="" />
            </a>
          </li>
          <li class="insta" *ngIf="user.instagram_url">
            <a href="{{ user.instagram_url }}" target="_blank">
              <img src="assets/img/social-icons/insta.svg" alt="" />
            </a>
          </li>
          <li class="imdb" *ngIf="user.imdb_url">
            <a href="{{ user.imdb_url }}" target="_blank">
              <img src="assets/img/social-icons/imdb.svg" alt="" />
            </a>
          </li>
          <li class="tw" *ngIf="user.twitter_url">
            <a href="{{ user.twitter_url }}" target="_blank">
              <img src="assets/img/social-icons/tw.svg" alt="" />
            </a>
          </li>
          <li class="vi" *ngIf="user.vimeo_url">
            <a href="{{ user.vimeo_url }}" target="_blank">
              <img src="assets/img/social-icons/vi.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-24 mb-24">
      <div *ngIf="user.first_name" class="bg-body p-8 rounded">
        <p class="text-xs uppercase">First Name</p>
        <p class="font-bold break-all">{{ user.first_name }}</p>
      </div>
      <div *ngIf="user.last_name" class="bg-body p-8 rounded">
        <p class="text-xs uppercase">Last Name</p>
        <p class="font-bold break-all">{{ user.last_name }}</p>
      </div>
      <div *ngIf="user.sex" class="bg-body p-8 rounded">
        <p class="text-xs uppercase">Gender</p>
        <p class="font-bold" *ngIf="user.sex === 'M'">Male</p>
        <p class="font-bold" *ngIf="user.sex === 'F'">Female</p>
        <p class="font-bold" *ngIf="user.sex === 'O'">Other</p>
      </div>
      <div *ngIf="user.email" class="bg-body p-8 rounded">
        <p class="text-xs uppercase">Email</p>
        <p class="font-bold break-all cut-off">{{ user.email }}</p>
      </div>
      <div *ngIf="user.user_type_id" class="bg-body p-8 rounded">
        <p class="text-xs uppercase">User Type</p>
        <p class="font-bold capitalize">{{ role }}</p>
      </div>
      <div *ngIf="user.created_at" class="bg-body p-8 rounded">
        <p class="text-xs uppercase">Member Since</p>
        <p class="font-bold">
          {{ user.created_at }}
        </p>
      </div>
      <div *ngIf="user.ethnicity" class="bg-body p-8 rounded">
        <p class="text-xs uppercase">Ethnicity</p>
        <p class="font-bold break-all">{{ user.ethnicity }}</p>
      </div>
    </div>

    <div *ngIf="user.bio" class="bg-body p-8 rounded mb-24">
      <p class="text-xs uppercase">Bio</p>
      <p class="font-bold">
        {{ user.bio }}
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24">
      <div *ngIf="user.favoriteDirector">
        <p class="text-xs uppercase">Favorite Director</p>
        <p class="font-bold">{{ user.favoriteDirector }}</p>
      </div>
      <div *ngIf="user.favoriteFilm">
        <p class="text-xs uppercase">Favorite Film</p>
        <p class="font-bold">{{ user.favoriteFilm }}</p>
      </div>
      <div *ngIf="user.favoriteGenre">
        <p class="text-xs uppercase">Favorite Genre</p>
        <p class="font-bold">{{ user.favoriteGenre }}</p>
      </div>
    </div>
  </div>
  <div class="lg:w-2/3 mx-auto bg-white rounded p-16 mt-12" *ngIf="distributions?.length">
    <p class="h-40 text-center border-b border-gray-border font-semibold text-xl mb-12">My Picks</p>
    <div class="md:grid misc gap-16">
      <div
        class="cursor-pointer md:mb-0 mb-16"
        (click)="redirectTo(distribution.id, distribution.affiliate_marketing)"
        *ngFor="let distribution of distributions"
      >
        <div
          class="cover-image w-full filter-gray-scale"
          [ngStyle]="{ 'background-image': 'url(' + distribution.cover_url + ')' }"
        ></div>
        <h5 class="z-10 mt-8 flex-1 max-w-3/4 truncate text-lg font-bold">{{ distribution.title }}</h5>
      </div>
    </div>
  </div>
</div>
