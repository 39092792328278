import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, tap } from 'rxjs';
import {
  CreativeQuery,
  Distribution,
  HorizontalSliderProperties,
  Moderation,
  ModerationRequest,
  Moderations,
  Proposal,
} from '../../../app.datatypes';
import { ApiService } from '../api.service';
import { LoadingService } from './loading/loading.service';
import { environment } from '../../../../environments';
import { HorizontalSliderService } from './horizontal-slider.service';
import {
  AuthenticatedUserService,
  CreativeQueryQuery,
  CreativeQueryStore,
  ProposalQuery,
  ProposalStore,
} from '../../state';
import { EntityTypeEnum } from '../../interface/entity-type-enum';
import { DistributionQuery, DistributionStore } from '../../state/distribution';
import { ReviewStateService } from 'app/shared/state/review/review-state.service';
import { Review } from 'app/shared/interface/review';

@Injectable({
  providedIn: 'root',
})
export class OverallModerationService {
  private readonly proposalUrl = 'api/proposals/moderate';
  private readonly distributionUrl = 'api/distributions/moderate';
  private readonly reviewUrl = 'api/reviews/moderate';
  private readonly distributionReviewUrl = 'api/distributions/reviews/moderate';
  private readonly cqUrl = 'api/creative-query/moderate';
  moderaionList$ = new BehaviorSubject<HorizontalSliderProperties[]>([]);
  unlockedOnly = true;

  constructor(
    private readonly apiService: ApiService,
    private readonly loadingService: LoadingService,
    private readonly horizontalSliderService: HorizontalSliderService,
    private readonly proposalStore: ProposalStore,
    private readonly porposalQuery: ProposalQuery,
    private readonly distributionStore: DistributionStore,
    private readonly distributionQuery: DistributionQuery,
    private readonly cqStore: CreativeQueryStore,
    private readonly cqQuery: CreativeQueryQuery,
    private readonly reviewService: ReviewStateService,
    private readonly authenticatedUserService: AuthenticatedUserService
  ) {}

  setWidth(width: number): void {
    this.horizontalSliderService.limit.parentWidth = width;
  }

  getAllModerations(userId: string): Observable<HorizontalSliderProperties[]> {
    const listModerations = this.moderaionList$.getValue();
    if (listModerations.length) {
      return this.moderaionList$.asObservable();
    }

    const observables = [];
    const urls = [this.proposalUrl, this.reviewUrl, this.cqUrl];
    if (this.authenticatedUserService.getUser().is_beta_tester) {
      urls.push(...[this.distributionUrl, this.distributionReviewUrl]);
    }
    urls.forEach((url) => {
      observables.push(
        this.apiService.get(`${url}?limit=${environment.grid_skip_limit}${this.unlockedOnly ? '&unlocked=true' : ''}`)
      );
    });
    return combineLatest(observables as []).pipe(
      tap((res: []) => {
        res.forEach((data: Moderations[], index) => {
          const count = data?.length;
          let title = '';
          let data$ = null;
          let entityType = EntityTypeEnum.PROPOSAL;
          switch (urls[index]) {
            case this.proposalUrl:
              title = 'Proposals';
              this.proposalStore.add(data as Proposal[]);
              data$ = this.porposalQuery.getAllModerationProposal(userId);
              break;
            case this.distributionUrl:
              title = 'DCP+';
              entityType = EntityTypeEnum.VIDEO_DISTRIBUTION;
              this.distributionStore.add(data as Distribution[]);
              data$ = this.distributionQuery.getAllModerationDistribution(userId);
              break;
            case this.reviewUrl:
              title = 'Reviews';
              entityType = EntityTypeEnum.REVIEW;
              this.reviewService.addReviews(data as Review[]);
              data$ = this.reviewService.getAllModerationReview(userId);
              break;
            case this.distributionReviewUrl:
              title = 'DCP+ Reviews';
              entityType = EntityTypeEnum.VIDEO_DISTRIBUTION_REVIEW;
              this.reviewService.addReviews(data as Review[]);
              data$ = this.reviewService.getAllModerationReview(userId, true);
              break;
            case this.cqUrl:
              title = 'Creative Queries';
              entityType = EntityTypeEnum.CREATIVE_QUERY;
              this.cqStore.add(data as CreativeQuery[]);
              data$ = this.cqQuery.getAllModerationCreativeQuery(userId);
              break;
          }
          if (count) {
            listModerations.push({
              title,
              skip: count,
              ...this.horizontalSliderService.handlePaginationProperties(count),
              data$,
              entityType,
            });
          }
        });
        this.moderaionList$.next(listModerations);
      })
    );
  }

  lockModeration(id: string, type: EntityTypeEnum): Observable<Proposal> {
    this.loadingService.changeStatus(true);

    return this.apiService.put(this.handleEntityModeration(id, type), null).pipe(
      tap((entity) => {
        this.handleEntityModeration(id, type, entity);
        this.loadingService.changeStatus(false);
      })
    );
  }

  handleEntityModeration(id: string, type: EntityTypeEnum, entity?: Moderations): string {
    let url = 'api/proposals/';
    if (entity) {
      this.proposalStore.update(id, entity as Proposal);
      this.cqStore.update(id, entity as CreativeQuery);
      this.authenticatedUserService.setModeratedEntity({ entity: 'proposal', id });
    }
    switch (type) {
      case EntityTypeEnum.CREATIVE_QUERY:
        url = 'api/creative-query/';
        if (entity) {
          this.cqStore.update(id, entity as CreativeQuery);
          this.authenticatedUserService.setModeratedEntity({ entity: 'creative-query', id: id });
        }
        break;
      case EntityTypeEnum.VIDEO_DISTRIBUTION:
        url = 'api/distributions/';
        if (entity) {
          this.distributionStore.update(id, entity as Distribution);
          this.authenticatedUserService.setModeratedEntity({ entity: 'distribution', id: id });
        }
        break;
    }
    return `${url}${id}/lock`;
  }

  reset(unlockedOnly = true): void {
    this.unlockedOnly = unlockedOnly;
    this.moderaionList$.next([]);
    this.proposalStore.reset();
    this.distributionStore.reset();
    this.cqStore.reset();
    this.reviewService.resetModeratioReviews();
  }

  postModerate(moderation: ModerationRequest): Observable<Moderation> {
    return this.apiService
      .post('api/moderations', moderation)
      .pipe(tap(() => this.authenticatedUserService.setUnlocked()));
  }
}
