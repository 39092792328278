import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { JoyrideService } from 'ngx-joyride';
import { AuthenticatedUser, PaymentQuery, PaymentStateService } from '../../../../../shared';
import { Proposal, PricingFees, ProposalRound, CheckoutData, BountyData } from '../../../../../app.datatypes';

@Component({
  selector: 'app-proposal-create-step-four',
  templateUrl: './proposal-create-step-four.component.html',
})
export class ProposalCreateStepFourComponent implements OnInit, OnChanges {
  @Input() proposal: Proposal;
  @Input() isLoading = false;
  @Input() selectedRound: ProposalRound;
  @Input() prices: PricingFees;
  @Input() stepThreeData: BountyData;
  @Input() user: AuthenticatedUser;
  @Output() setStepFive = new EventEmitter<CheckoutData>();

  stepFiveForm: FormGroup;
  submitFeeFilm: number;
  additional: number;
  bountyFieldName: string;
  bountyData: BountyData;
  @Input() minBountyToBeAdded: number;
  @Input() isBalanceZero: boolean;
  checkoutAmount: number;
  isExternalCheckout = false;
  checkoutFormValidity = true;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    protected readonly paymentService: PaymentStateService,
    protected readonly paymentQuery: PaymentQuery,
    protected readonly joyrideService: JoyrideService
  ) {}

  ngOnInit(): void {
    this.setBountyFieldName();
    this.stepFiveForm = this.formBuilder.group({
      termsAccepted: [null, Validators.requiredTrue],
      userBounty: [
        this.proposal?.bounty - this.selectedRound?.minimum_bounty > 0
          ? this.proposal?.bounty - this.selectedRound?.minimum_bounty
          : 0,
      ],
    });
    this.submitFeeRecalculate();
    this.refreshBountyValidation();
    this.recalculateBountyValue();
  }

  setBountyFieldName(): void {
    this.bountyFieldName = this.proposal.blockchain_confirmed ? 'extra_bounty' : 'bounty';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRound) {
      this.submitFeeRecalculate();
      this.recalculateBountyValue();
    }
  }

  bountyTotalChange($event): void {
    this.checkoutAmount = $event;
  }

  setChecked(): void {
    if (this.stepFiveForm) {
      const data = this.stepFiveForm.value;
      data.bounty = this.bountyData?.bounty || 0;
      data.extra_bounty = this.bountyData?.extra_bounty || 0;
      data.isValid = this.stepFiveForm.valid;
      data.is_external_checkout = this.isExternalCheckout;
      this.setStepFive.emit(data);
    }
  }

  changeCheckoutType(isExternalCheckout: boolean): void {
    this.isExternalCheckout = isExternalCheckout;
    this.refreshBountyValidation();
    this.setChecked();
  }

  checkoutFormValidityChange(validity): void {
    this.checkoutFormValidity = validity;
    this.refreshBountyValidation();
    this.setChecked();
  }

  submitFeeRecalculate(): void {
    if (!this.proposal?.blockchain_confirmed && this.selectedRound) {
      this.submitFeeFilm = this.selectedRound?.submission_fee
        ? this.paymentService.calculateDollarToFilmPricing(this.selectedRound?.submission_fee)
        : 0;
    }
  }

  // This method recalculates bounty object according to the proposal `mode` (submit or resubmit)
  recalculateBountyValue(): void {
    const val = this.stepFiveForm?.get('userBounty').value;
    if (val === '' || val < 0) {
      this.stepFiveForm.get('userBounty').setValue(0);
    }
    // Initialize bountyData object
    this.bountyData = new BountyData();
    // Set needed field value (based on mode)
    this.bountyData[this.bountyFieldName] = this.stepFiveForm?.get('userBounty').value;
    if (!this.proposal.blockchain_confirmed) {
      this.bountyData[this.bountyFieldName] += this.minBountyToBeAdded;
    }
    // Set isValid attribute based on bountyForm.valid attribute value
    this.stepFiveForm?.updateValueAndValidity();
    this.setChecked();
  }

  addBounty(bounty): void {
    let newBounty = this.stepFiveForm.get('userBounty').value + bounty;
    if (newBounty <= 0) {
      newBounty = 0;
    }
    this.stepFiveForm.get('userBounty').setValue(newBounty);
    this.recalculateBountyValue();
  }

  handleBounty(event): void {
    if (event.action === 'type') {
      this.stepFiveForm.get('userBounty').setValue(Number(event.value));
      this.recalculateBountyValue();
    } else {
      this.addBounty(event.action === 'add' ? 1 : -1);
    }
    this.refreshBountyValidation();
  }

  // Set bounty form validators based on proposal mode (submit or resubmit)
  refreshBountyValidation(): void {
    if (this.stepFiveForm) {
      if (!this.isExternalCheckout) {
        let maxValue =
          this.user.balance -
          ((this.minBountyToBeAdded || 0) +
            this.paymentService.calculateDollarToFilmPricing(this.selectedRound?.submission_fee || 0));
        if (this.proposal.blockchain_confirmed) {
          maxValue = this.user.balance - this.paymentQuery.proposalResubmissionFilmFee;
        }
        this.stepFiveForm.controls['userBounty'].setValidators([
          // max value should be balance - all other stuff we charge (min_bounty + submission_fee)
          Validators.max(maxValue),
        ]);
      } else {
        this.stepFiveForm.controls['userBounty'].clearValidators();
      }
      this.stepFiveForm.controls['userBounty'].updateValueAndValidity();
      if (!this.checkoutFormValidity) {
        this.stepFiveForm.controls['userBounty'].setErrors({ incorrect: true });
      }
    }
  }
}
