import { Injectable } from '@angular/core';
import { TezosToolkit } from '@taquito/taquito';
import { AuthenticatedUserQuery } from '../state/authenticated-user/authenticated-user.query';
import { ToasterService } from './toaster.service';
import { BeaconWallet } from '@taquito/beacon-wallet';
import { NetworkType, PermissionScope, SigningType } from '@airgap/beacon-types';
import { environment } from '../../../environments';
import { TezosWalletImpl, TezosWalletService } from './tezos-wallet.service';
import { packDataBytes } from '@taquito/michel-codec';

@Injectable({
  providedIn: 'root',
})
export class TezosBeaconWalletService implements TezosWalletImpl {
  tezos: TezosToolkit;
  beacon: BeaconWallet;
  publicKey: string;
  publicKeyHash: string;

  constructor(private authUserQuery: AuthenticatedUserQuery, private toasterService: ToasterService) {
    this.beacon = new BeaconWallet({
      name: 'DCP Web App',
      preferredNetwork: NetworkType.CUSTOM as any,
    });
  }

  async connect(signBytes) {
    await this.login();
    if (!this.publicKeyHash || !this.publicKey) {
      throw new Error('No kukai user wallet, wallet connection failed');
    }
    if (!this.authUserQuery.user?.id) {
      throw new Error('No authenticated user id detected, wallet connection failed');
    }
    return {
      address: this.publicKeyHash,
      pk: this.publicKey,
      email: null,
      sig: await this.signPackedData(
        signBytes,
        'Wallet Connection',
        'Please sign the message so we can verify you control the wallet.',
        'Connect Now'
      ).catch(this.handleError.bind(this)),
    };
  }

  async login() {
    let activeAccount = await this.beacon.client.getActiveAccount();
    if (!activeAccount) {
      await this.beacon
        .requestPermissions({
          network: {
            type: NetworkType.CUSTOM as any,
            name: 'T4L3NT Testnet',
            rpcUrl: environment.t4l3nt_rpc_endpoint,
          },
          scopes: [PermissionScope.SIGN as any, PermissionScope.OPERATION_REQUEST],
        })
        .catch(this.handleError.bind(this));
      activeAccount = await this.beacon.client.getActiveAccount();
    }
    this.publicKey = activeAccount.publicKey;
    this.publicKeyHash = activeAccount.address;
    return { pkh: this.publicKeyHash, pk: this.publicKey };
  }

  async loginVerification() {
    await this.login();
    if (!this.publicKeyHash) {
      throw new Error('No beacon public key hash, wallet connection failed');
    }
    if (!this.publicKey) {
      throw new Error('No beacon public key, wallet connection failed');
    }
    const nonce = TezosWalletService.randomNonce;
    let packedExpr = packDataBytes({ string: `DCP Login Request ${this.publicKeyHash} ${nonce}` });
    return {
      address: this.publicKeyHash,
      pk: this.publicKey,
      nonce,
      email: null,
      sig: await this.signPackedData(
        packedExpr.bytes,
        'Connect',
        'Please sign the message so we can verify you control the account associated with the wallet.',
        'Connect Now'
      ),
    };
  }

  async logout() {
    return this.beacon.clearActiveAccount();
  }

  handleError() {
    this.toasterService.openToastr(
      'You have cancelled signing the action. Please sign your action to make your attribution count!',
      'Sign Error',
      'error',
      3000
    );
    throw new Error('Signing cancelled');
  }

  get connectedWalletPublicKey() {
    return this.publicKey;
  }

  get connectedWalletAddress() {
    return this.publicKeyHash;
  }

  get selectedVerifier() {
    // no 'verifier' for beacon, only for social/torus
    return null;
  }

  get user() {
    return null;
  }

  async validateWallet() {
    if (this.authUserQuery.user?.wallet_address) {
      if (
        (await this.beacon.client.getActiveAccount()) &&
        this.connectedWalletAddress !== this.authUserQuery.user.wallet_address
      ) {
        await this.logout();
      }
    } else {
      await this.logout();
    }
  }

  async sendOperation(tx) {
    return this.beacon.sendOperations([tx]);
  }

  async signPackedData(data, actionTitle, actionText, buttonTitle) {
    return this.beacon.client
      .requestSignPayload({
        payload: data,
        signingType: SigningType.MICHELINE,
        sourceAddress: this.connectedWalletAddress,
      })
      .then((resp) => resp.signature);
  }

  setSelectedVerifier(selected) {
    // No op
  }
}
