import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { User, UserRole, Google2FaResponse, AuthData, AuthWithWalletData } from '../../app.datatypes';
import { environment } from '../../../environments';
import { CryptoUtil } from '../util/crypto-util';
import { AuthenticatedUserService } from '../state/authenticated-user/authenticated-user.service';
import { AuthenticatedUser, AuthenticationTokens } from '../state/authenticated-user/authenticated-user.state';
import { SocketService } from './socket.service';
import { Router } from '@angular/router';
import { TezosWalletService } from './tezos-wallet.service';
import { resetStores } from '@datorama/akita';
import { ProposalByRoundService } from './new/proposal-by-round/proposal-by-round.service';
import { OverallModerationService } from './new/overall-moderation.service';
import { DistributionService } from './distribution.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private client_id = environment.client_id;
  private client_secret = environment.client_secret;
  refreshJwtTimer;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private socketService: SocketService,
    private authenticatedUserService: AuthenticatedUserService,
    private tezosWalletService: TezosWalletService,
    private readonly proposalByRound: ProposalByRoundService,
    private readonly overAllModeration: OverallModerationService,
    private readonly distributionService: DistributionService
  ) {}

  loginWithPassword(username: string, password: string, opt_2faCode = null): Observable<AuthenticationTokens> {
    const data: AuthData = {
      username,
      password,
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      grant_type: 'password',
    };
    if (opt_2faCode) {
      data['code'] = opt_2faCode;
    }
    return this.login(data);
  }

  loginWithWallet(
    wallet_address: string,
    pk: string,
    sig: string,
    nonce: string,
    opt_2faCode = null
  ): Observable<AuthenticationTokens> {
    const data: AuthWithWalletData = {
      wallet_address,
      pk,
      sig,
      nonce,
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      grant_type: 'crypto_wallet_grant',
    };
    if (opt_2faCode) {
      data['code'] = opt_2faCode;
    }
    return this.login(data);
  }

  // Moved the login(), refreshAccessToken(), startRefreshJWTTimer(), serverSideLogOut() and logout() to this auth.service.ts from authenticated-user.service.ts
  // to mitigate the circular dependency between authenticated-user.service.ts and socket.service.ts
  private login(authRequestData): Observable<AuthenticationTokens> {
    return this.apiService.post('oauth/token', authRequestData).pipe(
      tap((tokenDetails: AuthenticationTokens) => {
        this.authenticatedUserService.login(tokenDetails);
        if (typeof environment.echo_enabled === 'boolean' && environment.echo_enabled) {
          this.socketService.connect(tokenDetails.access_token).then((socket) => {
            socket.joinMandatoryChannels();
          });
        }
        this.startRefreshJWTTimer();
      }),
      catchError((err) => {
        this.authenticatedUserService.handleError(err.error ? err.error.message : err.message);
        return throwError(err);
      })
    );
  }

  refreshAccessToken() {
    if (!localStorage.getItem('refresh_token')) {
      return;
    }
    const data: AuthData = {
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      grant_type: 'refresh_dcp_token',
      refresh_token: localStorage.getItem('refresh_token'),
    };

    return this.apiService.post('oauth/token', data).pipe(
      tap((tokenDetails: AuthenticationTokens) => {
        this.authenticatedUserService.refreshAccessToken(tokenDetails);
        if (typeof environment.echo_enabled === 'boolean' && environment.echo_enabled) {
          this.socketService.connect(tokenDetails.access_token).then((socket) => {
            socket.joinMandatoryChannels();
          });
        }
        this.startRefreshJWTTimer();
      }),
      catchError((err) => {
        this.authenticatedUserService.handleError(err.error ? err.error.message : err.message);
        this.authenticatedUserService.clientSideLogOut();
        return throwError(err);
      })
    );
  }

  startRefreshJWTTimer(): void {
    if ((this.refreshJwtTimer && !this.refreshJwtTimer.isStopped) || !localStorage.getItem('refresh_at')) {
      return;
    }
    const refreshAt = new Date(localStorage.getItem('refresh_at'));
    const milliSecondsToRefresh = refreshAt.getTime() - new Date().getTime();
    if (milliSecondsToRefresh < 0) {
      return;
    }
    this.refreshJwtTimer = timer(milliSecondsToRefresh).subscribe(() => {
      this.refreshAccessToken();
    });
  }

  serverSideLogOut(): Promise<string> {
    return this.apiService.post('oauth/logOut', []).toPromise();
  }

  /**
   * Logs the user out server side, client side, resets states,
   * and disconnects websockets.
   */
  logout(): void {
    // Server side logout called first, so the JWT token is still in state
    this.tezosWalletService.logout();
    this.overAllModeration.reset();
    this.distributionService.reset();
    this.serverSideLogOut().finally(async () => {
      await this.authenticatedUserService.clientSideLogOut();
      await this.router.navigate(['/login']);
      resetStores();
      this.proposalByRound.resetAll();
    });
    this.refreshJwtTimer.unsubscribe();
    // disconnect web-socket
    if (this.socketService && typeof environment.echo_enabled === 'boolean' && environment.echo_enabled) {
      this.socketService.disconnect();
    }
  }

  getTopUsers(limit: number): Observable<User[]> {
    const url = `api/scoreboards/top-users?limit=${limit}`;
    return this.apiService.get(url);
  }

  getUserTypes(): Observable<UserRole[]> {
    return this.apiService.get('api/user-roles');
  }

  postRequestUserAttributeChange(attribute, data) {
    const copyData = { ...data };
    if (copyData.password) {
      copyData.password = CryptoUtil.bcryptHash(data.password);
    }
    return this.apiService.post('api/request-change/' + attribute, copyData);
  }

  postInviteUser(data) {
    return this.apiService.post('api/invite-user', data);
  }

  postApplyUserAttributeChange(attribute, data) {
    const copyData = { ...data };
    if (attribute === 'password') {
      copyData.password = CryptoUtil.bcryptHash(data.password);
      copyData.password_confirmation = CryptoUtil.bcryptHash(data.password_confirmation);
    }
    return this.apiService
      .post('api/apply-change/' + attribute, copyData)
      .pipe(tap((res) => this.authenticatedUserService.setUser(res)));
  }

  createNewUserVerification(): Observable<AuthenticatedUser> {
    return this.apiService.get('api/verify/create').pipe(tap((res) => this.authenticatedUserService.setUser(res)));
  }

  postSetUserSettings(settingName, settingValue): Observable<User> {
    return this.apiService
      .post('api/profile/settings', { settingName: settingName, settingValue: settingValue })
      .pipe(tap((res) => this.authenticatedUserService.setUser(res)))
      .pipe(catchError((error) => this.apiService.catchError(error)));
  }

  enableGoogle2FA(): Observable<Google2FaResponse> {
    return this.apiService.get('api/goog2fa');
  }

  confirmEnableGoogle2FA(code): Observable<any> {
    // any is ok here
    return this.apiService.post('api/goog2fa', { code: code });
  }

  disableGoogle2FA(): Observable<any> {
    // any is ok here
    return this.apiService.delete('api/goog2fa');
  }

  sendSMSMobileVerification(mobile): Observable<User> {
    return this.apiService.post('api/verify/sms', { mobile });
  }

  sendEmailCode(email): Observable<User> {
    return this.apiService
      .put('api/verify/email', { email })
      .pipe(tap((res) => this.authenticatedUserService.setUser(res)));
  }

  verifyEmailCode(code): Observable<User> {
    return this.apiService
      .put('api/verify/email/confirm', { verification_code: code })
      .pipe(tap((res) => this.authenticatedUserService.setUser(res)));
  }

  postCheckUserUpdateToken(attribute, token: string) {
    return this.apiService
      .post('api/check-token/' + attribute + '?token=' + token, [])
      .pipe(catchError((error) => this.apiService.catchError(error)));
  }

  resendUserEmailVerification(): Observable<User> {
    return this.apiService.put('api/verify/email', null);
  }
}
