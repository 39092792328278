import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

import { CreativeQuery, CreativeQueryResult, CQResultTable } from '../../app.datatypes';

@Injectable({
  providedIn: 'root',
})
export class CreativeQueryService {
  constructor(private apiService: ApiService) {}
  getCreativeQuery(id: string): Observable<CreativeQuery> {
    return this.apiService.get('api/creative-query/' + id);
  }

  getCreativeQueryResults(id: string): Observable<CreativeQueryResult> {
    return this.apiService.get('api/creative-query/' + id + '/results');
  }

  getCreativeQueryTableResults(id: string): Observable<CQResultTable> {
    return this.apiService.get('api/creative-query/' + id + '/results-table');
  }

  getUserCreativeQuerys(userid, skip = 0, search = ''): Observable<CreativeQuery[]> {
    let apiUrl = 'api/creative-query/user/';
    if (userid) {
      apiUrl += userid + '/';
    }
    apiUrl += '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }
}
