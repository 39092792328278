import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { catchError, last, map, switchMap, tap } from 'rxjs/operators';
import {
  FileProgressObject,
  ProfileAboutUpdateRequest,
  ProfileUpdateRequest,
  Proposal,
  RegisterRequest,
  User,
  UserEngagementData,
} from '../../../app.datatypes';
import { CryptoUtil } from '../../../shared/util/crypto-util';
import { UserTypes, UserVerificationStatus } from '../../enumerations';
import { ApiService } from '../../services/api.service';
import { AuthenticatedUser, AuthenticationTokens } from './authenticated-user.state';
import { AuthenticatedUserStore } from './authenticated-user.store';
import { ModerationType } from '../../components/user-requests-suggestion/user-requests-suggestion.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUserService {
  constructor(private readonly apiService: ApiService, private readonly authStore: AuthenticatedUserStore) {}

  /**
   * Handle the error
   * @param error the error
   * @returns Observable created by rethrowing error
   */
  handleError(error: any): Observable<never> {
    if (this.authStore) {
      this.authStore.setError(error);
      this.authStore.setLoading(false);
    }
    return this.apiService?.catchError(error);
  }

  setUser(user: AuthenticatedUser): void {
    this.authStore.update({ user });
  }

  login(tokenDetails: AuthenticationTokens): void {
    const refresh_at = this.calculateJWTRefreshTime(tokenDetails.expires_in);
    localStorage.setItem('access_token', tokenDetails.access_token);
    localStorage.setItem('refresh_token', tokenDetails.refresh_token);
    localStorage.setItem('expires_in', tokenDetails.expires_in.toString());
    localStorage.setItem('refresh_at', refresh_at.toString());
    this.authStore.update({ tokens: tokenDetails });
    this.authStore.setLoading(false);
  }

  refreshAccessToken(tokenDetails: AuthenticationTokens): void {
    const refresh_at = this.calculateJWTRefreshTime(tokenDetails.expires_in);
    localStorage.setItem('access_token', tokenDetails.access_token);
    localStorage.setItem('refresh_token', tokenDetails.refresh_token);
    localStorage.setItem('expires_in', tokenDetails.expires_in.toString());
    localStorage.setItem('refresh_at', refresh_at.toString());
    this.authStore.update({ tokens: tokenDetails });
  }

  updateToken(): void {
    this.authStore.update({
      tokens: {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        // https://stackoverflow.com/questions/14667713/how-to-convert-a-string-to-number-in-typescript
        expires_in: +localStorage.getItem('expires_in'),
        refresh_at: new Date(localStorage.getItem('refresh_at')),
      },
    });
  }

  clientSideLogOut(): void {
    this.removeTokens();
    this.authStore.reset();
  }

  calculateJWTRefreshTime(expires_in: number): Date {
    const secondsBeforeExpireToRefresh = expires_in > 60 ? 60 : Math.round(expires_in / 2);
    const now = new Date();
    return new Date(now.setSeconds(now.getSeconds() + expires_in - secondsBeforeExpireToRefresh));
  }

  async getPassword(): Promise<any> {
    const pass = null;
    if (localStorage.getItem('password')) {
      try {
        return await CryptoUtil.decrypt(localStorage.getItem('password'), this.authStore.getValue().user?.id);
      } catch (ex) {
        localStorage.removeItem('password');
      }
    }
    return pass;
  }

  removeTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_at');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('password');
    localStorage.removeItem('stop_submission_prompt');
    const tokenDetails: AuthenticationTokens = {
      access_token: null,
    };
    this.authStore.update({ tokens: tokenDetails });
  }

  resetStates(): void {
    resetStores({ exclude: ['authenticated-user', 'authentication'] });
  }

  /**
   * Get the app user for app initialization
   * @returns Promise containing the authenticated user, or null
   */
  initializeAppUserState(): Promise<AuthenticatedUser | null> {
    const observable: Observable<AuthenticatedUser | null> = this.userByToken();
    return observable ? observable.toPromise().catch((_) => null) : null;
  }

  setUserExternalBalance(external_balance: number) {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        external_balance,
      },
    }));
  }

  getUser(): AuthenticatedUser {
    return this.authStore.getValue().user;
  }

  setUserEmail(value: string): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        email: value,
      },
    }));
  }

  setUserMobilePhone(value: string): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        mobile: value,
      },
    }));
  }

  setUserSmsVerificationStatus(value: UserVerificationStatus): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        sms_verification_status: value,
      },
    }));
  }

  setUserEmailVerificationStatus(value: UserVerificationStatus): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        email_verification_status: value,
      },
    }));
  }

  setUserKycVerificationStatus(value: UserVerificationStatus): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        kyc_verification_status: value,
      },
    }));
  }

  setWalletAddress(wallet_address: string): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        wallet_address,
      },
    }));
  }

  setUserBalance(balance: number): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        balance,
      },
    }));
  }

  setUserLockedBalance(locked: number): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        locked,
      },
    }));
  }

  setUserReputation(reputation: number): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        reputation,
      },
    }));
  }

  setUserTypeID(user_type_id: UserTypes): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        user_type_id,
      },
    }));
  }

  setModeratorAttributes(
    user_type_id: UserTypes,
    awaiting_become_moderator_request: boolean,
    can_be_moderator?: boolean,
    can_refuse_moderator_access?: boolean
  ): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        user_type_id,
        awaiting_become_moderator_request,
        can_be_moderator:
          can_be_moderator !== undefined ? can_be_moderator : this.authStore.getValue().user.can_be_moderator,
        can_refuse_moderator_access:
          can_refuse_moderator_access !== undefined
            ? can_refuse_moderator_access
            : this.authStore.getValue().user.can_refuse_moderator_access,
      },
    }));
  }

  setModeratedEntity(to_be_moderated_entity: { entity: string; id: string }) {
    const moderation_in_progress = !!to_be_moderated_entity;
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        to_be_moderated_entity,
        moderation_in_progress,
      },
    }));
  }

  setUserEngagement(user_engagement_data: UserEngagementData): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        user_engagement_data,
      },
    }));
  }

  setPotentialEarnings(potential_earnings: number): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        potential_earnings,
      },
    }));
  }

  setUnlocked(): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        moderation_in_progress: false,
        to_be_moderated_entity: null,
      },
    }));
  }

  setDiscordIntegration(discord_user_id?: string, discord_username?: string): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        discord_user_id,
        discord_username,
      },
    }));
  }

  setPlaidLinkToken(plaid_link_token?: string): void {
    this.authStore.update((state) => ({
      ...state,
      user: {
        ...state.user,
        plaid_link_token,
      },
    }));
  }

  getPlaidLinkToken(): string {
    return this.authStore.getValue().user.plaid_link_token;
  }

  /**
   * Update the user by calling the API
   * @param data The request data
   * @returns Observable containing the authenticated user
   */
  updateUser(data: AuthenticatedUser): Observable<AuthenticatedUser> {
    return this.apiService.put('api/profile', data).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * Get user from database
   * @returns Observable containing authenticated user, or null if user is not authenticated.
   */
  userByToken(): Observable<AuthenticatedUser | null> {
    if (!this.authStore.getValue()?.tokens?.access_token) {
      return of(null);
    }
    this.authStore.setLoading(true);
    return this.apiService.get('api/account/dashboard').pipe(
      switchMap((user) => {
        if (user) {
          return this.getAllUserDraftProposal().pipe(
            map((proposals) => {
              user.drafts = proposals.length;
              this.setUser(user);
              return user;
            })
          );
        } else {
          this.setUser(user);
          return of(user);
        }
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getAllUserDraftProposal(): Observable<Proposal[]> {
    const api = 'api/user-proposals?status=waiting-for-submission';
    return this.apiService.get(api);
  }

  /**
   * Create new user
   * @param request The request object
   * @returns Observable containing the new user
   */
  register(request: RegisterRequest): Observable<AuthenticatedUser> {
    this.authStore.setLoading(true);
    return this.apiService.post('api/users', request).pipe(
      tap((_: AuthenticatedUser) => {
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  public registerWithWallet(
    wallet_address: string,
    pk: string,
    sig: string,
    nonce: string,
    wallet_type: string,
    ref?: string,
    betaTester?: boolean,
    btt?: string,
    type_of_kukai_login?: string, // kukai only
    email?: string // kukai gmail only
  ): Observable<User> {
    this.authStore.setLoading(true);
    return this.apiService
      .post('api/users/reg-with-wallet', {
        wallet_address,
        pk,
        sig,
        nonce,
        wallet_type,
        ref,
        betaTester,
        btt,
        type_of_kukai_login,
        email,
      })
      .pipe(
        tap((_: AuthenticatedUser) => {
          this.authStore.setLoading(false);
        }),
        catchError(this.handleError.bind(this))
      );
  }

  updateAvatarImage(data: FormData, fileProgressObject: FileProgressObject): Observable<any> {
    const url = 'api/profile/set-avatar';
    return this.apiService.postWithAttachment(url, data).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            fileProgressObject.progress = Math.round(
              ((event.total ? event.total : 1) * 100) / (event.total ? event.total : 1)
            );
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap((event: any) => {
        if (typeof event === 'object') {
          this.authStore.update((state) => ({
            ...state,
            user: {
              ...state.user,
              avatar_url: event.body.data.file_url,
            },
          }));
        }
      }),
      last(),
      catchError((error: HttpErrorResponse) => {
        fileProgressObject.inProgress = false;
        fileProgressObject.canRetry = true;
        return this.apiService?.catchError(error);
      })
    );
  }

  confirmSMSMobileVerification(code): Observable<AuthenticatedUser> {
    return this.apiService.post('api/verify/sms/confirm', { code }).pipe(tap((res) => this.setUser(res)));
  }

  confirmEmailVerification(token: string): Observable<string> {
    // @todo Test it for unauthorized user This may break
    return this.apiService.get('api/verify/email?t=' + token).pipe(
      tap((_) =>
        this.authStore.update((state) => ({
          ...state,
          user: {
            ...state.user,
            email_verification_status: UserVerificationStatus.COMPLETE,
            role: 'Member',
          },
        }))
      )
    );
  }

  createLinkToken(): Observable<string> {
    return this.apiService.post('api/verify/create_link_token', {}).pipe(
      tap((token) => {
        this.authStore.setLoading(false);
        this.setPlaidLinkToken(token);
      }),
      catchError((error) => {
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  updateKYCDetails(data: any): Observable<AuthenticatedUser> {
    return this.apiService.put('api/profile/kyc', data).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError((error) => {
        this.setUserKycVerificationStatus(UserVerificationStatus.FAILED);
        this.authStore.setError(error);
        this.authStore.setLoading(false);
        return this.apiService.catchError(error);
      })
    );
  }

  postRequestUserAttributeChange(attribute: string, data): Observable<AuthenticatedUser> {
    const copyData = { ...data };
    if (copyData.password) {
      copyData.password = CryptoUtil.bcryptHash(data.password);
    }
    return this.apiService.post('api/request-change/' + attribute, copyData).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  putChangeUsername(data: any): Observable<AuthenticatedUser> {
    const copyData = { ...data };
    if (copyData.password) {
      copyData.password = CryptoUtil.bcryptHash(data.password);
    }
    return this.apiService.put('api/profile/username', copyData).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updateProfile(data: ProfileUpdateRequest): Observable<AuthenticatedUser> {
    return this.apiService.put('api/profile/personal-info', data).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updateMissingProfile(data: ProfileUpdateRequest): Observable<AuthenticatedUser> {
    return this.apiService.put('api/profile/missing-info', data).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updateAbout(data: ProfileAboutUpdateRequest): Observable<AuthenticatedUser> {
    return this.apiService.put('api/profile/about', data).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  updatePublicVisibility(field: string, visibilityObject = []): Observable<AuthenticatedUser> {
    const arrayIndex = visibilityObject.indexOf(field);
    if (arrayIndex === -1) {
      visibilityObject = Object.assign([], visibilityObject);
      visibilityObject.push(field);
    } else {
      visibilityObject = Object.assign([], visibilityObject);
      visibilityObject.splice(arrayIndex, 1);
    }
    if (visibilityObject.length === 0) {
      visibilityObject = Object.assign([], visibilityObject);
      visibilityObject.push('name');
    }

    return this.apiService
      .put('api/profile/public-data-visibility', { visible_public_profile_data: visibilityObject })
      .pipe(
        tap((user: AuthenticatedUser) => {
          this.setUser(user);
          this.authStore.setLoading(false);
        }),
        catchError((error) => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
          return this.apiService.catchError(error);
        })
      );
  }

  updateSocialMedia(data): Observable<AuthenticatedUser> {
    return this.apiService.put('api/profile/socials', data).pipe(
      tap((user: AuthenticatedUser) => {
        this.setUser(user);
        this.authStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  postRequestModeratorAccess(type: ModerationType): Observable<AuthenticatedUser> {
    return this.apiService
      .post(`api/users/${type}-moderator-access`, {})
      .pipe(tap((user: AuthenticatedUser) => this.updateUser(user)));
  }

  postRequestBetaTesterAccess(): Observable<AuthenticatedUser> {
    return this.apiService.post(`api/users/become-beta-tester`, {}).pipe(catchError(this.handleError.bind(this)));
  }
}
