<ul id="navigation">
  <li
    class="active"
    *ngIf="showProposal"
    routerLinkActive="menu-top-link-active"
    [routerLinkActiveOptions]="{ exact: true }"
    joyrideStep="po1"
    stepPosition="right"
    title="Review/Evaluations & Liking reviews"
    text="Navigate to the Proposal page"
    (next)="pnav.click()"
    appTooltip="Projects"
    [canShow]="isMini"
    placement="right"
    routerLink="/"
  >
    <a routerLink="/" (click)="closeLeftMenu()" #pnav>
      <app-icon [icon]="'proposals'"></app-icon>
      <label>Projects</label>
    </a>
  </li>
  <li
    *ngIf="showProposal"
    routerLinkActive="menu-top-link-active"
    joyrideStep="pro1"
    stepPosition="right"
    appTooltip="Awards"
    [canShow]="isMini"
    placement="right"
    title="Project Award Overview"
    text="To view all available, upcoming and past awards, click Awards from the main menu."
    (next)="rnav.click()"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <a routerLink="/awards" (click)="closeLeftMenu()" #rnav>
      <app-icon [icon]="'round'"></app-icon>
      <label>Awards</label>
    </a>
  </li>
  <li
    routerLinkActive="menu-top-link-active"
    *ngIf="showCq"
    joyrideStep="cqe1"
    stepPosition="right"
    title="Engaging with Creative Query"
    text="Navigate to the Creative Queries page"
    (next)="cqnav.click()"
    appTooltip="Creative Queries"
    placement="right"
    [canShow]="isMini"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <a routerLink="/creative-query" (click)="closeLeftMenu()" #cqnav>
      <app-icon [icon]="'bulb'"></app-icon>
      <label>Creative Queries</label>
    </a>
  </li>
  <li
    routerLinkActive="menu-top-link-active"
    *ngIf="showQuiz"
    [routerLinkActiveOptions]="{ exact: false }"
    appTooltip="Quiz"
    [canShow]="isMini"
    placement="right"
  >
    <a routerLink="/quiz" (click)="closeLeftMenu()">
      <app-icon [icon]="'quiz'"></app-icon>
      <label>Quiz</label>
    </a>
  </li>
  <li
    routerLinkActive="menu-top-link-active"
    [routerLinkActiveOptions]="{ exact: true }"
    appTooltip="DCP+ (Beta)"
    [canShow]="isMini"
    placement="right"
    *ngIf="showWatch"
  >
    <a routerLink="/dcp-plus" (click)="closeLeftMenu()" #pnav>
      <app-icon [icon]="'play'"></app-icon>
      <label>DCP+ (Beta)</label>
    </a>
  </li>
  <li *ngIf="showSwag" routerLinkActive="menu-top-link-active" appTooltip="Store" placement="right">
    <a routerLink="/store" (click)="closeLeftMenu()">
      <app-icon [icon]="'ccard'"></app-icon>
      <label>Store</label>
    </a>
  </li>
  <li
    routerLinkActive="menu-top-link-active"
    [routerLinkActiveOptions]="{ exact: false }"
    appTooltip="How to"
    placement="right"
    [canShow]="isMini"
  >
    <a routerLink="/academy" (click)="closeLeftMenu()">
      <app-icon [icon]="'university'"></app-icon>
      <label>How to</label>
    </a>
  </li>
  <li
    routerLinkActive="menu-top-link-active"
    *ngIf="authenticatedUserQuery.isModerator && showModeration"
    [routerLinkActiveOptions]="{ exact: false }"
    appTooltip="Moderate"
    placement="right"
    [canShow]="isMini"
  >
    <a routerLink="/moderate" (click)="blockClick()">
      <app-icon [icon]="'moderate'"></app-icon>
      <label> Moderate</label>
    </a>
  </li>
  <li
    routerLinkActive="menu-top-link-active"
    *ngIf="authenticatedUserQuery.isAdministrator"
    [routerLinkActiveOptions]="{ exact: false }"
    appTooltip="Administration"
    placement="right"
    [canShow]="isMini"
  >
    <a routerLink="/administration/user" (click)="closeLeftMenu()">
      <app-icon [icon]="'admin'"></app-icon>
      <label>Administration</label>
    </a>
  </li>
  <li
    routerLinkActive="menu-top-link-active"
    *ngIf="buyEnabled()"
    [routerLinkActiveOptions]="{ exact: false }"
    appTooltip="Buy"
    placement="right"
    [canShow]="isMini"
    joyrideStep="bfc1"
    stepPosition="right"
    title="FILMCredits"
    text="To purchase FILMCredits, click Buy from the main menu."
    (next)="bfc.click()"
  >
    <a routerLink="/payment" (click)="closeLeftMenu()" #bfc>
      <app-icon [icon]="'bag'"></app-icon>
      <label>Buy</label>
    </a>
  </li>
  <li appTooltip="Help" placement="right" [canShow]="isMini">
    <a
      href="https://support-dcp.atlassian.net/servicedesk/customer/portal/1 "
      target="_blank"
      rel="noopener"
      (click)="closeLeftMenu()"
    >
      <app-icon [icon]="'help'"></app-icon>
      <label>Help</label>
    </a>
  </li>
</ul>
<app-referral-box
  *ngIf="isReferralProgram"
  [boxMode]="true"
  class="block mx-18"
  appTooltip="Referral Program"
  placement="right"
  [canShow]="isMini"
>
</app-referral-box>
