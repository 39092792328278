<div
  id="round-{{ round?.id }}"
  class="round sticky-round round-title"
  [ngClass]="{ details: shownDetails, 'video-cover': round?.cover_type === 'video' }"
>
  <ng-container *ngIf="round">
    <div
      class="flex justify-between w-full relative general-info"
      [ngClass]="{ 'min-[640px]:max-[1700px]:aspect-[16/3.7] overflow-hidden': !shownDetails }"
    >
      <div class="pt-16 px-16 header flex-1" #header>
        <p class="text-[14px] mt-8">Project Award</p>
        <a [routerLink]="['/awards', round.id]" class="md:text-xl text-lg mb-8">
          {{ round.title | shorten : 20 : '...' : !shownDetails && header.offsetWidth < 421 }}
        </a>
        <div class="misc">
          <div class="flex items-center md:mt-12">
            <app-icon [icon]="'calendar'" class="w-24 h-24 black opacity-50 mr-16"></app-icon>
            <div>
              <ng-container *ngIf="!round?.minimum_participants_to_go">
                <label class="text-xs font-semibold">End Date</label>
                <p class="font-bold">{{ getDays() }} {{ getDays() === 1 ? 'Day' : 'Days' }} to go</p>
              </ng-container>
              <ng-container *ngIf="round?.minimum_participants_to_go">
                <label class="text-xs font-semibold">Projects to go </label>
                <p class="font-bold">
                  {{
                    round?.minimum_participants_to_go - round?.proposal_count > 0
                      ? round?.minimum_participants_to_go - round?.proposal_count
                      : 0
                  }}
                </p>
              </ng-container>
            </div>
          </div>
          <div class="h-auto row-span-1 border-gray-border flex flex-col py-16 overflow-x-auto">
            <p class="text-sm">Evaluation Categories</p>
            <ul class="csv flex-grow overflow-x-auto flex flex-wrap" [style]="{ color: textColor }">
              <li *ngFor="let score of round.scores" class="pill mx-4 mt-8" [appTooltip]="score.name">
                {{ score.name | shorten : 20 : '...' }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <button
        class="ml-auto mr-16 expand-buton flex items-center mb-12 mt-[18%] min-[1700px]:mt-auto"
        (click)="showRoundDetails()"
      >
        <span class="ml-4 animated-arrow arrow-primary" [ngClass]="{ 'arrow-up': shownDetails }"></span>
      </button>
      <app-dcp-image
        class="cover"
        [src]="round?.cover_url"
        *ngIf="round.cover_url && round.cover_type !== 'video'"
      ></app-dcp-image>
      <app-video-player
        [videoConfig]="generateVideoConfig(round)"
        *ngIf="round.cover_url && round.cover_type === 'video'"
      ></app-video-player>
    </div>
  </ng-container>

  <div class="grid grid-cols-1 border-t mt-16 border-gray-border" *ngIf="shownDetails">
    <ng-container *ngIf="round">
      <div class="p-8 pb-0 relative">
        <app-expanded-info [roundChanges]="round"></app-expanded-info>
      </div>
    </ng-container>

    <app-moderations-overview
      class="block px-8"
      *ngIf="
        showModerationTable &&
        (authenticatedUserQuery.isAdministrator || authenticatedUserQuery.isModerator) &&
        entity.status === proposalStatus.TO_BE_MODERATED
      "
      [showSearch]="false"
      [entity]="entity"
      [entityType]="EntityTypeEnum.PROPOSAL"
      [mobileViewForAllScreens]="mobileViewModerationTable"
      [isAddTransparent]="isAddTransparent"
    ></app-moderations-overview>
  </div>
</div>
