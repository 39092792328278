import { EventEmitter, inject, Injectable } from '@angular/core';
import { Filters, MultiViewActions } from '../../../app.datatypes';
import { environment } from '../../../../environments';
import { ApiService } from '../api.service';

export interface HorizontalEntitiesLimit {
  parent: number;
  child: number;
  skip: number;
}

@Injectable({
  providedIn: 'root',
})
export class HorizontalSliderService {
  #apiService = inject(ApiService);
  horizontaEntityActions: EventEmitter<{ action: MultiViewActions; data: object }> = new EventEmitter();
  roundViewDimensions = {
    width: 380,
    height: 214,
  };
  limit = {
    parent: 4,
    child: 6,
    skip: 0,
    cardWidth: 396,
    parentWidth: window.innerWidth,
    itemsVisible: 5,
  };

  setFilters(api: string, filters: Filters, skip = 0): string {
    api = `${api}?limit=${environment.grid_skip_limit}&direction=desc&skip=${skip}&${this.#apiService.getQueryString(
      filters
    )}`;
    return api;
  }

  handlePaginationProperties(length: number, page = 1, leftPositionSlider = 0): object {
    this.limit.itemsVisible = Math.floor(this.limit.parentWidth / this.limit.cardWidth);
    const paginationCount = Math.ceil(length / this.limit.itemsVisible);
    return {
      page: page,
      leftPositionSlider: leftPositionSlider,
      paginationCount,
      nxtDisabled: paginationCount === page,
    };
  }

  setLimitsDependOnScreenWidth(): void {
    const width = window.innerWidth;
    if (width <= 860 && width > 540) {
      this.limit.child = 4;
    } else if (width <= 540) {
      this.limit.child = 3;
    }
  }
}
