import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Payment, PricingFees } from '../../../../../src/app/app.datatypes';

export interface PaymentState extends EntityState<Payment> {
  filters: {
    search: string;
  };
  skip: number;
  apiEndReached: boolean;
  fees: PricingFees;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'payment', idKey: 'id', resettable: true })
export class PaymentStore extends EntityStore<PaymentState> {
  constructor() {
    super({
      ui: { showFilters: false },
      filters: {
        search: '',
      },
      skip: 0,
      apiEndReached: false,
      fees: {
        proposal_resubmission_fee: 0,
        proposal_moderation_fee: 0,
        proposal_extension_fee: 0,
        review_moderation_fee: 0,
        creative_query_moderation_fee: 0,
        creative_query_submission_fee: 0,
        creative_query_resubmission_fee: 0,
        proposal_resubmission_fee_in_dollar: 0,
        proposal_moderation_fee_in_dollar: 0,
        proposal_extension_fee_in_dollar: 0,
        review_moderation_fee_in_dollar: 0,
        creative_query_moderation_fee_in_dollar: 0,
        creative_query_submission_fee_in_dollar: 0,
        creative_query_resubmission_fee_in_dollar: 0,
        credit_card_fee_minimum_in_dollar: 0,
        credit_card_percent_fee: 0,
        sign_up_fee: 0,
        kyc_fee: 0,
        available_film_tokens: 0,
        price_of_film: 0,
        price_of_film_sale: 0,
        price_of_film_last_updated: '',
      },
    });
  }
}
