<form [formGroup]="stepFiveForm" autocomplete="off" style="overflow-y: auto; height: calc(100% - 72px)">
  <div *ngIf="selectedRound || joyrideService?.isTourInProgress()">
    <div class="grid sm:grid-cols-2 gap-32">
      <div
        joyrideStep="proposalcreate11"
        [title]="joyrideService?.isTourInProgress() ? 'Create Project' : ''"
        text="Read & Accept Submission Terms and then Submit for Moderation."
      >
        <app-logo class="logo-black w-128 block mb-16"></app-logo>
        <p class="mb-16 d-block text-base font-semibold">
          Please review and agree to the Terms of Submission and choose your preferred payment method for submission.
          Good luck with your submission.
        </p>
        <app-dcp-checkout
          class="block max-w-500"
          [isLoading]="isLoading"
          [user]="user"
          [films]="checkoutAmount"
          (changeCheckoutType)="changeCheckoutType($event)"
          (formValidity)="checkoutFormValidityChange($event)"
        >
        </app-dcp-checkout>
        <p class="-mt-8 text-error mb-16" *ngIf="checkoutAmount > user.balance && !isExternalCheckout">
          You have an insufficient Current Balance for this amount. Please choose another payment method to continue
          with this amount.
        </p>
        <label class="checkbox">
          <input type="checkbox" id="terms" (change)="setChecked()" formControlName="termsAccepted" />
          <span class="checkmark" style="border-width: 2px !important"></span>
          By clicking this checkbox, you agree to our
          <a rel="noopener noreferrer" href="https://decentralized.pictures/terms-of-service" target="_blank"
            >Terms of Service</a
          >
          and
          <a rel="noopener noreferrer" href="https://decentralized.pictures/community-guidelines" target="_blank"
            >Community Guidelines.</a
          >
        </label>
      </div>
      <div>
        <div class="rounded max-w-500 ml-auto">
          <app-bounty-info
            *ngIf="(!proposal?.blockchain_confirmed && selectedRound) || joyrideService?.isTourInProgress()"
            [title]="'Submission summary'"
            [additional]="this.stepFiveForm.value.userBounty"
            (recalculateBountyValue)="handleBounty($event)"
            (recalculateTotal)="bountyTotalChange($event)"
            [submissionFee]="submitFeeFilm"
            [minimumBounty]="selectedRound?.minimum_bounty || 0"
            [resubmissionFee]="null"
            [isExternalCheckout]="isExternalCheckout"
            class="mb-16"
          >
          </app-bounty-info>

          <app-bounty-info
            *ngIf="proposal?.blockchain_confirmed && selectedRound"
            [title]="'Resubmission summary'"
            [additional]="this.stepFiveForm.value.userBounty"
            (recalculateBountyValue)="handleBounty($event)"
            (recalculateTotal)="bountyTotalChange($event)"
            [submissionFee]="null"
            [minimumBounty]="null"
            [resubmissionFee]="paymentQuery.proposalResubmissionFilmFee"
            class="mb-16"
          >
          </app-bounty-info>
        </div>
      </div>
    </div>
  </div>
</form>
