import {
  Component,
  OnInit,
  Output,
  OnDestroy,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  DestroyRef,
} from '@angular/core';
import {
  ToasterService,
  SEOService,
  AuthenticatedUserService,
  AuthenticatedUserQuery,
  TezosWalletService,
  TezosWalletType,
} from '../../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { panelIn } from '../../../app.animations';
import { AuthService } from '../../../shared/services/auth.service';
import { MaintainanceDilaogComponent } from '../../../shared/modules/dialog/maintainance-dilaog/maintainance-dilaog.component';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { environment } from '../../../../environments';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WalletLoginData } from '../../../app.datatypes';
import { EMPTY, from, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, switchMap } from 'rxjs/operators';

enum VerifierPlatform {
  GOOGLE = 'google',
  TWITTER = 'twitter',
  APPLE = 'apple',
}

enum KeyCode {
  BACKSPACE = 8,
  CONTROL = 31,
  ZERO = 48,
  NINE = 57,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    class: 'fixed top-0 left-0 w-full h-full z-10',
  },
  animations: [panelIn],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() slug: string;
  @Output() close = new EventEmitter();
  @Output() authClear = new EventEmitter();
  @Output() changePage = new EventEmitter();

  @ViewChild('goog2fa_code', { static: false }) goog2faInput: ElementRef;
  @ViewChild('goog2faAuth', { static: false }) goog2faAuth: ElementRef;
  @ViewChild('goog2fa_code1', { static: false }) goog2faCode1Elem: ElementRef;
  @ViewChild('goog2fa_code2', { static: false }) goog2faCode2Elem: ElementRef;
  @ViewChild('goog2fa_code3', { static: false }) goog2faCode3Elem: ElementRef;
  @ViewChild('goog2fa_code4', { static: false }) goog2faCode4Elem: ElementRef;
  @ViewChild('goog2fa_code5', { static: false }) goog2faCode5Elem: ElementRef;
  @ViewChild('goog2fa_code6', { static: false }) goog2faCode6Elem: ElementRef;

  ARRAY_LENGTH_ONE = 1;

  errors: any; // any is ok here
  refToken: string;
  betaTesterToken: string;
  betaTester: boolean;
  returnUrl: string = null;
  tempalWallets = ['/assets/img/tempal-wallet.svg', '/assets/img/sentinal-wallet.svg'];
  goog2faRequired: boolean;
  isLoginLoading: boolean;

  form = new FormGroup({
    goog2fa_code: new FormControl('', Validators.maxLength(6)),
    goog2fa_code1: new FormControl('', Validators.maxLength(1)),
    goog2fa_code2: new FormControl('', Validators.maxLength(1)),
    goog2fa_code3: new FormControl('', Validators.maxLength(1)),
    goog2fa_code4: new FormControl('', Validators.maxLength(1)),
    goog2fa_code5: new FormControl('', Validators.maxLength(1)),
    goog2fa_code6: new FormControl('', Validators.maxLength(1)),
  });

  selectedTab = 0;
  walletLoginRetries = 0;
  loginPayload: WalletLoginData;
  twoFactorTimer = null;
  verifierPlatform = VerifierPlatform;

  constructor(
    private authenticatedUserService: AuthenticatedUserService,
    private authService: AuthService,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private _seoService: SEOService,
    private dialogService: DialogService,
    private tezosWalletService: TezosWalletService,
    private renderer: Renderer2,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.refToken = params['ref'];
      this.betaTester = params['betaTester'] == 1;
      this.betaTesterToken = params['btt'];
    });
    this._seoService.createTitleForPage();
    this.route.queryParams.subscribe((params) => (this.returnUrl = params['return']));
    const auth = localStorage.getItem('Unauthorized');
    if (auth) {
      localStorage.removeItem('Unauthorized');
      this.authClear.emit();
    }
  }

  closePanel(): void {
    this.authenticatedUserQuery.unAuthAction.next(null);
  }

  loginKukai(selectedVerifier = VerifierPlatform.GOOGLE) {
    try {
      this.isLoginLoading = true;
      this.tezosWalletService.setWalletType(TezosWalletType.KUKAI);
      this.loginVerification().subscribe();
      this.tezosWalletService.setSocialLoginType(selectedVerifier);
    } catch (error) {
      this.isLoginLoading = false;
    }
  }

  loginBeacon() {
    try {
      this.tezosWalletService.setWalletType(TezosWalletType.BEACON);
      this.loginVerification().subscribe();
    } catch (error) {
      this.isLoginLoading = false;
    }
  }

  loginVerification() {
    return from(this.tezosWalletService.logout()).pipe(
      switchMap(() =>
        from(this.tezosWalletService.loginVerification()).pipe(
          switchMap((response) => {
            this.loginPayload = response;

            return this.loginResponseSubscribeFunction();
          })
        )
      ),
      catchError(() => this.disableLoading())
    );
  }

  disableLoading(): Observable<never> {
    this.isLoginLoading = false;
    return EMPTY;
  }

  private loginResponseSubscribeFunction() {
    this.isLoginLoading = true;

    if (this.walletLoginRetries > 1) {
      return;
    }

    return this.authService
      .loginWithWallet(
        this.loginPayload.address,
        this.loginPayload.pk,
        this.loginPayload.sig,
        this.loginPayload.nonce,
        this.goog2faRequired ? this.form.value.goog2fa_code : null
      )
      .pipe(
        switchMap(() => this.authenticatedUserService.userByToken()),
        switchMap(() => {
          if (environment.readOnlyMode) {
            return this.dialogService.open(MaintainanceDilaogComponent);
          }

          if (this.twoFactorTimer) {
            clearTimeout(this.twoFactorTimer);
          }

          if (this.slug) {
            this.closePanel();
          } else {
            this.onAuthUserLogin();
          }

          this.close.emit();
          return from(this.tezosWalletService.validateWallet());
        }),
        catchError((error) => {
          this.isLoginLoading = false;
          return this.handleLoginError(error);
        }),
        takeUntilDestroyed(this.destroyRef)
      );
  }

  onAuthUserLogin() {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate(['/']);
    }
  }

  isNumberKey(evt) {
    const charCode = evt.keyCode;
    if (charCode > KeyCode.CONTROL && (charCode < KeyCode.ZERO || charCode > KeyCode.NINE)) {
      return false;
    }
    return true;
  }

  keyDigit1DownFunction(event, val) {
    if (val.length === this.ARRAY_LENGTH_ONE) {
      this.goog2faCode2Elem?.nativeElement.focus();
    }
  }

  keyDigit2DownFunction(event, val) {
    if (event.keyCode === KeyCode.BACKSPACE) {
      this.renderer.setProperty(this.goog2faCode1Elem?.nativeElement, 'value', '');
      this.goog2faCode1Elem?.nativeElement.focus();
    }

    if (val.length === this.ARRAY_LENGTH_ONE) {
      this.goog2faCode3Elem?.nativeElement.focus();
    }
  }

  keyDigit3DownFunction(event, val) {
    if (event.keyCode === KeyCode.BACKSPACE) {
      this.renderer.setProperty(this.goog2faCode2Elem?.nativeElement, 'value', '');
      this.goog2faCode2Elem?.nativeElement.focus();
    }

    if (val.length === this.ARRAY_LENGTH_ONE) {
      this.goog2faCode4Elem?.nativeElement.focus();
    }
  }

  keyDigit4DownFunction(event, val) {
    if (event.keyCode === KeyCode.BACKSPACE) {
      this.renderer.setProperty(this.goog2faCode3Elem?.nativeElement, 'value', '');
      this.goog2faCode3Elem?.nativeElement.focus();
    }

    if (val.length === this.ARRAY_LENGTH_ONE) {
      this.goog2faCode5Elem?.nativeElement.focus();
    }
  }

  keyDigit5DownFunction(event, val) {
    if (event.keyCode === KeyCode.BACKSPACE) {
      this.renderer.setProperty(this.goog2faCode4Elem?.nativeElement, 'value', '');
      this.goog2faCode4Elem?.nativeElement.focus();
    }

    if (val.length === this.ARRAY_LENGTH_ONE) {
      this.goog2faCode6Elem?.nativeElement.focus();
    }
  }

  keyDigit6DownFunction(event, val) {
    if (event.keyCode === KeyCode.BACKSPACE) {
      this.renderer.setProperty(this.goog2faCode5Elem?.nativeElement, 'value', '');
      this.goog2faCode5Elem?.nativeElement.focus();
    }

    if (val.length === this.ARRAY_LENGTH_ONE) {
      this.form.value.goog2fa_code =
        ' ' +
        this.form.value.goog2fa_code1 +
        this.form.value.goog2fa_code2 +
        this.form.value.goog2fa_code3 +
        this.form.value.goog2fa_code4 +
        this.form.value.goog2fa_code5 +
        this.form.value.goog2fa_code6;

      this.form.value.goog2fa_code = this.form.value.goog2fa_code.trim();
      this.isLoginLoading = true;
      this.loginResponseSubscribeFunction().subscribe();
    }
  }

  handleLoginError(error) {
    let err = error;
    if (error && typeof error.error !== 'object' && error.error) {
      err = error.error;
    } else if (error && typeof error.error === 'object' && error.error['error'] === 'goog2fa_required') {
      this.goog2faRequired = true;
      setTimeout(() => {
        this.goog2faCode1Elem?.nativeElement.focus();
      }, 500);
      return;
    } else if (error && typeof error.error === 'object' && error.error['error'] === 'invalid_goog2fa') {
      this.renderer.addClass(this.goog2faAuth?.nativeElement, 'error');
    } else if (error && typeof error.error === 'object' && error.error['error'] === 'invalid_credentials') {
      this.isLoginLoading = true;
      // 401 Unauthorized with code invalid_credentials -- Means user wasn't registered.
      // Register them now!  After that login again
      return this.authenticatedUserService
        .registerWithWallet(
          this.loginPayload.address,
          this.loginPayload.pk,
          this.loginPayload.sig,
          this.loginPayload.nonce.toString(),
          this.tezosWalletService.walletType,
          this.refToken,
          this.betaTester,
          this.betaTesterToken,
          this.tezosWalletService.verifier,
          this.tezosWalletService.userEmail
        )
        .pipe(
          switchMap((user) => {
            if (!user?.could_be_rewarded && this.refToken?.length) {
              this.toasterService.openToastrWithLink(
                'Sorry, it looks like this referral bonus has already been claimed. Don’t worry - you can still earn rewards by inviting other to join the DCP platform. Visit the Referrals page for info on how to get started.',
                'DCP Referral Program',
                'warning',
                null,
                'account/referral',
                'Referral Bonus',
                'Visit the Referrals page'
              );
            }
            this.walletLoginRetries += 1;
            return this.loginResponseSubscribeFunction();
          }),
          takeUntilDestroyed(this.destroyRef)
        );
    }
    if (error?.error?.message) {
      err = error.error.message;
    }
    this.isLoginLoading = false;
    this.toasterService.openToastr(err, 'none', 'error', 3000);
  }

  ngOnDestroy() {
    this.toasterService.closeToastr();
  }
}
