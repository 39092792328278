import { Injectable } from '@angular/core';
import DirectWebSdk from '@toruslabs/customauth';
import { TORUS_LEGACY_NETWORK } from '@toruslabs/constants';
import { OperationService } from './operation/operation.service';
import { CONSTANTS } from '../../environments/environment';
import { CryptoUtil } from '../shared/util/crypto-util';

const GOOGLE = 'google';
const REDDIT = 'reddit';
const TWITTER = 'twitter';
const APPLE = 'apple';
const FACEBOOK = 'facebook';
const EMAIL = 'email';
const AUTH_DOMAIN = 'https://dev-u4j7qr8x.us.auth0.com';
const AUTH_DOMAIN_MAINNET = 'https://dev-u4j7qr8x.us.auth0.com';
@Injectable({
  providedIn: 'root',
})
export class TorusService {
  readonly web3AuthClientId = CONSTANTS.MAINNET
    ? 'BLQifFrG2BK7I719MiqtKAgTC38zPZbxPnsDrcYVJ0mrJQG9K-ev6r8Z4c83n46fRLpCwEgsqbZS5lFWyJW-eCY'
    : 'BGMjC4YJ5OMCaaaOJkjK0ls6P2Zej6A_VdBIfVrpcgvv8Tjjz-S1bA2a499lkqZ9nrXBsNPyKZZh07lQy6SPAXw';
  torus: any = undefined;
  public readonly verifierMap: any;
  private readonly proxy: any;
  verifierMaps = {
    testnet: {
      [GOOGLE]: {
        name: 'Google',
        typeOfLogin: 'google',
        clientId: '955068534712-g128qq799cj35jrsb98947tjusj53rnb.apps.googleusercontent.com',
        verifier: 'tlnt-testnet',
        caseSensitiveVerifierID: false,
        lookups: true,
      },
      [TWITTER]: {
        name: 'Twitter',
        typeOfLogin: 'twitter',
        verifier: 'tlnt-testnet-twitter',
        clientId: '9lZGdLGOVZxpxOJY56RIlUVO3UsuUajT',
        caseSensitiveVerifierID: false,
        lookups: true,
      },
      [APPLE]: {
        name: 'Apple',
        typeOfLogin: 'apple',
        verifier: 'tlnt-testnet-apple',
        clientId: '9lZGdLGOVZxpxOJY56RIlUVO3UsuUajT',
        caseSensitiveVerifierID: false,
        lookups: false,
      },
      [FACEBOOK]: {
        name: 'Facebook',
        typeOfLogin: 'facebook',
        verifier: 'tlnt-testnet-fb',
        clientId: '475860913802038',
        caseSensitiveVerifierID: false,
      },
    },
    mainnet: {
      [GOOGLE]: {
        name: 'Google',
        typeOfLogin: 'google',
        clientId: '955068534712-2slh7v6h34eu8lsrbpla74p6hqrbp1to.apps.googleusercontent.com',
        verifier: 'tlnt-google',
        caseSensitiveVerifierID: false,
        lookups: true,
      },
      [TWITTER]: {
        name: 'Twitter',
        typeOfLogin: 'twitter',
        verifier: 'tlnt-twitter',
        clientId: 'yRJQKywqd7IZhB17OKWLY181KKCT11N2',
        caseSensitiveVerifierID: false,
        lookups: true,
      },
      [APPLE]: {
        name: 'Apple',
        typeOfLogin: 'apple',
        verifier: 'tlnt-apple',
        clientId: 'yRJQKywqd7IZhB17OKWLY181KKCT11N2',
        caseSensitiveVerifierID: false,
        lookups: false,
      },
      [FACEBOOK]: {
        name: 'Facebook',
        typeOfLogin: 'facebook',
        verifier: 'tlnt-facebook',
        clientId: '980346822520326',
        caseSensitiveVerifierID: false,
      },
    },
  };
  verifierMapKeys: any;
  constructor(private operationService: OperationService) {
    if (CONSTANTS.MAINNET) {
      this.verifierMap = this.verifierMaps.mainnet;
      this.proxy = {
        network: TORUS_LEGACY_NETWORK.MAINNET,
      };
    } else {
      this.verifierMap = this.verifierMaps.testnet;
      this.proxy = {
        network: TORUS_LEGACY_NETWORK.TESTNET,
      };
    }
    this.verifierMapKeys = Object.keys(this.verifierMap);
  }
  private async isBraveOrChrome(): Promise<boolean> {
    return (await (<any>navigator)?.brave?.isBrave()) || navigator.userAgent.includes('Chrome') || false;
  }
  async initTorus() {
    if (this.torus === undefined) {
      this.torus = null;
      try {
        // set this value to false in every browser except for brave
        const redirectToOpener = await this.isBraveOrChrome();
        const torusdirectsdk = new DirectWebSdk({
          web3AuthClientId: this.web3AuthClientId,
          baseUrl: `${location.origin}/serviceworker`,
          redirectToOpener,
          enableLogging: this.proxy.network !== 'mainnet',
          network: this.proxy.network,
        });
        await torusdirectsdk.init({ skipSw: false });
        this.torus = torusdirectsdk;
      } catch (error) {
        this.torus = undefined;
        console.error(error, 'oninit caught');
      }
    }
  }
  async loginTorus(selectedVerifier: string, verifierId = '', skipTorusKey = 0, checkIfNewKey = false): Promise<any> {
    if (
      !CONSTANTS.MAINNET &&
      document?.location?.host === 'localhost:4200' &&
      !['google', 'twitter', 'email'].includes(selectedVerifier)
    ) {
      return this.mockLogin(selectedVerifier); // mock locally
    }
    try {
      const jwtParams: any = this._loginToConnectionMap()[selectedVerifier] || {};
      if (verifierId && selectedVerifier === GOOGLE) {
        jwtParams.login_hint = verifierId;
      }
      if (verifierId && selectedVerifier === EMAIL) {
        jwtParams.login_hint = verifierId;
      }
      const { typeOfLogin, clientId, verifier, aggregated } = this.verifierMap[selectedVerifier];
      const loginDetails = aggregated
        ? await this.torus.triggerAggregateLogin({
            login_hint: verifierId,
            aggregateVerifierType: 'single_id_verifier',
            verifierIdentifier: verifier,
            sessionTime: 7 * 86400, // Max session time
            subVerifierDetailsArray: [
              {
                clientId,
                typeOfLogin: typeOfLogin,
                verifier: this.verifierMap[selectedVerifier].subVerifier,
                jwtParams,
              },
            ],
            skipTorusKey,
            checkIfNewKey,
          })
        : await this.torus.triggerLogin({
            verifier,
            sessionTime: 7 * 86400, // Max session time
            typeOfLogin,
            clientId,
            jwtParams,
            skipTorusKey,
            checkIfNewKey,
          });
      if (aggregated) {
        loginDetails.userInfo = loginDetails.userInfo[0];
      }
      if (selectedVerifier === FACEBOOK) {
        fetch(`https://graph.facebook.com/me/permissions?access_token=${loginDetails.userInfo.accessToken}`, {
          method: 'DELETE',
          mode: 'cors',
        });
      }
      const keyPair =
        skipTorusKey && !loginDetails?.finalKeyData?.privKey
          ? { pk: '', pkh: '' }
          : this.operationService.spPrivKeyToKeyPair(loginDetails.finalKeyData.privKey);
      if (loginDetails?.existingPk) {
        loginDetails.userInfo.preexistingPkh = this.operationService.spPointsToPkh(
          loginDetails.existingPk.X,
          loginDetails.existingPk.Y
        );
        loginDetails.userInfo.isNewKey = !loginDetails?.existingPk;
      }
      if (loginDetails?.userInfo?.typeOfLogin === 'jwt') {
        loginDetails.userInfo.typeOfLogin = selectedVerifier;
      }
      return { keyPair, userInfo: loginDetails.userInfo };
    } catch (e) {
      console.error(e, 'login caught');
      return { keyPair: null, userInfo: null };
    }
  }
  _loginToConnectionMap = () => {
    return {
      [TWITTER]: {
        domain: CONSTANTS.MAINNET ? AUTH_DOMAIN_MAINNET : AUTH_DOMAIN,
      },
      [APPLE]: {
        domain: CONSTANTS.MAINNET ? AUTH_DOMAIN_MAINNET : AUTH_DOMAIN,
        connection: 'apple',
      },
      [FACEBOOK]: {
        scope: 'public_profile email',
      },
      [REDDIT]: {
        domain: CONSTANTS.MAINNET ? AUTH_DOMAIN_MAINNET : AUTH_DOMAIN,
        connection: 'Reddit',
        verifierIdField: 'name',
        isVerifierIdCaseSensitive: false,
      },
      [EMAIL]: {
        domain: CONSTANTS.MAINNET ? AUTH_DOMAIN_MAINNET : AUTH_DOMAIN,
        connection: '',
        verifierIdField: 'name',
        isVerifierCaseSensitive: false,
      },
    };
  };
  private async mockLogin(typeOfLogin: string): Promise<any> {
    const keyPair = {
      sk: 'spsk1VfCfhixtzGvUSKDre6jwyGbXFm6aoeLGnxeVLCouueZmkgtJF',
      pk: 'sppk7cZsZeBApsFgYEdWuSwj92YCWkJxMmBfkN3FeKRmEB7Lk5pmDrT',
      pkh: 'tz2WKg52VqnYXH52TZbSVjT4hcc8YGVKi7Pd',
    };
    const userInfo = {
      typeOfLogin,
      verifierId: typeOfLogin !== 'google' ? 'MockUser' : 'mock@user.com',
      name: typeOfLogin !== 'twitter' ? 'Mock User' : '@MockUser',
    };
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ keyPair, userInfo });
      }, 1000);
    });
  }

  async loadSessionUser(pwd = '') {
    let encString = sessionStorage.getItem('sw') || localStorage.getItem('sw');
    let isReg = sessionStorage.getItem('isReg') || localStorage.getItem('isReg');
    if (isReg) {
      // If the user was stored on registration, eg no password / user ID
      pwd = '';
    }
    let user;
    if (encString) {
      let decString = await CryptoUtil.decrypt(encString, pwd);
      user = JSON.parse(decString);
    }
    return user;
  }

  async storeSessionUser(user, pwd = '') {
    let encString = JSON.stringify(user);
    encString = await CryptoUtil.encrypt(encString, pwd);
    if (!pwd) {
      // Store this when there's no password -- indicating a user registering without a userID
      localStorage.setItem('isReg', 'true');
    } else localStorage.removeItem('isReg');
    localStorage.setItem('sw', encString);
  }
}
