<ul class="flex h-full items-center" *ngIf="user && user.id">
  <li *ngIf="!this.authenticatedUserQuery.isRegistrationComplete">
    <button (click)="completeRegistration()" class="btn btn-primary min-w-0">Complete Registration</button>
  </li>
  <ng-container *ngIf="authenticatedUserQuery.isRegistrationComplete">
    <li
      *ngIf="user.drafts"
      class="activity flex items-center px-16 relative cursor-pointer whitespace-nowrap"
      id="pocket"
      appTooltip="View Drafts"
      placement="bottom"
      routerLink="/user/projects"
      [queryParams]="{ action: 'drafts' }"
    >
      <app-icon [icon]="'edit-proposal'" class="w-24 h-24 opacity-50 mr-8"></app-icon>
      <span>View Drafts: {{ user.drafts }}</span>
    </li>
    <li
      *ngIf="!showRegistrationOnly"
      class="activity flex items-center px-16 relative cursor-pointer whitespace-nowrap"
      id="pocket"
    >
      <app-icon [icon]="'activity'" class="w-24 h-24 opacity-50 mr-8"></app-icon>
      <span>Activity: {{ info.fillChart }}%</span>
      <app-potential-earnings [data]="info"> </app-potential-earnings>
    </li>
    <li
      class="flex items-center px-16 cursor-pointer"
      appTooltip="Notifications"
      placement="bottom"
      (click)="showInfoBoxFn()"
    >
      <app-icon [icon]="'bell'" class="w-24 h-24 opacity-50 mr-8"></app-icon>
      <span>{{ notificationCount }}</span>
    </li>
    <li
      class="balance flex items-center px-16 relative cursor-pointer whitespace-nowrap"
      routerLink="/account/wallet"
      routerLinkActive="drop-menu-hidden"
    >
      <app-icon [icon]="'wallet'" class="w-24 h-24 opacity-50 mr-8"></app-icon>
      <span>{{ user.balance || 0 | filmPrice }}</span>
      <app-balance-details [data]="balance"> </app-balance-details>
      <div id="coinAnimationPocket" [ngClass]="{ show: isCoinAnimation }">
        <div class="coins-wrapper">
          <span class="coin"></span>
          <span class="coin"></span>
          <span class="coin"></span>
          <span class="coin"></span>
          <span class="coin"></span>
          <span class="coin"></span>
        </div>
      </div>
    </li>
    <li
      *ngIf="!showRegistrationOnly"
      class="flex items-center px-16 cursor-pointer"
      routerLink="/leader-board"
      appTooltip="Your reputation"
      placement="bottom"
    >
      <app-icon [icon]="'reputations'" class="w-24 h-24 opacity-50 mr-8"></app-icon>
      <span>{{ user.reputation | filmPrice }}</span>
    </li>
    <li
      *ngIf="showSwag && !showRegistrationOnly"
      class="flex items-center pl-32 cursor-pointer relative"
      routerLink="/cart"
      appTooltip="Cart"
      placement="bottom"
    >
      <app-icon [icon]="'cart'" class="w-24 h-24 opacity-50 mr-8"></app-icon>
      <span
        class="rounded-full text-white -top-8 m-w-18 h-18 text-sm p-4 flex items-center justify-center bg-error absolute"
        *ngIf="cartCount"
        >{{ cartCount }}</span
      >
    </li>
  </ng-container>
</ul>
