import { Component, DestroyRef, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, EMPTY, from, merge, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  AuthenticatedUser,
  AuthenticatedUserQuery,
  CreativeQueryQuery,
  CreativeQueryStateService,
  EmbedVideoService,
  ErrorsHandlerService,
  PaymentStateService,
  ProgressRef,
  ProgressService,
  ProposalRoundService,
  SEOService,
  SharedService,
  TezosWalletService,
  ToasterService,
  UnAuthAction,
  UserService,
  WindowScrollService,
} from '../../../shared';
import {
  CreativeQuery,
  ProcessStatus,
  ExternalCheckoutAction,
  ExternalCheckoutRequest,
  Option,
  PricingFees,
  Question,
  Region,
  SubmitCreativeQueryRequest,
  Demography,
} from '../../../app.datatypes';
import { ComponentCanDeactivateDirective } from '../../../shared/services/component-can-deactivate';
import { panelIn } from '../../../app.animations';
import { environment } from '../../../../environments';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { JoyrideService } from 'ngx-joyride';
import { GlobalTourService } from '../../../shared/services/global-tour-service.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VideoConfig } from '../../../shared/interface/video-config.interface';

type CreativeQueryResult = Observable<CreativeQuery | PricingFees | []>;

@Component({
  selector: 'app-query-create',
  templateUrl: './query-create.component.html',
  styleUrls: ['./query-create.component.scss'],
  animations: [panelIn],
})
export class QueryCreateComponent extends ComponentCanDeactivateDirective implements OnInit {
  @Output() closeCreateCQSidebar = new EventEmitter();

  @ViewChild('tab1', { static: false }) tab1: ElementRef;
  @ViewChild('tab2', { static: false }) tab2: ElementRef;
  @ViewChild('tab3', { static: false }) tab3: ElementRef;

  scrollRestricted = this.joyRideService.isTourInProgress();
  loading = false;
  activeIndex = 0;
  ageGroupList = [];
  isExternalCheckout = false;
  checkoutFormValidity = true;
  user: AuthenticatedUser;
  processStatus = ProcessStatus;
  colorList = [
    { slug: 'red', name: 'Coral', color: 'red' },
    { slug: 'blue', name: 'Sky Blue', color: 'blue' },
    { slug: 'green', name: 'Dark Sea Green', color: 'green' },
    { slug: 'purple', name: 'Medium Purple', color: 'purple' },
    { slug: 'cyan', name: 'Pale Violet Red', color: 'cyan' },
    { slug: 'indigo', name: 'Cornflower Blue', color: 'indigo' },
    { slug: 'olive', name: 'Olive', color: 'olive' },
  ];
  brandList = [
    { slug: 'artist', name: 'Talent' },
    { slug: 'director', name: 'Film Reel' },
    { slug: 'film', name: 'Clapper Board' },
    { slug: 'movie', name: 'Movie' },
    { slug: 'music', name: 'Music' },
    { slug: 'video', name: 'Video' },
    { slug: 'writer', name: 'Writer' },
  ];
  genderList = [
    { id: '0', slug: 'M', name: 'Male' },
    { id: '1', slug: 'F', name: 'Female' },
    { id: '2', slug: 'O', name: 'Other' },
  ];
  countryList$: Observable<Region[]>;
  creativeQuery: CreativeQuery;
  submitFeeFilm: number;
  submitFeeDollars: number;
  checkoutFormGroup: UntypedFormGroup;
  preview: UntypedFormGroup;
  readyToSave = false;
  isLinear = true;
  form: UntypedFormGroup = this.formBuilder.group({
    title: [null, [Validators.required, Validators.maxLength(191)]],
    question: [null, [Validators.required, Validators.minLength(6)]],
    bounty: [0],
    query_age: [null, [Validators.required]],
    demography: this.formBuilder.group({
      gender: [],
      age_group: [],
      region: [],
    }),

    cover: ['default', [Validators.required]],
    cover_color: ['default', [Validators.required]],
    enable_chat: true,
  });
  questions: Question[];
  questionsPreview: Question[];
  isNew = true;
  answerClicked = false;
  creativeQueryId: string;
  minimumBounty = 0;
  totalAmount = 0;
  hasQuestionError: boolean;
  errorQuestionMessage: string;
  hasOptionError: boolean;
  errorOptionMessage: string;

  dark = false;
  submitting = false;
  selectedColor = '';
  selectedBrand = '';
  selectedGender = [];
  selectedAge = [];
  selectedRegion = [];
  ageMinValue = 1;
  ageMaxValue = 30;
  tabOneActive = true;
  tabTwoActive = false;
  tabThreeActive = false;
  progress: ProgressRef;

  // New
  isQuestionValid = false;
  options: Option[];
  optionCount = 0;
  addOptionType: string;
  optionForm: UntypedFormGroup;
  imageFiles = [];
  isOpenModal = false;
  optionModalIndex = 0;
  optionImageUploaded: string;
  optionVideoUploaded: string | SafeHtml;
  isOptionDirectVideoUpload = false;
  optionModalTitle: string;
  optionModalValue: string;
  optionModalDescription: string;
  fileUploaded: string;
  fileId: string;
  optionTitle: string;
  hasError = false;
  isCreativeQueryEdit = false;
  creativeQueryMode: string;
  isBountyEdit = false;

  constructor(
    private readonly cqService: CreativeQueryStateService,
    private readonly cqQuery: CreativeQueryQuery,
    private readonly sanitizer: DomSanitizer,
    private readonly authenticatedUserQuery: AuthenticatedUserQuery,
    private readonly proposalRoundService: ProposalRoundService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly paymentStateService: PaymentStateService,
    private readonly toastService: ToasterService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _seoService: SEOService,
    private readonly errorService: ErrorsHandlerService,
    private readonly progressService: ProgressService,
    private readonly elRef: ElementRef,
    private readonly userService: UserService,
    protected readonly windowScrollService: WindowScrollService,
    private readonly embedVideoService: EmbedVideoService,
    private readonly location: Location,
    private readonly tezosWalletService: TezosWalletService,
    protected readonly joyRideService: JoyrideService,
    private readonly globalTourService: GlobalTourService,
    private readonly destroyRef: DestroyRef,
    private readonly sharedService: SharedService
  ) {
    super();
    this.globalTourService.tourClosed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.closeCreateCQSidebar.emit();
    });
    this.selectedColor = this.colorList[Math.floor(Math.random() * this.colorList.length) | 0].color;
    this.selectedBrand = this.brandList[Math.floor(Math.random() * this.brandList.length) | 0].slug;

    this.sharedService
      .getStopLoader()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((loader) => {
        this.submitting = loader;
      });
  }

  ngOnInit(): void {
    this.authenticatedUserQuery.authenticatedUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      this.user = user;
    });

    this.windowScrollService.hideFooter();
    this._seoService.createTitleForPage();
    this.cqQuery
      .selectLoading()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((loading) => {
        if (loading) {
          this.progress = this.progressService.showSpinner(this.progress, this.elRef);
        } else {
          this.progressService.hideSpinner(this.progress);
        }
      });

    this.userService
      .getAgeGroups()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.ageGroupList = data;
      });

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.isNew = !params['id'];
          return this.cqService.getCreativeQuery(params['id']);
        }),
        switchMap((cq: CreativeQuery) => combineLatest([this.setupCreativeQuery(cq), this.updateSubmissionFeePrice()])),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.countryList$ = this.proposalRoundService.getRegions().pipe(
      shareReplay(1),
      tap((result) => this.setupSelectedRegionItems(result))
    );

    this.authenticatedUserQuery.unAuthAction
      .pipe(
        switchMap((action: UnAuthAction) => {
          if (action === null && this.authenticatedUserQuery.isRegistrationComplete) {
            return this.submit();
          } else {
            return EMPTY;
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  generateVideoConfig(option: Option): VideoConfig {
    return {
      classes: 'absolute top-0 left-0 w-full h-full',
      poster: option?.thumbnail_url,
      src: option?.videoBlobUrl + '?ngsw-bypass=true',
    };
  }

  calculateTotal(): void {
    if (!this.creativeQuery.blockchain_confirmed) {
      this.totalAmount = this.submitFeeFilm + (this.form.value.bounty ?? 0) + (this.minimumBounty ?? 0);
    } else {
      this.totalAmount = this.submitFeeFilm ?? 0;
    }
    this.totalAmount = Number(this.totalAmount.toFixed(2));
  }

  changeCheckoutType(type: boolean): void {
    this.isExternalCheckout = type;
    this.refreshBountyValidation();
  }

  checkoutFormValidityChange(validity: boolean): void {
    this.checkoutFormValidity = validity;
    this.submitting = false;
  }

  refreshBountyValidation(): void {
    if (this.form) {
      if (!this.isExternalCheckout && this.tabThreeActive) {
        this.form.controls['bounty'].setValidators([Validators.required, Validators.max(this.user?.balance)]);
      } else if (this.isExternalCheckout) {
        this.form.controls['bounty'].setValidators(Validators.required);
      }
      this.form.controls['bounty'].updateValueAndValidity();
    }
  }

  setupCreativeQuery(cq: CreativeQuery): Observable<number> {
    this.creativeQuery = JSON.parse(JSON.stringify(cq));
    this.creativeQueryId = cq.id ? cq.id : null;

    if (this.creativeQueryId !== null) {
      this.setupSelectedDemographyItems(cq);
      this.hasQuestionError = false;
      this.readyToSave = true;
    }
    // Make copy of creative query object from store.
    return this.userService.getSystemSetting('creative_query_minimum_bounty').pipe(
      tap((setting) => {
        this.setCreativeQuerySettings(cq, setting);
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  setCreativeQuerySettings(cq: CreativeQuery, setting: number): void {
    this.minimumBounty = setting || 15;
    this.buildForm(cq);

    if (this.creativeQuery.id && this.creativeQuery.questions?.length > 0) {
      this.isCreativeQueryEdit = true;
      this.selectedColor = this.creativeQuery.cover_color;
      this.selectedBrand = this.creativeQuery.cover;
      this.isQuestionValid = true;
      this.questions = this.creativeQuery.questions;
      for (const question of this.questions) {
        for (const option of question?.options) {
          if (Object.isExtensible(option)) {
            option.isSaved = true;
          }
        }
      }
    } else {
      this.questions = [{ type: null, description: null, creative_query_id: null, index: 0 }];
    }

    this.handleQueryParam();
    this.updateOptions();
    this.calculateTotal();
  }

  updateOptions(): void {
    if (this.isCreativeQueryEdit) {
      this.options = this.questions[0]?.options;
      this.optionCount = this.options?.length - 1;
      for (const option of this.options) {
        if (option?.type === 'video') {
          const videoData = this.embedVideoService.embed(option?.value);
          option.videoData = videoData;
        }
        option.isEdit = false;
      }
    } else {
      this.options = [
        {
          type: 'none',
          value: null,
          isSaved: false,
          question_id: this.questions[0].id,
          index: this.optionCount,
        },
        {
          type: 'none',
          value: null,
          isSaved: false,
          question_id: this.questions[0].id,
          index: ++this.optionCount,
        },
      ];
    }
  }

  handleQueryParam(): void {
    if (this.route.snapshot.queryParamMap.get('step')) {
      this.activeIndex = parseInt(this.route.snapshot.queryParamMap.get('step'), 10);
      this.router.navigate([], { queryParams: {} });
    }
  }

  buildPreview(): void {
    this.questionsPreview = this.questions;
    if (this.joyRideService.isTourInProgress()) {
      this.moveToTab2();
      return;
    }

    this.form.markAllAsTouched();
    if (
      this.isValidStateErrorState() &&
      this.form.valid &&
      this.form.get('cover_color').value &&
      this.form.get('cover').value
    ) {
      this.moveToTab2();
    } else {
      setTimeout(() => {
        this.hasError = true;
        this.scrollToFirstInvalidControl();
      }, 500);
    }
  }

  buildForm(creativeQuery: CreativeQuery): void {
    const genders = this.adaptField(creativeQuery, 'gender');
    const regions = this.adaptField(creativeQuery, 'region');
    const age_groups = this.adaptField(creativeQuery, 'age_group');
    this.form = this.formBuilder.group({
      title: [creativeQuery ? creativeQuery.title : null, [Validators.required, Validators.maxLength(191)]],
      question: [
        creativeQuery?.questions?.length > 0 ? creativeQuery?.questions[0]?.description : null,
        [Validators.required, Validators.minLength(6)],
      ],
      bounty: [
        {
          value: creativeQuery.bounty || 0,
          disabled: !this.isNew && creativeQuery.status !== 'waiting-for-submission',
        },
      ],
      query_age: [
        creativeQuery ? creativeQuery.query_age : null,
        [Validators.required, Validators.min(this.ageMinValue), Validators.max(this.ageMaxValue)],
      ],
      demography: this.formBuilder.group({
        gender: [genders],
        age_group: [age_groups],
        region: [regions],
      }),
      enable_chat: creativeQuery?.enable_chat ?? true,
      cover: [creativeQuery ? creativeQuery.cover : 'default', [Validators.required]],
      cover_color: [creativeQuery ? creativeQuery.cover_color : 'default', [Validators.required]],
    });
    this.form.get('cover_color').setValue(this.selectedColor);
    this.form.get('cover').setValue(this.selectedBrand);
    this.checkoutFormGroup = this.formBuilder.group({
      termsAccepted: [null, Validators.requiredTrue],
    });

    if (!this.isNew && creativeQuery.status !== 'waiting-for-submission') {
      this.isBountyEdit = true;
    } else {
      this.isBountyEdit = false;
    }
    this.onChanges();
  }

  public adaptField(creativeQuery: CreativeQuery, field: string): Demography[] {
    if (creativeQuery && creativeQuery.demography && creativeQuery.demography[field]) {
      return typeof creativeQuery.demography[field][0] === 'string'
        ? creativeQuery.demography[field]
        : creativeQuery.demography[field].map((object) => object[field]);
    }
    return [];
  }

  addQuestion(): void {
    if (this.creativeQuery.questions === undefined) {
      this.creativeQuery.questions = [];
    }
    this.questions.push({
      type: null,
      description: '',
      creative_query_id: this.creativeQuery && this.creativeQuery.id ? this.creativeQuery.id : null,
      index: 0,
    });
  }

  createCreativeQuery(error = null): CreativeQueryResult {
    if (!this.form.valid || !this.questions) {
      return;
    }

    const req = this.createCreateOrUpdateRequest();
    return this.cqService.postPrepareForSubmitCreativeQuery(req).pipe(
      switchMap((cQuery) => {
        this.creativeQuery = cQuery;
        return this.handleCreativeQuerySubmission(cQuery, false);
      }),
      catchError((error) => {
        this.submitting = false;
        if (error.status === 403) {
          return this.createCreativeQuery(error.error);
        } else {
          this.toastService.openToastr('There was a problem submitting your creative query', 'error');
          this.errorService.handleSubmitError(error);
          return throwError(() => error);
        }
      })
    );
  }

  updateCreativeQuery(): CreativeQueryResult {
    if (!this.form.valid) {
      this.submitting = false;
      return;
    }

    const req = this.createCreateOrUpdateRequest();
    // update a cq that is created, and on the blockchain (resubmit)
    if (this.creativeQuery.blockchain_confirmed) {
      return this.cqService.putPrepareForReSubmitCreativeQuery(this.creativeQuery.id, req).pipe(
        switchMap((cQuery) => {
          this.creativeQuery = cQuery;
          return this.handleCreativeQuerySubmission(cQuery, true);
        }),
        catchError((error) => {
          this.resubmitErrorHandler(error);
          return throwError(() => error);
        })
      );
    } else {
      return this.cqService.postPrepareForSubmitCreativeQuery(req).pipe(
        switchMap((cQuery) => {
          this.creativeQuery = cQuery;
          return this.handleCreativeQuerySubmission(cQuery, false);
        }),
        catchError((error) => this.submitErrorHandler(error))
      );
    }
  }
  /*
   * currently not used
   */
  saveCreativeQuery(): void {
    if (this.form.valid) {
      const isSure = confirm('Are you sure you want to save the content of your creative query as draft?');
      if (!isSure) {
        return;
      }
      const merged = this.form.value;
      // Check if question is not null/unedited
      if (this.questions && this.questions[0].description) {
        merged.questions = this.questions;
      }

      if (this.creativeQuery && this.creativeQuery.id) {
        this.cqService
          .putCreativeQuery(this.creativeQuery.id, merged)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((cQuery) => {
            this.readyToSave = false;
            this.router.navigate([`/creative-query/create/${cQuery.id}`]);
            this.toastService.openToastr('Creative query saved ', 'Success', 'check', 3000);
          });
      } else {
        this.cqService
          .postDraftCreativeQuery(merged)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((cQuery) => {
            this.readyToSave = false;
            this.router.navigate([`/creative-query/create/${cQuery.id}`]);
            this.toastService.openToastr('Creative query saved ', 'Success', 'check', 3000);
          });
      }
    }
  }

  public handleCreativeQuerySubmission(cQuery: CreativeQuery, isResubmission: boolean): CreativeQueryResult {
    return this.createSubmitRequest(cQuery, isResubmission).pipe(
      switchMap((req) => {
        const submitOperation = isResubmission
          ? this.cqService.postCreativeQueryResubmit(req)
          : this.cqService.postCreativeQuerySubmit(req);

        return submitOperation.pipe(
          tap((cq) => this.submitSuccessHandler(cq)),
          catchError((error) => this.submitErrorHandler(error))
        );
      })
    );
  }

  public createSubmitRequest(cQuery: CreativeQuery, isResubmission: boolean): Observable<SubmitCreativeQueryRequest> {
    const req = new SubmitCreativeQueryRequest();
    req.creativeQueryId = cQuery.id;
    req.nonce = TezosWalletService.randomNonce;

    const params: [string, number, number, { transactionId: string }, number] = [
      cQuery.blockchain_id,
      isResubmission ? cQuery.resubmission_fee : cQuery.submission_fee,
      isResubmission ? 0 : cQuery.bounty,
      {
        transactionId: isResubmission ? cQuery.resubmission_transaction : cQuery.submission_transaction,
      },
      req.nonce,
    ];

    const submissionFunction = isResubmission
      ? this.tezosWalletService.resubmitCreativeQuerySignature(...params)
      : this.tezosWalletService.submitCreativeQuerySignature(...params);

    return from(submissionFunction).pipe(
      map((signature) => {
        req.signature = signature.sig;
        req.pk = this.tezosWalletService.connectedWalletPublicKey;
        return req;
      }),
      catchError((error) => {
        this.submitting = false;
        return throwError(() => error);
      })
    );
  }

  public createCreateOrUpdateRequest(): void {
    const req = {
      ...this.form.value,
      questions: this.questions,
      is_external_checkout: this.isExternalCheckout,
    };

    req.bounty = (this.form.value.bounty || 0) + this.minimumBounty;

    if (this.creativeQuery.id) {
      req.creative_query_id = this.creativeQuery.id;
    }

    const fee = Number(this.submitFeeFilm.toFixed(8));
    if (this.creativeQuery.blockchain_confirmed) {
      req.resubmission_fee = fee;
    } else {
      req.submission_fee = fee;
    }

    return req;
  }

  public updateSubmissionFeePrice(): Observable<PricingFees | []> {
    return this.paymentStateService.getFees().pipe(
      tap((fees) => {
        if (this.creativeQuery?.blockchain_confirmed) {
          this.submitFeeDollars = fees.creative_query_resubmission_fee_in_dollar;
          this.submitFeeFilm = fees.creative_query_resubmission_fee;
        } else {
          this.submitFeeDollars = fees.creative_query_submission_fee_in_dollar;
          this.submitFeeFilm = fees.creative_query_submission_fee;
        }
        this.calculateTotal();
      }),
      catchError(() => {
        return of<PricingFees | []>([]);
      })
    );
  }

  isValidStateErrorState(): boolean {
    if (this.questions[0].description !== null && this.options?.length > 1) {
      let isSavedAllOption = true;
      for (const option of this.options) {
        if (option?.isSaved === false) {
          isSavedAllOption = false;
        }
      }
      if (isSavedAllOption === false) {
        this.hasOptionError = true;
        this.errorOptionMessage = 'All options should be saved.';
        return false;
      }
    }
    this.hasOptionError = false;
    return true;
  }

  submitAction(): void {
    this.submit().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  submit(): CreativeQueryResult {
    if (environment.readOnlyMode) {
      this.authenticatedUserQuery.openMaintainanceModal();
    } else if (!this.authenticatedUserQuery.isRegistrationComplete) {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create CQ', action: 'verify' });
    } else {
      this.sharedService.setStopLoader(true);

      return this.updateSubmissionFeePrice().pipe(
        switchMap(() => {
          if (!this.submitFeeFilm) {
            this.sharedService.setStopLoader(false);
            throw new Error('There was a problem determining the submission fee');
          } else if (this.creativeQuery.id && this.creativeQuery.blockchain_confirmed) {
            return this.updateCreativeQuery();
          } else {
            return this.createCreativeQuery();
          }
        })
      );
    }
  }

  private submitSuccessHandler(cq: CreativeQuery): void {
    if (cq.status === ProcessStatus.WAITING_FOR_CONFIRMATION) {
      this.toastService.openToastrWithLink(
        'Thank you for your creative query. It must be moderated before becoming visible to other users',
        'Query Submitted',
        'info',
        5000,
        'account/activity',
        'creative-queries',
        'Check Activity'
      );
      this.readyToSave = false;
      this.router.navigate(['/creative-query/' + cq.id]);
      this.closeCreateForm();
    } else if (cq.status === ProcessStatus.WAITING_FOR_PAYMENT) {
      const externalCheckoutRequest = new ExternalCheckoutRequest();
      externalCheckoutRequest.action =
        this.isNew || !cq.blockchain_confirmed
          ? ExternalCheckoutAction.CREATIVE_QUERY_SUBMIT
          : ExternalCheckoutAction.CREATIVE_QUERY_RESUBMIT;
      externalCheckoutRequest.reference_id = cq.id;
      externalCheckoutRequest.payment_id = cq.pending_payment_id;
      this.readyToSave = false;
      this.paymentStateService.externalCheckout$.emit(externalCheckoutRequest);
    }
  }

  private submitErrorHandler(error: Error): Observable<PricingFees | []> {
    this.toastService.openToastr(
      'There was a problem submitting your creative query',
      'Submission Error',
      'error',
      3000
    );
    this.submitting = false;
    this.errorService.handleSubmitError(error);
    return this.updateSubmissionFeePrice();
  }

  private resubmitErrorHandler(error: Error): void {
    this.submitting = false;
    this.toastService.openToastr('There was a problem updating your creative query', 'Update Error', 'error', 3000);
    this.errorService.handleSubmitError(error);
  }

  openLowBalanceSheet(minBuy: number, id: string): void {
    const redirectUri = '/creative-query/create/' + id;
    const data = { redirectUri, minBuy };
    this.toastService.openToastrWithLowBalanceSheet('Low film balance!', 'error', data);
  }

  onChanges(): void {
    merge(this.form.valueChanges, this.checkoutFormGroup.valueChanges).subscribe((value) => {
      if (!this.readyToSave) {
        this.readyToSave = true;
      }
    });
  }

  questionChanged(question): void {
    this.questions[question.index] = question;
    this.readyToSave = true;
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this.elRef?.nativeElement.querySelector('form .ng-invalid');
    if (firstInvalidControl) {
      this.scrollIt(firstInvalidControl);
    }
  }

  private scrollIt(controlEl: HTMLElement): void {
    const labelOffset = 128;
    const scrollElem = this.elRef.nativeElement.querySelector('.cq-scroll-top');
    const top = controlEl.getBoundingClientRect().top + scrollElem.scrollTop - labelOffset;
    scrollElem.scrollTo({ top: top, behavior: 'smooth' });
  }

  canDeactivate(): boolean {
    return !this.readyToSave;
  }

  selectGender(evt): void {
    const result = [];
    if (evt.length) {
      for (const item of evt) {
        result.push(item.slug);
      }
    }
    this.form.controls.demography['controls'].gender.setValue(result);
  }

  selectAge(evt): void {
    const result = [];
    if (evt.length) {
      for (const item of evt) {
        result.push(item.slug);
      }
    }
    this.form.controls.demography['controls'].age_group.setValue(result);
  }

  selectCountry(evt): void {
    const result = [];
    if (evt.length) {
      for (const item of evt) {
        result.push(item.slug);
      }
    }
    this.form.controls.demography['controls'].region.setValue(result);
  }

  selectColor(evt): void {
    let color = 'none';
    if (evt?.slug) {
      color = evt.slug;
    }

    this.selectedColor = color;
    this.form.controls['cover_color'].setValue(color);
  }

  selectBrand(evt): void {
    let brand = 'default';
    if (evt?.slug) {
      brand = evt.slug;
    }
    this.selectedBrand = brand;
    this.form.controls['cover'].setValue(brand);
  }

  activateTab(index): void {
    this.tabOneActive = false;
    this.tabTwoActive = false;
    this.tabThreeActive = false;

    if (index === 1) {
      this.tabOneActive = true;
    } else if (index === 2) {
      this.tabTwoActive = true;
    } else if (index === 3) {
      this.tabThreeActive = true;
    }
  }

  moveToTab1(): void {
    this.questionsPreview = undefined;
    this.tab1?.nativeElement.click();
    this.activateTab(1);
    this.refreshBountyValidation();
  }

  moveToTab2(): void {
    this.tab2?.nativeElement.click();
    this.activateTab(2);
    this.refreshBountyValidation();
  }

  moveToTab3(): void {
    this.tab3?.nativeElement.click();
    this.activateTab(3);
    this.refreshBountyValidation();
  }

  AddQueryAge(val): void {
    let newVal = this.form.get('query_age').value;

    if (isNaN(newVal)) {
      newVal = 0;
    }
    newVal = newVal + val;
    if (newVal < this.ageMinValue) {
      newVal = this.ageMinValue;
    }
    if (newVal > this.ageMaxValue) {
      newVal = this.ageMaxValue;
    }
    this.form.controls['query_age'].patchValue(newVal);
    this.form.controls['query_age'].markAsTouched();
    this.form.controls['query_age'].markAsDirty();
    this.form.controls['query_age'].updateValueAndValidity();
  }

  onChangeQueryAge(): void {
    if (this.form.get('query_age').value <= 1) {
      this.form.get('query_age').setValue(1);
    }
    if (this.form.get('query_age').value > this.ageMaxValue) {
      this.form.get('query_age').setValue(this.ageMaxValue);
    }
  }

  closeCreateForm(): void {
    if (this.isCreativeQueryEdit) {
      this.location.back();
    } else {
      if (!this.canDeactivate()) {
        if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
          this.closeCreateCQSidebar.emit();
        }
      } else {
        this.closeCreateCQSidebar.emit();
      }
    }
  }

  chooseOptionType(type: string, index): void {
    if (!this.isQuestionValid && !this.joyRideService.isTourInProgress()) {
      this.answerClicked = true;
      this.form.get('question').markAsTouched();
      return;
    }
    if (type === 'text') {
      this.options[index].type = type;
    } else {
      this.options[index].type = type;
      this.openModal(type, index);
    }
  }

  openModal(type: string, index): void {
    this.form.controls.question.markAsTouched();
    const question = this.form.value.question;
    if ((question !== '' && question?.length > 5) || this.joyRideService.isTourInProgress()) {
      this.isQuestionValid = true;
    } else {
      this.isQuestionValid = false;
    }
    if (this.isQuestionValid) {
      this.addOptionType = type;
      this.creativeQueryMode = 'add';
      this.optionModalIndex = index;
      this.optionImageUploaded = '';
      this.optionVideoUploaded = '';
      this.isOptionDirectVideoUpload = false;
      this.optionModalTitle = this.optionTitle ? this.optionTitle : '';
      this.optionModalValue = '';
      this.optionModalDescription = '';
      this.isOpenModal = true;
    }
  }

  openEditModal(type: string, option: Option): void {
    this.form.controls.question.markAsTouched();
    const question = this.form.value.question;
    if (question !== '' && question?.length > 5) {
      this.isQuestionValid = true;
    } else {
      this.isQuestionValid = false;
    }
    if (this.isQuestionValid && option) {
      this.addOptionType = type;
      this.creativeQueryMode = 'edit';
      this.optionModalIndex = option?.index;
      this.optionImageUploaded = '';
      this.optionVideoUploaded = '';
      this.isOptionDirectVideoUpload = option?.isDirectVideoUpload;
      this.optionModalTitle = option?.title;
      this.optionModalValue = option?.value;
      this.optionModalDescription = option?.description;
      this.fileUploaded = option?.thumbnail_url;
      this.fileId = option?.file_id;
      if (type === 'image') {
        this.optionImageUploaded = option?.value;
      } else {
        if (this.isOptionDirectVideoUpload) {
          this.optionVideoUploaded = option?.value;
        } else {
          this.optionVideoUploaded = this.embedVideoService.embed(option?.value);
        }
      }
      this.isOpenModal = true;
    }
  }

  cancelModal(): void {
    const option = this.options.find((x) => x?.index === this.optionModalIndex);
    if (this.creativeQueryMode === 'add') {
      option.type = 'none';
    }
    this.clearModal();
  }

  clearModal(): void {
    this.addOptionType = '';
    this.optionModalIndex = -1;
    this.optionTitle = '';
    this.optionImageUploaded = '';
    this.optionVideoUploaded = '';
    this.optionModalTitle = '';
    this.optionModalValue = '';
    this.optionModalDescription = '';
    this.isOpenModal = false;
  }

  updateQuestion(): void {
    if (this.form.value.question?.length < 6) {
      return;
    }
    const dataForUpdate = { ...this.questions[0], type: 'vote', description: this.form.value.question } as Question;
    this.questionChanged(dataForUpdate);
  }

  setOptionTitle(value: string): void {
    this.optionTitle = value;
  }

  setOptionUpdatedTitle(value: string, option: Option): void {
    this.optionTitle = value;
    if (value) {
      option.updatedValue = value;
    } else {
      option.updatedValue = option.value;
    }
  }

  updateOption(option: Option): boolean {
    if (option && option.value) {
      option.isSaved = true;
      option.isEdit = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
      this.isValidStateErrorState();
    }
    this.optionTitle = '';
    return false;
  }

  addOption(): void {
    const data = {
      type: 'none',
      value: null,
      isSaved: false,
      question_id: this.questions[0].id ? this.questions[0].id : null,
      index: ++this.optionCount,
    };
    this.options.push(data);
  }

  saveOption(data: any): void {
    if (data) {
      const option = this.options.find((x) => x?.index === this.optionModalIndex);
      option.isSaved = data.isSaved;
      option.isEdit = data.isEdit;
      option.type = data.type;
      option.value = data.value;
      option.title = data.title;
      option.file_id = data.file_id;
      option.description = data.description;
      option.videoData = data.videoData;
      option.isDirectVideoUpload = data.isDirectVideoUpload;
      option.videoBlob = data.videoBlob;
      if (data.videoBlob) {
        option.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.videoBlob));
      }
      option.thumbnail_url = data.thumbnailUrl;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
      this.isValidStateErrorState();
      this.clearModal();
    }
  }

  onAddQuestion(value: string): void {
    this.answerClicked = false;
    if (value && value.length > 5) {
      this.isQuestionValid = true;
    } else {
      this.isQuestionValid = false;
    }
  }

  editOption(option: Option): void {
    if (option) {
      option.isEdit = true;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
  }

  resetOption(option: Option): void {
    if (option) {
      option.type = 'none';
      option.value = null;
      option.isSaved = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
  }

  deleteOption(index): void {
    this.options = this.options.filter((o) => o?.index !== index);
    for (let i = index; i < this.options.length; ++i) {
      this.options[i].index = this.options[i].index - 1;
    }
    --this.optionCount;

    const options = this.options;
    const dataForUpdate = { ...this.questions[0], options } as Question;
    this.questionChanged(dataForUpdate);
    this.isValidStateErrorState();
  }

  closeOption(option: Option): void {
    if (option) {
      option.isEdit = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
  }

  saveUpdatedOption(option: Option): void {
    if (option) {
      if (option?.updatedValue) {
        option.value = option.updatedValue;
      }
      option.isEdit = false;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
  }

  showSaveButton(event, option: Option): void {
    this.optionTitle = event?.target.value;
    if (option && this.optionTitle) {
      option.value = this.optionTitle;
      const options = this.questions[0].options ? this.questions[0].options : [];
      options[option?.index] = option;
      const dataForUpdate = { ...this.questions[0], options } as Question;
      this.questionChanged(dataForUpdate);
    }
  }

  setupSelectedDemographyItems(cq: CreativeQuery): void {
    if (cq.demography?.age_group) {
      cq.demography.age_group.forEach((age) => {
        const selectedAge = this.ageGroupList.find((elem) => elem.slug === age.age_group);
        if (selectedAge !== null) {
          this.selectedAge.push(selectedAge);
        }
      });
    }

    if (cq.demography?.gender) {
      cq.demography.gender.forEach((gender) => {
        const selectedGender = this.genderList.find((elem) => elem.slug === gender.gender);
        if (selectedGender !== null) {
          this.selectedGender.push(selectedGender);
        }
      });
    }
  }

  setupSelectedRegionItems(result: Region[]): void {
    if (this.creativeQuery.demography?.region) {
      this.creativeQuery.demography.region.forEach((region) => {
        const selectedRegion = result.find((elem) => elem.slug === region.region);
        if (selectedRegion !== null) {
          if (!this.selectedRegion.find((elem) => elem === selectedRegion)) {
            this.selectedRegion.push(selectedRegion);
          }
        }
      });
    }
  }

  addBounty(bounty): void {
    let newBounty = this.form.get('bounty').value + bounty;
    if (newBounty <= 0) {
      newBounty = 0;
    }
    this.form.get('bounty').setValue(newBounty);
    this.calculateTotal();
  }

  handleBounty(event): void {
    if (event.action === 'type') {
      this.form.get('bounty').setValue(Number(event.value));
      this.calculateTotal();
    } else {
      this.addBounty(event.action === 'add' ? 1 : -1);
    }
  }
}
