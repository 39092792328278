import { AfterViewInit, Component, DestroyRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoConfig } from '../../../../shared/interface/video-config.interface';
import { DistributionService } from '../../../../shared/services/distribution.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Distribution } from '../../../../../app/app.datatypes';
import { VideoPlayerModule } from '../../../../shared/modules/components/video-player/video-player.module';
import { LocalLoaderModule } from '../../../../shared/modules/components/local-loader/local-loader.module';
import { firstValueFrom, timer } from 'rxjs';
import { VideoPlayerService } from '../../../../shared/services/video-player.service';
import { IconModule } from '../../../../shared/modules/components/icon/icon.module';

@Component({
  selector: 'app-distribution-player',
  standalone: true,
  imports: [CommonModule, VideoPlayerModule, LocalLoaderModule, IconModule],
  templateUrl: './distribution-player.component.html',
  host: {
    class: 'w-full block',
  },
})
export class DistributionPlayerComponent implements AfterViewInit {
  @Input() distribution: Distribution;
  @Input() autoPlay: boolean;
  @Input() expanded: boolean;
  videoConfig: VideoConfig;
  streamLoading: boolean;
  constructor(
    private distributionService: DistributionService,
    private destroyRef: DestroyRef,
    videoPlayerService: VideoPlayerService
  ) {
    videoPlayerService.resolutionChange.pipe(takeUntilDestroyed(destroyRef)).subscribe((res) => {
      this.videoConfig = null;
      this.startStreaming(res);
    });
  }

  ngAfterViewInit(): void {
    if (this.autoPlay || this.expanded) {
      this.startStreaming();
    }
  }

  startStreaming(res?: number): void {
    if (this.streamLoading) {
      return;
    }
    this.streamLoading = true;
    this.distributionService
      .getVideoStream(this.distribution.id, res)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((url: string) => {
        firstValueFrom(timer(2500)).then(() => {
          this.streamLoading = false;
          this.videoConfig = {
            poster: this.distribution.cover_url,
            src: url,
            classes: 'w-full min-w-full max-w-full',
            showText: 'Your browser does not support the video tag.',
            resolutions: this.distribution.video_data.resolutions,
            selectedRes: res,
            autoplay: true,
            play_duration: this.distribution.purchase?.play_duration ?? 0,
            id: this.distribution.id,
            handleResolutionChangeFromParent: true,
          };
        });
      });
  }
}
