import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() notification: any; // any is ok here
  @Output() setRead = new EventEmitter<string>();
  public action: string;

  ngOnInit() {
    // Special message for proposal round created
    const entity = this.notification.template_variables?.entity;
    const action = this.notification.template_variables?.action;

    if (entity === 'proposal round') {
      this.notification.template_variables.actor = null;
      if (action === 'is created') {
        this.action = action + ' and starts on ' + this.notification.template_variables.start_date;
      }
      if (action === 'is started') {
        this.action = 'is available for participation';
      }
    } else {
      this.action = action;
    }
  }

  getEntityUrl(type, id) {
    let url = '/';
    switch (type) {
      case 'proposal':
      case 'proposal draft': {
        url = '/project/' + id;
        break;
      }
      case 'creative query':
        url = '/creative-query/' + id;
        break;
      case 'review':
        url = '/review/' + id;
        break;
      case 'proposal round':
        url = '/award/' + id;
        break;
    }

    return url;
  }

  readNotification(notification) {
    if (notification.read_time == null) {
      this.setRead.emit(notification.id);
    }
  }
}
