<section class="flex flex-col relative">
  <ng-container *ngIf="distribution">
    <div class="px-24 h-64 min-h-64 max-h-64 border-b border-gray-border" stepPosition="center">
      <h3 class="font-semibold mt-16 flex items-center">
        {{ distribution?.blockchain_confirmed ? 'Resubmit' : 'Submit' }}
        Project to DCP+
        <span
          class="pill pill-success mt-2 ml-16"
          appTooltip="You can find your drafts in My Projects page"
          placement="bottom"
          *ngIf="isSavedDraft && !route.snapshot.params['id']"
        >
          <span class="hidden sm:inline">Saved as a draft</span>
          <app-icon [icon]="'save'" class="sm:ml-8" style="margin-bottom: 0 !important"></app-icon>
        </span>
      </h3>
      <button *ngIf="createMode" class="absolute top-16 right-16 opacity-75 hover:opacity-100">
        <app-icon [icon]="'close'" class="w-16 h-16" (click)="closeCreateSidebar.emit()"></app-icon>
      </button>
      <span class="proposal-status absolute top-16 right-40" *ngIf="isSavedDraft">
        <app-status [status]="distribution.status"></app-status>
      </span>
    </div>
    <app-tabs class="flex-grow overflow-hidden tabs-create-panel step-tab flex flex-col">
      <app-tab-item>
        <app-tab-label>
          <app-icon [icon]="'info'"></app-icon>
          <label>About your Project</label>
          <p class="absolute top-0 left-0 w-full h-full" id="tab1" #tab1></p>
        </app-tab-label>
        <app-tab-body>
          <app-distribution-create-step-one
            (setData)="setStepOneData($event)"
            [user]="user"
            [distribution]="distribution"
          ></app-distribution-create-step-one>
          <div class="flex justify-end py-16 px-24 border-t border-gray-border">
            <button
              class="btn btn-primary mr-16"
              type="button"
              (click)="saveDraft()"
              *ngIf="distribution?.title?.length && distribution?.status !== 'approved'"
            >
              <app-icon [icon]="'save'" class="w-12 h-12 ml-8"></app-icon>
              Save as Draft
            </button>
            <button
              class="btn btn-primary"
              [disabled]="!distribution.isStepOneValid"
              (click)="saveDraft(); moveToTab('tab2')"
            >
              Next
            </button>
          </div>
        </app-tab-body>
      </app-tab-item>
      <app-tab-item>
        <app-tab-label>
          <app-icon [icon]="'play'"></app-icon>
          <label>Review your Submission</label>
          <p class="absolute top-0 left-0 w-full h-full" id="tab2" #tab2></p>
          <p
            class="absolute top-0 left-0 w-full h-full cursor-not-allowed"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            *ngIf="!distribution.isStepOneValid"
          ></p>
        </app-tab-label>
        <app-tab-body *ngIf="distribution.isStepOneValid">
          <app-distribution-create-step-two [distribution]="distribution"></app-distribution-create-step-two>
          <div class="flex justify-between py-16 border-t border-gray-border px-24">
            <button class="btn btn-primary" (click)="moveToTab('tab1')">Previous</button>
            <button class="btn btn-primary" (click)="moveToTab('tab3')">Next</button>
          </div>
        </app-tab-body>
      </app-tab-item>
      <app-tab-item>
        <app-tab-label>
          <app-icon [icon]="'bag'"></app-icon>
          <label>Checkout</label>
          <p class="absolute top-0 left-0 w-full h-full" id="tab3" #tab3></p>
          <p
            class="absolute top-0 left-0 w-full h-full cursor-not-allowed"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            *ngIf="!distribution.isStepOneValid"
          ></p>
        </app-tab-label>
        <app-tab-body>
          <app-distribution-create-step-three
            [isLoading]="distributionQuery.selectLoading() | async"
            *ngIf="submitFeeFilm"
            [distribution]="distribution"
            [user]="user"
            (setData)="setStepThreeData($event)"
            [submissionFee]="submitFeeFilm"
            [moderationFee]="moderationFee"
          ></app-distribution-create-step-three>
          <div class="flex justify-between py-16 border-t border-gray-border px-24">
            <button class="btn btn-primary" (click)="moveToTab('tab2')">Previous</button>
            <button class="btn btn-primary" (click)="submit()" [disabled]="!distribution.isStepTwoValid">
              Submit for moderation
            </button>
          </div>
        </app-tab-body>
      </app-tab-item>
    </app-tabs>
  </ng-container>
  <div
    *ngIf="distributionQuery.selectLoading() | async"
    class="bg-backdrop flex justify-center items-center absolute h-full w-full top-0 left-0"
  >
    <app-local-loader class="w-40 h-40"></app-local-loader>
  </div>
</section>
