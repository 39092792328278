import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HorizontalSliderProperties, ProcessStatus, Proposal, ProposalRound } from '../../../../app.datatypes';
import { ApiService } from '../../api.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProposalStore } from '../../../state';
import { HorizontalSliderService } from '../horizontal-slider.service';
@Injectable({
  providedIn: 'root',
})
export class ProposalByRoundService {
  constructor(
    private readonly apiService: ApiService,
    private readonly proposalStore: ProposalStore,
    private readonly horizontalSliderService: HorizontalSliderService
  ) {}
  roundsList$ = new BehaviorSubject<HorizontalSliderProperties[]>([]);
  lastPage = false;
  isLastActiveRound = false;
  getProposalRounds(scrolled = false): Observable<HorizontalSliderProperties[]> {
    this.proposalStore.setLoading(!scrolled);
    const limit = this.horizontalSliderService.limit;
    const currentRounds = this.roundsList$.getValue();
    if (!currentRounds.length || scrolled) {
      const api = `api/proposal-rounds/by-round-group?limit=${limit.parent}&skip=${currentRounds.length}`;
      return (this.isLastActiveRound ? of([]) : this.apiService.get(api)).pipe(
        switchMap((res) => {
          if (res.length < limit.parent) {
            this.isLastActiveRound = true;
            return this.apiService.get(`${api}&finished`).pipe(map((finished) => [...res, ...finished]));
          } else return of(res);
        }),
        tap((res) => {
          this.lastPage = res.length < limit.parent;
          res.forEach((round: ProposalRound) => {
            currentRounds.push({
              title: round.title,
              id: round.id,
              count: round.proposal_count,
              end_date: round.end_date,
              start_date: round.start_date,
              status: round.status,
              minimum_participants_to_go: round.minimum_participants_to_go,
            });
          });
          this.roundsList$.next(currentRounds);
          this.proposalStore.setLoading(false);
        })
      );
    } else {
      return this.roundsList$.asObservable();
    }
  }

  getProposalsByRoundId(entity: HorizontalSliderProperties, datalimit?: number): Observable<Proposal[]> {
    const limit = this.horizontalSliderService.limit;
    if (!datalimit) {
      datalimit = limit.child;
    }
    const skip = entity.skip ?? 0;
    let url = `api/proposals?proposal_rounds=${entity.id}&limit=${datalimit}&skip=${skip}${
      entity.status === ProcessStatus.FINISHED ? '&history' : ''
    }`;
    return this.apiService.get(url).pipe(
      tap((proposals: Proposal[]) => {
        const pagination = this.horizontalSliderService.handlePaginationProperties(
          proposals.length + skip,
          entity.page ?? 1,
          entity.leftPositionSlider ?? 0
        );
        entity.skip = skip + proposals.length;
        Object.assign(entity, pagination);
        const updatedValue = this.roundsList$.getValue().map((item) => (item.id === entity.id ? entity : item));
        this.roundsList$.next(updatedValue);
        this.proposalStore.add(proposals);
        this.proposalStore.setLoading(false);
      })
    );
  }

  resetAll(): void {
    this.lastPage = false;
    this.roundsList$.next([]);
  }
}
