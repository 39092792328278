import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AuthenticatedUser, AuthenticatedUserQuery, PaymentStateService } from '../../../../../shared';
import { PricingFees, Proposal, ProposalRound, ProposalRoundData } from '../../../../../app.datatypes';
import { JoyrideService } from 'ngx-joyride';
import { environment } from '../../../../../../environments';
import { GlobalTourService } from '../../../../../shared/services/global-tour-service.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { VideoPlayerService } from '../../../../../shared/services/video-player.service';
import { VideoConfig } from '../../../../../shared/interface/video-config.interface';

@Component({
  selector: 'app-proposal-create-step-one',
  templateUrl: './proposal-create-step-one.component.html',
  styleUrls: ['./proposal-create-step-one.component.scss'],
})
export class ProposalCreateStepOneComponent implements OnChanges {
  @Input() user: AuthenticatedUser;
  @Input() proposal: Proposal;
  @Input() roundList: ProposalRound[] = [];
  @Input() isNew: boolean;
  @Input() prices: PricingFees;
  @Input() selectedRoundId?: string;
  @Input() showSelectedRound: boolean;
  @Output() closeSidebar = new EventEmitter<string>();
  @Output() setStepOne = new EventEmitter<ProposalRoundData>();

  selectedRound: ProposalRound;
  textColor: SafeStyle;

  constructor(
    private paymentService: PaymentStateService,
    public readonly joyrideService: JoyrideService,
    private globalTour: GlobalTourService,
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public sanitizer: DomSanitizer,
    private videoPlayerService: VideoPlayerService
  ) {}

  get textColorStyle(): string {
    this.textColor = this.sanitizer.bypassSecurityTrustStyle('var(--global-black-color)');
    return this.textColor.toString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.roundList?.currentValue?.length > 0) {
      if (this.isNew) {
        if (this.selectedRoundId) {
          this.selectRound(this.selectedRoundId);
        } else {
          this.roundList?.forEach((round) => (round.showRoundDetails = true));
        }
      } else if (!this.isNew && this.proposal) {
        this.checkBalance(this.proposal.proposal_round_id);
      }
    }
  }

  calculateFilmToDollar(fee: number): number {
    return this.paymentService.calculateFilmToDollarPricing(fee);
  }

  checkBalance(roundId = ''): void {
    this.selectedRoundId = roundId;
    this.selectedRound = this.roundList.find((r) => r.id === this.selectedRoundId);
    this.setStepOneData(roundId);
    setTimeout(() => {
      this.selectedRound.showRoundDetails = true;
    });
  }

  generateVideoConfig(round: ProposalRound): VideoConfig {
    return {
      src: round.cover_url,
      classes: 'rounded',
      videoControlOptions: 'nofullscreen noremoteplayback',
      poster: round.cover_thumbnail_url,
    };
  }

  selectRound(id): void {
    if (this.joyrideService.isTourInProgress()) {
      const data: ProposalRoundData = {} as ProposalRoundData;
      data.selectedRound = environment.proposalDemo.proposal_round as any;
      this.setStepOne.emit(data);
      this.globalTour.tourAction.emit('next1');
      return;
    }
    this.checkBalance(id);
  }

  showRoundDetails(round): void {
    round.shownDetails = !!round.showRoundDetails;
    round.showRoundDetails = !round.showRoundDetails;
  }

  setStepOneData(roundId: string): void {
    const data: ProposalRoundData = { proposal_round_id: roundId, isValid: true, selectedRound: this.selectedRound };
    if (!data.selectedRound?.proposal_description_fields) {
      data.selectedRound.proposal_description_fields = environment.defaultProposalDetails.proposal_description_fields;
    }
    if (!data.selectedRound?.proposal_header_fields) {
      data.selectedRound.proposal_header_fields = environment.defaultProposalDetails.proposal_header_fields;
    }
    this.setStepOne.emit(data);
  }

  calculateFilmFee(fee: number): number {
    return this.paymentService.calculateDollarToFilmPricing(fee);
  }

  playVideo(): void {
    const videoElement = this.videoPlayerService.videos();
    videoElement.forEach((videoEl: ElementRef) => {
      const videoPlayer: HTMLVideoElement = videoEl.nativeElement;
      if (videoPlayer.currentTime > 0) {
        videoPlayer.pause();
        videoPlayer.load();
      }
    });
  }
}
