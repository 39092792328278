<section class="flex flex-col relative">
  <ng-container *ngIf="proposal">
    <div
      class="px-24 h-64 min-h-64 max-h-64 border-b border-gray-border"
      joyrideStep="proposalcreatefinish"
      [title]="joyrideService?.isTourInProgress() ? 'Create Project' : ''"
      text="Congratulations, you have just submitted your project. It will become active on the platform once it has been approved by a member of the moderation team. Good-luck!"
      (done)="closeCreateForm()"
      stepPosition="center"
    >
      <h3 class="font-semibold mt-16 flex items-center">
        {{ proposal?.blockchain_confirmed ? 'Resubmit' : 'Submit' }}
        Project
        <span
          class="pill pill-success mt-2 ml-16"
          appTooltip="You can find your drafts in My Projects page"
          placement="bottom"
          *ngIf="isSavedDraft"
        >
          <span class="hidden sm:inline">Saved as a draft</span>
          <app-icon [icon]="'save'" class="sm:ml-8" style="margin-bottom: 0 !important"></app-icon>
        </span>
      </h3>
      <button *ngIf="createMode" class="absolute top-16 right-16 opacity-75 hover:opacity-100">
        <app-icon [icon]="'close'" class="w-16 h-16" (click)="closeCreateForm()"></app-icon>
      </button>
      <span class="proposal-status absolute top-16 right-16" *ngIf="!createMode">
        <app-status [status]="proposal.status"></app-status>
      </span>
    </div>

    <app-tabs
      class="flex-grow overflow-hidden tabs-create-panel flex flex-col"
      [ngClass]="{ selectedRound: !tabOneActive }"
    >
      <app-tab-item [cursorClass]="'pointer'" *ngIf="!this.proposal?.blockchain_confirmed">
        <app-tab-label>
          <app-icon [icon]="'round'"></app-icon>
          <label>Choose your Award</label>
          <p
            class="absolute top-0 left-0 w-full h-full"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            *ngIf="!stepOneData || !isStepOneReady"
          ></p>
          <p class="absolute top-0 left-0 w-full h-full" id="tab1" (click)="activateTab(1)" #tab1></p>
        </app-tab-label>
        <app-tab-body class="flex-grow" *ngIf="isStepOneReady">
          <ng-container *ngTemplateOutlet="resubmissionAlert"></ng-container>
          <app-proposal-create-step-one
            [user]="user"
            [proposal]="proposal"
            [roundList]="roundList"
            [isNew]="isNew"
            [prices]="prices"
            [selectedRoundId]="selectedRoundId"
            (setStepOne)="setStepOne($event)"
            (closeSidebar)="closeForm()"
          ></app-proposal-create-step-one>
        </app-tab-body>
      </app-tab-item>

      <app-tab-item [cursorClass]="stepOneData?.isValid && isStepOneReady ? 'pointer' : 'disabled'">
        <app-tab-label>
          <app-icon [icon]="'info'"></app-icon>
          <label>About your Project</label>
          <p class="absolute top-0 left-0 w-full h-full" id="tab2" (click)="activateTab(2)" #tab2></p>
          <p
            class="absolute top-0 left-0 w-full h-full"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            *ngIf="!stepOneData?.isValid || !isStepOneReady"
          ></p>
        </app-tab-label>
        <app-tab-body *ngIf="isStepTwoReady || joyrideService?.isTourInProgress()">
          <div class="step-two h-full overflow-auto">
            <ng-container *ngTemplateOutlet="selectedRoundElement"></ng-container>
            <ng-container *ngTemplateOutlet="resubmissionAlert"></ng-container>
            <app-proposal-create-step-two
              [proposal]="proposal"
              [genreList]="genreList"
              [languageList]="languageList"
              [selectedRound]="stepOneData?.selectedRound"
              (setStepTwo)="setStepTwo($event)"
            ></app-proposal-create-step-two>
          </div>
        </app-tab-body>
      </app-tab-item>
      <app-tab-item [cursorClass]="stepOneData?.isValid && isStepTwoReady ? 'pointer' : 'disabled'">
        <app-tab-label>
          <app-icon [icon]="'proposal'"></app-icon>
          <label>Review your Submission</label>
          <p class="absolute top-0 left-0 w-full h-full" id="tab4" (click)="activateTab(3)" #tab4></p>
          <p
            class="absolute top-0 left-0 w-full h-full"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            *ngIf="!stepOneData?.isValid || !isStepTwoReady || !isStepFourReady"
          ></p>
        </app-tab-label>
        <app-tab-body *ngIf="isStepFourReady || joyrideService?.isTourInProgress()">
          <div class="h-full overflow-auto">
            <ng-container *ngTemplateOutlet="resubmissionAlert"></ng-container>
            <app-proposal-create-step-three
              [proposal]="proposal"
              [proposalReview]="proposalReview"
              [selectedRound]="selectedRound"
              [completed]="completed"
              [days]="days"
              [prices]="prices"
              [stepTwoData]="stepTwoData"
            ></app-proposal-create-step-three>
          </div>
        </app-tab-body>
      </app-tab-item>

      <app-tab-item [cursorClass]="stepOneData?.isValid && isStepTwoReady && isStepFourReady ? 'pointer' : 'disabled'">
        <app-tab-label>
          <app-icon [icon]="'bag'"></app-icon>
          <label>Checkout</label>
          <p class="absolute top-0 left-0 w-full h-full" id="tab5" (click)="activateTab(4)" #tab5></p>
          <p
            class="absolute top-0 left-0 w-full h-full"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            *ngIf="!stepOneData?.isValid || !isStepTwoReady || !isStepFourReady"
          ></p>
        </app-tab-label>
        <app-tab-body>
          <ng-container *ngTemplateOutlet="resubmissionAlert"></ng-container>
          <app-proposal-create-step-four
            [proposal]="proposal"
            [selectedRound]="selectedRound"
            [prices]="prices"
            [isLoading]="isLoading"
            [user]="user"
            [minBountyToBeAdded]="minBountyToBeAdded"
            [isBalanceZero]="isBalanceZero"
            (setStepFive)="setStepFive($event)"
          ></app-proposal-create-step-four>
        </app-tab-body>
      </app-tab-item>
    </app-tabs>
    <div
      class="h-72 {{
        tabTwoActive && stepTwoData?.title?.length ? 'mobile' : ''
      }} px-24 py-16 border-t border-gray-border"
    >
      <ng-container
        *ngTemplateOutlet="saveDraftButton; context: { $implicit: 'md:hidden flex ml-auto mb-16' }"
      ></ng-container>
      <div id="tab-1" *ngIf="tabOneActive && !this.proposal?.blockchain_confirmed" class="flex justify-end">
        <button
          class="btn btn-primary"
          [disabled]="(!stepOneData?.isValid || !isStepTwoReady) && !joyrideService?.isTourInProgress()"
          (click)="moveToTab2()"
        >
          Next
        </button>
      </div>
      <div id="tab-2" *ngIf="tabTwoActive && !tabFourActive" class="flex justify-between items-center">
        <button class="btn btn-secondary" (click)="moveToTab1()" *ngIf="!this.proposal?.blockchain_confirmed">
          Prev
        </button>
        <div class="relative flex items-center ml-auto">
          <ng-container
            *ngTemplateOutlet="saveDraftButton; context: { $implicit: 'hidden md:flex mr-16' }"
          ></ng-container>
          <button class="btn btn-primary ml-auto" (click)="validateStepTwoForm()">Next</button>
        </div>
      </div>

      <div id="tab-4" *ngIf="tabThreeActive" class="flex items-center justify-between h-full py-16">
        <button class="btn btn-secondary" (click)="moveToTab2()">Prev</button>
        <div class="relative">
          <button
            class="btn btn-primary ml-auto"
            joyrideStep="proposalcreatenext3"
            [title]="joyrideService?.isTourInProgress() ? 'Create Project' : ''"
            text="Click next to submit project"
            (next)="moveToTab4()"
            stepPosition="left"
            (click)="moveToTab4()"
          >
            Next
          </button>
        </div>
      </div>

      <div id="tab-5" *ngIf="tabFourActive" class="flex justify-between items-center h-full">
        <button class="btn btn-secondary" (click)="moveToTab3()">Prev</button>
        <div
          class="relative"
          joyrideStep="proposalcreatenext4"
          [title]="joyrideService?.isTourInProgress() ? 'Create Project' : ''"
          text="Submit for Moderation."
        >
          <button
            class="btn btn-primary ml-auto"
            [disabled]="
              (!stepOneData ||
                !stepTwoData ||
                !stepFiveData?.isValid ||
                progress?.overlayRef.hasAttached() ||
                submitting) &&
              !joyrideService?.isTourInProgress()
            "
            (click)="submit()"
          >
            Submit for Moderation
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="isLoading"
    class="absolute w-full h-full top-0 left-0 flex justify-center items-center backdrop backdrop-blur"
  >
    <app-local-loader class="h-48"></app-local-loader>
  </div>
</section>
<ng-template #saveDraftButton let-class>
  <button
    class="btn btn-primary {{ class }}"
    type="button"
    (click)="saveDraft()"
    *ngIf="stepTwoData?.title?.length && tabTwoActive"
  >
    <app-icon [icon]="'save'" class="w-12 h-12 ml-8"></app-icon>
    Save as Draft
  </button>
</ng-template>
<ng-template #selectedRoundElement>
  <div class="round-details-bar" *ngIf="tabTwoActive && stepOneData?.selectedRound">
    <app-proposal-create-step-one
      [user]="user"
      [proposal]="proposal"
      [roundList]="roundList"
      [isNew]="true"
      [prices]="prices"
      [selectedRoundId]="stepOneData?.selectedRound?.id"
      [showSelectedRound]="true"
    ></app-proposal-create-step-one>
  </div>
</ng-template>
<ng-template #resubmissionAlert>
  <div
    class="info-box warning mb-16"
    *ngIf="proposal?.status === proposalStatus.WAITING_FOR_SUBMISSION && proposal?.blockchain_confirmed"
  >
    <app-icon icon="warning"></app-icon>
    <p>
      <b>Important:</b> Users cannot evaluate/review your project until resubmission has been completed. Please complete
      required edits and proceed to Checkout to resubmit your project for moderation.
    </p>
  </div>
</ng-template>
