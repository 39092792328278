import { Component, Input } from '@angular/core';
import { ProposalActionPreview, DocsItem } from '../../../app.datatypes';
import { FilePreviewOverlayService } from '../..';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent {
  @Input() proposalDocs: DocsItem[];
  expandedUrl: string;
  constructor(private previewDialog: FilePreviewOverlayService) {}
  showPreview(item, type): void {
    const file: ProposalActionPreview = { url: item.url, type };
    if (item.title) {
      file.name = 'item.title';
    } else {
      file.name = 'PDF doc';
    }
    this.previewDialog.open({
      image: file,
    });
  }

  expand(proposalDoc: DocsItem): void {
    this.expandedUrl = this.expandedUrl ? undefined : proposalDoc.url;
  }
}
