import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { ApiService } from './api.service';
import {
  User,
  Moderation,
  Proposal,
  Country,
  UserNotification,
  Ethnicity,
  Badges,
  SystemSetting,
  UsernameSuggestion,
  UserEngagementResponse,
  PotentialUserEarnings,
  NFT,
  ReferralData,
  State,
  SimpleList,
} from '../../app.datatypes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments';
import { AuthenticatedUser } from '../state';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = environment.url;
  public countryList: BehaviorSubject<Country[]> = new BehaviorSubject([]);
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getUserProfile(userId): Observable<AuthenticatedUser> {
    const apiUrl = 'api/public-profile/' + userId;
    return this.apiService.get(apiUrl);
  }

  getAgeGroups(): Observable<SimpleList[]> {
    return this.apiService.get('api/age-groups').pipe(
      map((res) => {
        const ageGroupList: SimpleList[] = [];
        res.forEach((value) => {
          if (value !== 'N/A') {
            ageGroupList.push({ slug: value, name: value });
          }
        });
        return ageGroupList;
      })
    );
  }

  getUserModerations(userid, skip = 0, search = ''): Observable<Moderation[]> {
    let apiUrl = 'api/user-moderations/';
    if (userid) {
      apiUrl += userid;
    }
    apiUrl += '?skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  getUserProposals(userid, field = '', genres = 'all', search = '', skip = 0, state = null): Observable<Proposal[]> {
    let apiUrl = 'api/user-proposals/';
    if (userid) {
      apiUrl += userid + '/';
    }
    apiUrl +=
      '?field=' +
      field +
      '&direction=desc' +
      (genres ? '&genres=' + genres : '') +
      '&skip=' +
      skip +
      (search ? '&search=' + encodeURIComponent(search) : '') +
      (state ? '&state=' + state : '');
    return this.apiService.get(apiUrl);
  }

  getSystemSettings(): Observable<SystemSetting[]> {
    return this.apiService.get('api/system-settings');
  }

  // Response is {data: ${value}} e.g. {data: false} / {data: 0.4} etc
  getSystemSetting(slug: string): Observable<any> {
    return this.apiService.get('api/system-settings/' + slug);
  }

  getPublicSystemSettings(): Observable<SystemSetting[]> {
    return this.apiService.get('api/system-settings/public');
  }

  putSystemSettings(settings) {
    return this.apiService.put('api/system-settings', settings);
  }

  // Response status 200 when available and 422 when it's not
  checkUserName(name): any {
    return this.apiService.post('api/username-check', name);
  }

  suggestUserName(name): Observable<UsernameSuggestion> {
    return this.apiService.post(`api/username-suggest`, name);
  }

  getCountries(): Observable<Country[]> {
    return this.apiService.get('api/countries').pipe(
      tap((data: Country[]) => {
        this.countryList.next(data);
      })
    );
  }

  getStates(countryCode): Observable<State[]> {
    return this.apiService.get(`api/provinces?c=${countryCode}`);
  }

  getEthnicity(): Observable<Ethnicity[]> {
    return this.apiService.get('api/ethnicity');
  }

  public getLanguages() {
    return this.apiService.get('api/languages');
  }

  postReadUserNotification(notificationId): Observable<UserNotification> {
    return this.apiService.post('api/users/notification/' + notificationId + '/read', '');
  }

  getUserEngagement(): Observable<UserEngagementResponse> {
    return this.apiService.get('api/users/engagement');
  }

  getUserPotentialEarnings(): Observable<PotentialUserEarnings> {
    return this.apiService.get('api/users/potential-earnings');
  }

  getUser(id: string): Observable<User> {
    return this.apiService.get('api/users/' + id);
  }

  getUserTokenEarnings(id: string, skip = 0, limit = 10): Observable<number[]> {
    return this.apiService.get('api/users/' + id + '/token-earnings?skip=' + skip + '&limit=' + limit);
  }

  getUserNotifications(skip = 0, limit = 10): Observable<UserNotification[]> {
    return this.apiService.get('api/users/notification?skip=' + skip + '&limit=' + limit);
  }

  readAllUserNotification(): Observable<UserNotification[]> {
    return this.apiService.post('api/users/notification/read-all', '');
  }

  getBadges(): Observable<Badges[]> {
    return this.apiService.get('api/badges');
  }

  attachBadge(badgeId: string, userId: string): Observable<Badges> {
    return this.apiService.put(`api/badges/attach/${badgeId}/user/${userId}`, {});
  }

  detachBadge(badgeId: string, userId: string): Observable<Badges> {
    return this.apiService.put(`api/badges/detach/${badgeId}/user/${userId}`, {});
  }

  toggleBadge(badgeId: string, userId: string): Observable<Badges> {
    return this.apiService.put(`api/badges/toggle-visibility/${badgeId}/user/${userId}`, {});
  }

  getNftList(skip: number): Observable<NFT[]> {
    const api = `api/user-nft-list?limit=${environment.nft_skip_limit}&skip=${skip}&field=newest`;
    return this.apiService.get(api);
  }

  getReferralData(): Observable<ReferralData> {
    return this.apiService.get('api/referral-token/get');
  }
  shareByEmail(email: string) {
    return this.apiService.post('api/referral/invite-user', { email });
  }

  getCountryName(countryIso: string): string {
    return this.countryList.value?.find((country) => country.iso_2 === countryIso).name;
  }

  replaceGenderIndicator(indicator: string): string {
    switch (indicator) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case 'O':
        return 'Other';
      default:
        return 'Unknown';
    }
  }
}
