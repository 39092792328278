<app-toggle-button [type]="'moderationsOverview'" *ngIf="showSearch"></app-toggle-button>

<div class="pb-16 md:pb-24" *ngIf="authenticatedUserQuery.isAdministrator || authenticatedUserQuery.isModerator">
  <ng-container *ngIf="showSearch">
    <div *ngIf="!isMobileScreen" class="sticky top-0 border-b border-gray-border mb-24 bg-body z-50">
      <form [formGroup]="filterForm">
        <div class="filters align-items-center px-0" *ngIf="filterForm">
          <div class="ml-auto">
            <div class="form-group lg:w-1/5 ml-auto addon-f">
              <label></label>
              <input formControlName="search" placeholder="Search"/>
              <app-icon [icon]="'search'"></app-icon>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-container>

  <p class="text-sm" [ngStyle]="{ 'color': authenticatedUserQuery.user.is_dark_theme ? 'white' : 'black' }">
    Moderation History
  </p>

  <ng-container *ngIf="dataSourceTable$ | async as dataSourceTable">
    <div class="table-newlook z-0 relative"
         *ngIf="dataSourceTable.length"
         [ngClass]="{'table-mobile-view': mobileViewForAllScreens}"
    >
      <ul class="thead">
        <li>Type</li>
        <li>Reference</li>
        <li>Status</li>
        <li>Reason</li>
        <li>Comments</li>
        <li>Moderator</li>
        <li>created</li>
      </ul>
      <ul
        class="tbody clickable"
        [ngClass]="{ 'invisible-background': isAddTransparent }"
        *ngFor="let element of dataSourceTable; trackBy: trackById"
        [routerLink]="[
        element.slim_proposal ? '/proposal' : element.slim_review ? '/review' : '/creative-query',
        element.slim_proposal
          ? element.proposal_id
          : element.slim_review
          ? element.slim_review.id
          : element.creative_query_id
      ]"
      >
        <li>
          <label>Type</label>
          {{
          element.type | dashReplace | titlecase | shorten: 25:'...'
          }}
        </li>

        <li>
          <label>Reference</label>
          {{
          (element.slim_proposal
              ? element.slim_proposal.title
              : element.slim_review
                ? element.slim_review.slim_proposal.title
                : element.creative_query
                  ? element.creative_query.title
                  : '-'
          ) | shorten: 25:'...'
          }}
        </li>

        <li>
          <label>Status</label>
          {{ element.status | statusText }}
        </li>

        <li>
          <label>Moderator</label>
          <p class="truncate" title="{{ element.user.name }}">{{ element.user.name }}</p>
        </li>

        <li *ngIf="activatedRoute.snapshot.routeConfig.path === moderationPath || !(entity.status === proposalStatus.APPROVED)" [ngClass]="{'no-data-element': !element.comments}">
          <label>Comments</label>
          <p *ngIf="element.comments; else showNothing" appTooltip="{{ element.comments }}">
            {{ element.comments | shorten: 25:'...' }}
          </p>

          <ng-template #showNothing>
            <p>
              {{ noData }}
            </p>
          </ng-template>
        </li>

        <li *ngIf="activatedRoute.snapshot.routeConfig.path === moderationPath || !(entity.status === proposalStatus.APPROVED)" [ngClass]="{'no-data-element': !element.reason}">
          <label>Reason</label>
          <div *ngIf="element.reason && element.reason.length > 0; else showNothing" class="w-100 p-0">
            <p class="" *ngFor="let reason of element.reason" appTooltip="{{ reason.description }}">
              {{ reason.description | shorten: 20:'...' }}
            </p>
          </div>
          <ng-template #showNothing>
            <p>
              {{ noData }}
            </p>
          </ng-template>
        </li>

        <li>
          <label>Created</label>
          {{ element.created_at | date: 'medium' }}
        </li>
      </ul>
    </div>
  </ng-container>

  <button *ngIf="moderationsService.hasNextPage" class="btn btn-secondary w-full mt-16" (click)="loadMore()">
    Load More
  </button>
</div>
