<div class="w-full pb-8 mb-8 border-b border-borderdark" *ngIf="user && user.id && userDetails">
  <app-user-avatar class="w-64 mx-auto" [avatar_url]="user.avatar_url"> </app-user-avatar>
  <h4 class="text-lg text-center my-16">{{ user.name }}</h4>
  <ul class="w-full flex justify-between mb-16">
    <li class="w-52 flex flex-col items-center" (click)="showInfoBoxFn()">
      <app-icon [icon]="'bell'" class="w-24 h-24 opacity-50 mb-8 white"></app-icon>
      <p class="text-center text-xs">{{ user.notifications_count }}</p>
    </li>
    <li class="w-52 flex flex-col items-center">
      <app-icon [icon]="'wallet'" class="w-24 h-24 opacity-50 mb-8 white"></app-icon>
      <p class="text-center text-xs">
        {{ user.balance ? (user.balance | filmPrice) : (0 | filmPrice) }}
      </p>
    </li>
    <li class="w-52 flex flex-col items-center">
      <app-icon [icon]="'lock'" class="w-24 h-24 opacity-50 mb-8 white"></app-icon>
      <p class="text-center text-xs">{{ user.locked | filmPrice }}</p>
    </li>
    <li class="w-52 flex flex-col items-center" routerLink="/leader-board">
      <app-icon [icon]="'vote'" class="w-24 h-24 opacity-50 mb-8 white"></app-icon>
      <p class="text-center text-xs">{{ reputation | filmPrice }}</p>
    </li>
  </ul>

</div>
<app-potential-earnings [data]="info" class="mb-8"> </app-potential-earnings>
<div class="flex min-w-full max-w-full overflow-y-auto overflow-x-hidden">
  <div class="min-w-full max-w-full transition-all duration-300" [ngClass]="{ '-ml-full': themePanelOpen }">
    <a
      routerLink="/account/user"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"

    >
      <app-icon [icon]="'user'"></app-icon>
      Account
    </a>
    <a
      routerLink="/user/projects"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"
      *ngIf="showProposal"
    >
      <app-icon [icon]="'proposal'"></app-icon>
      My Projects
    </a>
    <a
      routerLink="/user/dcp-plus"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"
      *ngIf="showDistribution"
    >
      <app-icon [icon]="'play'"></app-icon>
      My DCP+
    </a>
    <a
      routerLink="/creative-query/user"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"
      *ngIf="showCq"
    >
      <app-icon [icon]="'bulb'"></app-icon>
      My Creative Queries
    </a>
    <a
      routerLink="/cart/order-history"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"
      *ngIf="showSwag"
    >
      <app-icon [icon]="'wallet'"></app-icon>
      Order History
    </a>
    <a
      routerLink="/account/wallet"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"

    >
      <app-icon [icon]="'wallet'"></app-icon>
      Wallet
    </a>
    <a
      routerLink="/account/settings"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"

    >
      <app-icon [icon]="'gear'"></app-icon>
      Settings
    </a>
    <a
      routerLink="/leader-board"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeUserMenu()"
      class="menu-button"
      *ngIf="showRep"
    >
      <app-icon [icon]="'prize'"></app-icon>
      Leaderboard
    </a>
    <a (click)="closeUserMenu()" class="menu-button" [href]="jiraServiceDeskUrl" target="_blank">
      <app-icon [icon]="'help'"></app-icon>
      Help
    </a>
    <button
      (click)="$event.preventDefault(); $event.stopPropagation(); toggleThemePanel()"
      class="relative w-full text-left menu-button"
    >
      <app-icon [icon]="'appearance'"></app-icon>
      Appearance
      <app-icon [icon]="'arrow-right'" class="ml-auto next"></app-icon>
    </button>
    <button (click)="onLogout()" class="menu-button">
      <app-icon [icon]="'exit'"></app-icon>
      Disconnect
    </button>
    <app-referral-box *ngIf="isReferralProgram" class="block mt-16" [boxMode]="true"  [revert]="true"></app-referral-box>
  </div>

  <div  class="min-w-full max-w-full relative">
    <button
      class="absolute top-4 left-0 -ml-8 w-32 h-32 rounded-full hover:opacity-75 no-hover"
      (click)="$event.preventDefault(); $event.stopPropagation(); toggleThemePanel()"

    >
      <app-icon [icon]="'arrow-left'" class="icon-white w-24 h-24"></app-icon>
    </button>
    <h3 class="text-lg pl-40 mb-24">Appearance</h3>
    <button
      class="theme"
      (click)="$event.preventDefault(); $event.stopPropagation(); toggleTheme()"
      [ngClass]="{ selected: user.is_dark_theme }"
    >
      <app-icon [icon]="'dark'"></app-icon>
      Dark Mode
    </button>
    <button
      class="theme"
      (click)="$event.preventDefault(); $event.stopPropagation(); toggleTheme()"
      [ngClass]="{ selected: !user.is_dark_theme }"
    >
      <app-icon [icon]="'sun'"></app-icon>
      Light Mode
    </button>
    <button
      class="theme grayscale mt-16"
      [ngClass]="{ selected: user.is_grayscale }"
      (click)="$event.preventDefault(); $event.stopPropagation(); toggleScale()"
    >
      <app-icon [icon]="'grayscale'"></app-icon>
      Images Gray Scale
    </button>
  </div>
</div>
