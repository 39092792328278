import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticatedUserQuery } from '../../shared';
import { environment } from 'environments';

export const BetaTesterGuard: CanActivateFn = () => {
  const router = inject(Router);
  if (!inject(AuthenticatedUserQuery).user?.is_beta_tester || !environment.beta_tester_allowed) {
    router.navigateByUrl('');
  }
  return true;
};
