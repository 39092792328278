import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {SwagProduct, SwagShippingRequest, SwagShippings} from "../../../app.datatypes";
import {Observable, of} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {ApiService} from "../../services";
import { SwagProductQuery } from './swag-products-query.component';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'swag-product',
  idKey: 'id',
  resettable: true,
})
export class SwagProductStore extends EntityStore<SwagProductState> {
  constructor() {
    super({ loading: false });
  }
}

export interface SwagProductState extends EntityState<SwagProduct> {
  skip: number;
  apiEndReached: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SwagProductStateService {
  constructor(private swagStore: SwagProductStore, private api: ApiService, private swagQuery: SwagProductQuery) {}

  getSwagProduct(id: string) {
    const query = this.swagQuery.selectEntity(id);
    return query.pipe(
      switchMap((proposal) => {
        if (!proposal) {
          return this.api.get(`api/swag/products/${id}`).pipe(tap((product) => this.swagStore.add(product)));
        } else {
          return of(proposal);
        }
      }),
      catchError((error) => {
        return this.api.catchError(error);
      })
    );
  }

  getAllSwagProducts(): Observable<SwagProduct[]> {
    this.swagStore.setLoading(true);
    return this.api.get(`api/swag/products`).pipe(
      tap((swagProducts) => {
        this.swagStore.add(swagProducts);
        this.swagStore.setLoading(false);
        this.swagStore.setHasCache(true);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getShippingCharges(request: SwagShippingRequest): Observable<SwagShippings[]> {
    this.swagStore.setLoading(true);
    return this.api
      .post(`api/swag/shipping`, request)
      .pipe(
        tap(() => {
          this.swagStore.setLoading(false);
        }),
        catchError(this.handleError.bind(this))
      );
  }

  handleError(error) {
    this.swagStore.setError(error);
    this.swagStore.setLoading(false);
    return this.api.catchError(error);
  }
}


