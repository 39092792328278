import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from './api.service';
import { ReportReason, ReportRequest, Report } from '../interface/report';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private readonly apiService: ApiService) {}

  getReports(skip: number, direction: string): Observable<Report[]> {
    return this.apiService
      .get(`api/reports?skip=${skip}&direction=${direction}`)
      .pipe(catchError((error) => this.apiService.catchError(error)));
  }

  getReportReason(): Observable<ReportReason[]> {
    return this.apiService.get('api/reports/reasons');
  }

  postReport(data: ReportRequest): Observable<Report> {
    return this.apiService.post('api/reports', data);
  }
}
