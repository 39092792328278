import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PaymentStateService } from '../../../..';

@Component({
  selector: 'app-bounty-info',
  templateUrl: './bounty-info.component.html',
  styleUrls: ['./bounty-info.component.scss'],
})
export class BountyInfoComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() submissionFee: number = 0;
  @Input() moderationFee: number = 0;
  @Input() resubmissionFee: number = 0;
  @Input() minimumBounty: number = 0;
  @Input() additional: number = 0;
  @Input() canAdd = true;
  @Input() showMinBounty = true;
  @Input() isDistributionPurchase: boolean;
  @Input() isExternalCheckout = false;

  @Output() recalculateTotal: EventEmitter<number> = new EventEmitter<number>();
  @Output() recalculateBountyValue: EventEmitter<{ action: string; value?: number }> = new EventEmitter();
  @Output() testEvent = new EventEmitter();
  total: number;
  isVisible = true;

  constructor(protected readonly paymentService: PaymentStateService) {}

  ngOnInit(): void {
    this.total = this.getTotal();
  }

  ngOnChanges(): void {
    this.total = this.getTotal();
    this.recalculateTotal.emit(this.total);
  }

  toggle(): void {
    this.isVisible = !this.isVisible;
  }

  getTotal(): number {
    let total = 0;
    total = (this.minimumBounty || 0) + (this.additional || 0);
    if (this.submissionFee) {
      total = this.submissionFee + total;
    }
    if (this.resubmissionFee) {
      total = this.resubmissionFee + total;
    }
    if (this.moderationFee) {
      total = total + this.moderationFee;
    }
    return Number(total.toFixed(6));
  }
}
