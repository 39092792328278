import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ProposalRound } from '../../../app.datatypes';

export interface ProposalByRoundState extends EntityState<ProposalRound,string> {
  skip: number;
  apiEndReached: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'proposal-by-round', idKey: 'id', resettable: true })
export class ProposalByRoundStore extends EntityStore<ProposalByRoundState> {
  constructor() {
    super({
      skip: 0,
      apiEndReached: false
    });
  }
}
