import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { ProposalStore } from './proposal.store';
import { ProposalQuery } from './proposal.query';
import {
  Proposal,
  ProposalRequest,
  SubmitProposalRequest,
  Genre,
  Language,
  ProposalRound,
  MpaRating,
  ProcessStatus,
  Filters,
} from '../../../app.datatypes';
import { environment } from '../../../../environments/environment';
import { ApiService, SharedService } from '../../services';
import { AuthenticatedUserService } from '../authenticated-user';
import { ProposalByRoundQuery, ProposalByRoundStore } from '../proposal-by-round';
import { DistributionStore } from '../distribution';
import { HorizontalSliderService } from '../../services/new/horizontal-slider.service';
import { ProposalByRoundService } from 'app/shared/services/new/proposal-by-round/proposal-by-round.service';

@Injectable({ providedIn: 'root' })
export class ProposalStateService {
  proposalsRequired = environment.grid_skip_limit;
  constructor(
    private proposalStore: ProposalStore,
    private proposalQuery: ProposalQuery,
    private apiService: ApiService,
    private authenticatedUserService: AuthenticatedUserService,
    private sharedService: SharedService,
    private proposalByRoundStore: ProposalByRoundStore,
    private proposalByRoundQuery: ProposalByRoundQuery,
    private readonly distributionStore: DistributionStore,
    private readonly horizontalSLiderService: HorizontalSliderService,
    private readonly proposalByRound: ProposalByRoundService
  ) {}

  handleError(error: any): Observable<never> {
    this.proposalStore?.setError(error);
    this.proposalStore?.setLoading(false);
    return this.apiService?.catchError(error);
  }

  getLanguages(): Observable<Language[]> {
    return this.apiService.get('api/languages').pipe(
      tap((languages: Language[]) => {
        const topOfTheList = ['es', 'fr'];
        languages.forEach((elem, index) => {
          if (topOfTheList.includes(elem.iso)) {
            languages.unshift(languages.splice(index, 1)[0]);
          }
        });
        languages.unshift(
          languages.splice(
            languages.findIndex((item) => item.iso === 'en'),
            1
          )[0]
        );
      })
    );
  }

  getGenres(): Observable<Genre[]> {
    return this.apiService.get('api/movie-genres');
  }

  getMpaRatings(): Observable<MpaRating[]> {
    return this.apiService.get('api/mpa-ratings');
  }

  getAll(): Observable<Proposal[]> {
    let hasCache = this.proposalQuery.getHasCache();
    const filterValue = this.proposalQuery.getValue();
    const filters = filterValue?.filters;
    const skip = filterValue?.skip ?? 0;

    if (!hasCache && !filterValue.error) {
      this.proposalStore.setLoading(true);
      this.proposalStore.update({ loaded: false });
      return this.apiService.get(this.horizontalSLiderService.setFilters('api/proposals', filters, skip)).pipe(
        switchMap((proposals) => {
          if (proposals.length < this.proposalsRequired) {
            this.proposalStore.update({ apiEndReached: true });
            return this.apiService
              .get(this.horizontalSLiderService.setFilters('api/proposals', filters) + '&history')
              .pipe(map((proposalHistory) => [...proposals, ...proposalHistory]));
          } else {
            return of(proposals);
          }
        }),
        tap((proposals) => {
          applyTransaction(() => {
            this.proposalStore.add(proposals);
            this.proposalStore.setHasCache(true);
            this.proposalStore.update({ skip: filterValue.skip + proposals.length, loaded: true });
            this.proposalStore.setLoading(false);
          });
        }),
        catchError(this.handleError.bind(this))
      );
    } else {
      this.sharedService.setStopLoader(true);
      return of(this.proposalQuery.getAll());
    }
  }

  getProposal(id): Observable<Proposal> {
    let query = this.proposalQuery.selectEntity(id);
    return query.pipe(
      switchMap((proposal) => {
        if (!proposal?.complete_data_fetched) {
          return this.apiService.get(`api/proposals/${id}`).pipe(
            tap((proposal) => {
              proposal.complete_data_fetched = true;
              this.proposalStore.upsert(proposal.id, proposal);
            })
          );
        } else {
          return of(proposal);
        }
      }),
      catchError((error) => {
        return this.apiService.catchError(error);
      })
    );
  }

  updateDraftProposal(id: string): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.get(`api/proposals/${id}`));
  }

  resetFailed(id): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.put('api/proposals/' + id + '/reset', null));
  }

  // Active state
  setActive(id: string, isDistribution?: boolean): void {
    if (isDistribution) {
      this.distributionStore.setActive(id);
    } else {
      this.proposalStore.setActive(id);
    }
  }

  setUserProposalActive(id: string): void {
    this.proposalStore.update({ userProposalActive: id });
  }

  setOnChangeStatus(id, status, blockchain_confirmed, blockchain_id): void {
    this.proposalStore.update(id, { status, blockchain_id, blockchain_confirmed });
  }

  setOnOwnReviewStatusChange(id, own_review_status): void {
    this.proposalStore.update(id, { own_review_status });
  }

  setLockedData(id, locked_by, locked_at): void {
    this.proposalStore.update(id, { locked_by, locked_at });
  }

  updateScores(id: string, propsoal: Partial<Proposal>): void {
    this.proposalStore.update(id, propsoal);
  }

  updateScroll(scroll: number): void {
    this.proposalStore.update({ scroll });
  }

  // Skips
  updateSkip(scroll: number): void {
    this.proposalStore.update({ scroll });
    this.proposalStore.setHasCache(false);
    this.distributionStore.setHasCache(false);
  }

  updateUserProposalSkip(userProposalScroll): void {
    this.proposalStore.update({
      userProposalSkip: this.proposalQuery.getValue().userProposalSkip + this.proposalsRequired,
      userProposalScroll,
    });
  }

  updateFilters(filters): void {
    this.proposalStore.remove(this.proposalQuery.getAllProposalIds());
    this.proposalByRound.resetAll();
    this.proposalStore.setHasCache(false);
    this.proposalStore.update({
      filters,
      skip: 0,
      scroll: 1,
      loaded: true,
      apiEndReached: false,
    });
  }

  removeAllDistributions(): void {
    applyTransaction(() => {
      this.distributionStore.reset();
    });
  }

  removeAllProposals(): void {
    applyTransaction(() => {
      this.proposalStore.reset();
      this.proposalByRoundStore.reset();
    });
  }

  updateShowFilters(showFilters: boolean): void {
    this.proposalStore.update({ ui: { showFilters } });
  }

  updateProposalReview(id: string, reviewResponse: any): void {
    this.proposalStore.update(id, (entity) => ({
      votes_score: reviewResponse.totalVotes,
      reviews_score: reviewResponse.reviews_score,
      evaluations_score: reviewResponse.evaluations_score,
      likes_score: reviewResponse.likes_score,
      statistics: { ...entity.statistics, total_votes: reviewResponse.totalVotes },
    }));
  }

  putExtendProposal(id: string, request: { extension_fee: number }): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.put('api/proposals/' + id + '/extend', request));
  }

  submitExtendProposal(req: any): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(
      this.apiService.post('api/proposals/' + req.proposalId + '/submit-extend', {
        sig: req.signature,
        nonce: req.nonce,
        pk: req.pk,
      })
    );
  }

  cancelProposal(proposal: Proposal): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this.apiService.get(`api/proposals/${proposal.id}/cancel`).pipe(
      tap(() => {
        applyTransaction(() => {
          if (proposal.status === ProcessStatus.APPROVED) {
            const skip = this.proposalQuery.getValue().skip - 1;

            if (this.proposalQuery.getActive() && this.proposalQuery.getActive().id === proposal.id) {
              this.proposalStore.setActive(null);
            }
            this.proposalStore.update({ skip, userProposalApiEndReached: false });
          } else if (proposal.status === ProcessStatus.TO_BE_MODERATED) {
            const moderationProposalSkip = this.proposalQuery.getValue().moderationProposalSkip - 1;

            this.proposalStore.update({
              moderationProposalSkip,
              userProposalApiEndReached: false,
            });
          }
          this.proposalStore.update(proposal.id, { status: 'cancelled' });
          this.proposalStore.setLoading(false);
        });
      }),
      catchError((error) => {
        this.proposalStore.setLoading(false);
        this.proposalStore.setError(error);
        return this.apiService.catchError(error);
      })
    );
  }

  removeProposal(proposal: Proposal) {
    this.proposalStore.setLoading(true);
    return this.apiService.delete(`api/proposals/${proposal.id}/remove`).pipe(
      tap(() => {
        this.proposalStore.update({
          userProposalActive: null,
        });
        this.proposalStore.remove(proposal.id);
        this.proposalStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  prepareProposalForSubmit(request: ProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.post(`api/proposals/prepare-for-submit`, request));
  }

  unlockProposal(id: string): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this.apiService.put('api/proposals/' + id + '/unlock', null).pipe(
      tap((proposal) => {
        this.authenticatedUserService.setUnlocked();

        proposal.locked_at = undefined;
        proposal.locked_by = undefined;

        this.proposalStore.upsert(proposal.id, proposal);
        this.proposalStore.setLoading(false);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  prepareProposalForResubmit(id: string, request: ProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.put(`api/proposals/${id}/prepare-for-resubmit`, request));
  }

  submitProposal(req: SubmitProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(
      this.apiService.post(`api/proposals/${req.proposalId}/submit`, {
        sig: req.signature,
        nonce: req.nonce,
        pk: req.pk,
      })
    );
  }

  resubmitProposal(req: SubmitProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(
      this.apiService.post(`api/proposals/${req.proposalId}/resubmit`, {
        sig: req.signature,
        nonce: req.nonce,
        pk: req.pk,
      })
    );
  }

  saveProposalAsDraft(request: ProposalRequest): Observable<Proposal> {
    this.proposalStore.setLoading(true);
    return this._upsertProposalState(this.apiService.post(`api/proposals/`, request));
  }

  updateProposalDraft(id: string, request: ProposalRequest): Observable<Proposal> {
    return this._upsertProposalState(this.apiService.put(`api/proposals/${id}`, request));
  }

  updateFullProposalState(id: string, proposal): void {
    this.proposalStore.update(id, proposal);
  }

  private _upsertProposalState(observerable: Observable<Proposal>) {
    return observerable.pipe(
      tap((proposal) => {
        this.proposalStore.upsert(proposal.id, proposal);
        this.proposalStore.setLoading(false);
      }),
      catchError((error) => {
        this.proposalStore?.setError(error);
        this.proposalStore?.setLoading(false);
        return throwError(() => error);
      })
    );
  }

  setEditable(id: string): Observable<Proposal> {
    return this.apiService.put(`api/proposals/${id}/set-editable`, null);
  }

  addProposal(proposal: Proposal): void {
    const entity = this.proposalByRoundQuery.getEntity(proposal.proposal_round_id);
    if (entity) {
      this.proposalByRoundStore.update(proposal.proposal_round_id, {
        proposal_count: entity.proposal_count + 1,
      });
      this.proposalStore.add(proposal);
    }
  }

  updateProposalByRoundStore(id: string, fields: Partial<ProposalRound>) {
    this.proposalByRoundStore.update(id, fields);
  }
}
