import { Component, ViewChild, ElementRef, OnInit, AfterViewChecked, Renderer2, DestroyRef } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import {
  ThemeService,
  AuthenticatedUserQuery,
  AuthenticatedUserService,
  RoutingState,
  UserService,
  SharedService,
  AuthenticatedUser,
  TezosWalletService,
  ToasterService,
  PaymentStateService,
  ProposalService,
  UnAuthAction,
} from './shared';
import { SocketService } from './shared/services/socket.service';
import { environment } from '../environments';
import { notificationsIn, slideInRight, announceMsg, dropdown, panelIn, slideInLeft } from './app.animations';
import { NavigationStart, Router } from '@angular/router';
import { filter, firstValueFrom, map, Subscription, timer } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { DcpModalService } from './shared/components/dcp-modal';
import { UserEngagementData } from './app.datatypes';
import { TopbarComponent } from './components/layout/topbar/topbar.component';
import { DialogService } from './shared/dialog/dialog.service';
import { AuthService } from './shared/services/auth.service';
import { PromptSubmissionDialogComponent } from './shared/modules/dialog/prompt-submission-dialog/prompt-submission-dialog.component';
import { DistributionService } from './shared/services/distribution.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Platform } from '@angular/cdk/platform';
import { IosPwaComponent } from './shared/modules/dialog/ios-pwa/ios-pwa.component';
import { AnalyticsService } from './shared/services/google-analytic.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [notificationsIn, slideInRight, announceMsg, dropdown, panelIn, slideInLeft],
})
export class AppComponent implements OnInit, AfterViewChecked {
  user?: AuthenticatedUser;
  loginModalRef: string;
  sticky = false;
  shrinkToolbar = false;
  progress: string;
  newMessages = 0;
  timesLoop = 0;
  requestReloadKey = '_dcp_force_reload';
  hidden = true;
  showInfoBox = false;
  isCreatingProposal = false;
  isCreatingCQ = false;
  loaded = false;
  os = 'scroll';
  updateSwSubscription: Subscription;
  lastPing: Date;
  idleState: string;
  timedOut = false;
  themePanelOpen = false;
  private engagementTimer: Subscription;
  private isUserEngagementStarted = false;
  refreshEngagementRetries = 0;
  refreshEarningsRetries = 0;
  hasRefreshTimeout = false;
  timeoutSeconds: number;
  selectedProposalRound: string;
  @ViewChild('usernav', { static: true }) unav: ElementRef;
  @ViewChild('main', { static: true }) mainDiv: ElementRef;
  @ViewChild('root', { static: true }) rootElement: ElementRef;
  @ViewChild('app_wrapper', { static: false }) wrapper: ElementRef;
  @ViewChild(TopbarComponent, { static: false }) topBarComponentData: TopbarComponent;
  route = '';
  unAuthAction: UnAuthAction;
  isCreatingDistribution = false;
  constructor(
    public authenticatedUserQuery: AuthenticatedUserQuery,
    public authenticatedUserService: AuthenticatedUserService,
    private authService: AuthService,
    public themeService: ThemeService,
    private swUpdate: SwUpdate,
    private routingState: RoutingState,
    private socketService: SocketService,
    private router: Router,
    private readonly googleAnalyticService: AnalyticsService,
    private idle: Idle,
    private userService: UserService,
    private modalService: DcpModalService,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private dialogService: DialogService,
    private tezosWalletService: TezosWalletService,
    private paymentService: PaymentStateService,
    private toastService: ToasterService,
    private proposalService: ProposalService,
    private distributionService: DistributionService,
    private readonly destroyRef: DestroyRef,
    private readonly platform: Platform
  ) {
    if (!this.authenticatedUserQuery.user) {
      environment.referral_program = false;
    }

    this.routingState.loadRouting();
    this.initIdleTimer();
    this.sharedService
      .getOnRedirectActivity()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isCreatingProposal = false;
        this.isCreatingCQ = false;
      });
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.route = event.url;
        this.googleAnalyticService.trackEvent(this.route);
      }
    });
    this.userService.getCountries().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.userService
      .getPublicSystemSettings()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        data.forEach((el) => {
          if (el.name === 'BLOCKCHAIN_DISABLED') {
            environment.readOnlyMode = el.value;
          }
        });
      });

    if (
      navigator.userAgent.indexOf('Win') !== -1 ||
      navigator.userAgent.indexOf('X11') !== -1 ||
      navigator.userAgent.indexOf('Linux') !== -1
    ) {
      this.os = 'customScroll';
    }
    this.renderer.addClass(document.body, `${this.os}`);
  }

  resetIdleTimer(): void {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stopIdleTimer(): void {
    this.idle.stop();
  }

  initIdleTimer(): void {
    this.idle.setIdle(environment.idle_timeout_seconds);
    this.idle.setTimeout(environment.idle_warning_seconds);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.idleState = 'No longer idle.';
      this.modalService.close('inactivity-modal-1');
    });
    this.idle.onTimeout.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.userIdleTimeOut();
    });
    this.idle.onIdleStart.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.idleState = "You've gone idle!";
      this.modalService.open('inactivity-modal-1');
    });
    this.idle.onTimeoutWarning.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.timeoutSeconds = countdown;
    });
  }

  /**
   * When a user idles out, we show a warning modal, and if they timeout, the modal forces them to refresh.
   * Call userIdleTimeout() when they timeout completely.
   */
  startIdleTimer(): void {
    // Start the timer if we have a user
    if (!this.idle.isRunning() && this.user?.id) {
      this.resetIdleTimer();
    }
  }

  /**
   * Called when the user idles out completely due to inactivity
   * Disconnects from websockets, stops service worker upgrade checks, stops user engagement monitoring.
   */
  userIdleTimeOut(): void {
    if (this.updateSwSubscription) {
      this.updateSwSubscription.unsubscribe();
    }
    if (this.socketService) {
      this.socketService.disconnect();
    }
    if (this.engagementTimer) {
      this.stopUserEngagementRefresh();
    }
  }

  ngOnInit(): void {
    this.authenticatedUserQuery.authenticatedUserNoFilter$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (user) => {
        this.sharedService.setUpdateUser();
        this.user = user;

        if (this.user && (!user.sex || !user.ethnicity || !user.age_group || !user.languages || !user.country)) {
          environment.personalDetailsPending = {
            ethnicity: !user.ethnicity,
            sex: !user.sex,
            age_group: !user.age_group,
            country: !user.country,
            languages: !user.languages,
          };
        } else {
          environment.personalDetailsPending = null;
        }
        this.paymentService.updateFees();
        if (user?.id) {
          if (!user.name?.length) {
            this.authenticatedUserQuery.unAuthAction.next({ action: 'register', slug: null });
          }
          environment.referral_program = true;

          this.toggleTheme();
          this.toggleScale();
          this.startUserEngagementRefresh();
          this.startIdleTimer();

          this.tezosWalletService.setWalletType(user.wallet_type ?? 'kukai');
          await this.tezosWalletService.validateWallet();
        } else {
          this.renderer.addClass(document.body, 'dcp-light-theme');
          environment.referral_program = false;
        }
      });
    this.authenticatedUserQuery.unAuthAction.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((action) => {
      this.unAuthAction = action;
    });
    this.authService.startRefreshJWTTimer();
    const token = this.authenticatedUserQuery.tokens?.access_token;
    if (environment.echo_enabled === true && token) {
      this.socketService.connect(token).then((socket) => {
        socket.joinMandatoryChannels();
      });
    }

    this.showIosPrompt();

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map((evt) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          }))
        )
        .subscribe(() => {
          this.showSwUpdateDialog();
        });
    }
    // Setup listener of storage (For handling reload commands from/to other tabs eg. on version upgrade)
    localStorage.removeItem(this.requestReloadKey);
    window.addEventListener('storage', this.onStorageChange.bind(this));
    firstValueFrom(timer(1000)).then(() => {
      this.hidden = false;
      // Delay calling initial user engagement refresh by 1 second.
      // Otherwise user auth state is wrong even if logged in
      this.startUserEngagementRefresh();
    });

    this.distributionService.createDistributionSubject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isCreatingDistribution = true;
    });

    this.proposalService.startCreateProposalSubject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((round) => {
      this.selectedProposalRound = round;
      this.createProposalFN();
    });
  }

  showIosPrompt(): void {
    const localStorageKey = 'iosPWA';

    // check if the device is in standalone mode
    const isInStandaloneMode = () => {
      return 'standalone' in (window as any).navigator && (window as any).navigator.standalone;
    };
    // show the modal only once
    const localStorageKeyValue = localStorage.getItem(localStorageKey);
    const iosInstallModalShown = localStorageKeyValue ? JSON.parse(localStorageKeyValue) : false;
    const shouldShowModalResponse = this.platform.IOS && !isInStandaloneMode() && !iosInstallModalShown;

    if (shouldShowModalResponse) {
      localStorage.setItem(localStorageKey, 'true');
      this.dialogService.open(IosPwaComponent);
    }
  }

  handleFullScreen(): boolean {
    return this.route !== '/signup' && this.route.includes('signup');
  }

  showSwUpdateDialog(): void {
    this.toastService.openReloadToastr(
      'Platform Update Available',
      'The Decentralized Pictures platform has been updated. To continue and use the most up to date version, please reload now.'
    );
  }

  toggleTheme(logout = false): void {
    if (this.user.is_dark_theme && !logout) {
      this.renderer.addClass(document.body, 'dark');
      this.renderer.removeClass(document.body, 'dcp-light-theme');
    } else {
      this.renderer.removeClass(document.body, 'dark');
      this.renderer.addClass(document.body, 'dcp-light-theme');
    }
    this.themeService.currentTheme$.next(this.user.is_dark_theme);
  }

  toggleScale(): void {
    if (this.user.is_grayscale) {
      this.renderer.addClass(document.body, 'gray-scale');
    } else {
      this.renderer.removeClass(document.body, 'gray-scale');
    }
  }

  logout(): void {
    this.authService.logout();
    this.stopIdleTimer();
    this.stopUserEngagementRefresh();
    this.toggleTheme(true);
  }

  onStorageChange(): void {
    if (localStorage.getItem(this.requestReloadKey)) {
      localStorage.removeItem(this.requestReloadKey);
      window.location.reload();
    }
  }

  showInboxBarFn(): void {
    this.showInfoBox = !this.showInfoBox;
  }

  createProposalFN(): void {
    this.isCreatingProposal = true;
  }

  closeCreateProposalSidebar(): void {
    this.isCreatingProposal = false;
  }

  createCreativeQueryFN(): void {
    this.isCreatingCQ = true;
  }

  closeCreativeQuerySidebar() {
    this.isCreatingCQ = false;
  }

  ngAfterViewChecked(): void {
    const wrapper = this.wrapper?.nativeElement;
    if (wrapper) {
      if (window.innerWidth > 991 && window.innerWidth < 1280) {
        this.renderer.addClass(wrapper, 'mini');
      } else {
        this.renderer.removeClass(wrapper, 'mini');
      }
      if (window.innerWidth > 991) {
        this.renderer.removeClass(wrapper, 'user-side-open');
      }
    }
  }

  initMenu(): void {
    this.themePanelOpen = false;
  }

  toggleThemePanel(): void {
    this.themePanelOpen = !this.themePanelOpen;
  }

  startUserEngagementRefresh(): void {
    const updateTime = null;
    const userEngagement: UserEngagementData = {
      likes: 0,
      likesRight: 0,
      review: 0,
      reviewRight: 0,
      creativeQueryRight: 0,
      creativeQuery: 0,
      all: 0,
      taken: 0,
      fillChart: 0,
      fill: 0,
      dasharay: '0',
      potentialEarning: 0.0,
      potentialEarningTime: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };

    const potentialEarning = null;

    if (this.user?.id && !this.isUserEngagementStarted) {
      this.isUserEngagementStarted = true;
      this.refreshUserEngagement(updateTime, userEngagement, potentialEarning);
      this.refreshPotentialEarnings(potentialEarning);
    }
  }

  refreshUserEngagement(updateTime, userEngagement, potentialEarning): void {
    this.userService.getUserEngagement().subscribe(
      (res) => {
        this.refreshEngagementRetries = 0;
        updateTime = res.updated_at;
        const userEngagementCopy = Object.assign({}, userEngagement);
        userEngagementCopy.potentialEarningTime = Object.assign({}, userEngagement.potentialEarningTime);
        userEngagement = this.calculateUserEngagementTime(userEngagementCopy, res.updated_at);

        if (res.no_activity_found) {
          userEngagement.all = 0;
          userEngagement.taken = 0;
          userEngagement.fillChart = 0;
        } else {
          if (!res.total_proposal_bounty) {
            res.total_proposal_bounty = 0;
          }

          if (!res.reviewed_creative_query_bounty) {
            res.reviewed_creative_query_bounty = 0;
          }
          userEngagement.taken = res.total_proposal_bounty + res.reviewed_creative_query_bounty;
          userEngagement.fillChart = parseInt((res.total_engagement_percent * 100).toString(), 10);
          userEngagement.fill = userEngagement.fillChart * 2.6;
          userEngagement.dasharay = userEngagement.fill + ',9999';
        }

        userEngagement.likes = res.liked_proposal_review_count;
        userEngagement.likesRight = res.active_proposal_count;

        userEngagement.review = res.reviewed_proposal_count;
        userEngagement.reviewRight = res.active_proposal_count;

        userEngagement.creativeQuery = res.reviewed_creative_query_count;
        userEngagement.creativeQueryRight = res.active_creative_query_count;

        userEngagement.all = res.active_proposal_bounty + res.active_creative_query_bounty;

        this.authenticatedUserService.setUserEngagement(userEngagement);

        if (updateTime) {
          // Update again in 5 min from time of last update
          const dtUpdate = new Date(updateTime);
          dtUpdate.setMinutes(dtUpdate.getMinutes() + 5);
          const updateInMS = dtUpdate.getTime() - new Date().getTime() + 5000; // wait 5 seconds longer
          if (updateInMS > 0 && this.hasRefreshTimeout === false) {
            this.hasRefreshTimeout = true;
            this.engagementTimer = timer(updateInMS).subscribe(() => {
              this.hasRefreshTimeout = false;
              this.refreshUserEngagement(updateTime, userEngagement, potentialEarning);
              this.refreshPotentialEarnings(potentialEarning);
            });
          }
        }
      },
      (error) => {
        // If the service received 503 Service Temporarily Unavailable, this is intentional.
        // Try try refresh again in 10 seconds.
        if (error.status === 503 && this.refreshEngagementRetries < 5) {
          timer(10 * 1000).subscribe(() => {
            ++this.refreshEngagementRetries;
            this.refreshUserEngagement(updateTime, userEngagement, potentialEarning);
          });
        }
      }
    );
  }

  refreshPotentialEarnings(potentialEarning): void {
    // Also refresh potential earnings (tied to the engagement)
    this.userService.getUserPotentialEarnings().subscribe(
      (res) => {
        potentialEarning = res.total_proposal_tokens + res.total_creative_query_tokens;
        this.authenticatedUserService.setPotentialEarnings(potentialEarning);
      },
      (error) => {
        // If the service received 503 Service Temporarily Unavailable, this is intentional.
        // Try try refresh again in 10 seconds.
        if (error.status === 503 && this.refreshEarningsRetries < 5) {
          timer(10 * 1000).subscribe(() => {
            ++this.refreshEarningsRetries;
            this.refreshPotentialEarnings(potentialEarning);
          });
        }
      }
    );
  }

  stopUserEngagementRefresh(): void {
    if (this.engagementTimer) {
      this.engagementTimer.unsubscribe();
      this.engagementTimer = null;
    }
    this.isUserEngagementStarted = false;
  }

  calculateUserEngagementTime(info, time): void {
    const dtNow = new Date();
    const dtUpdate = new Date(time);
    dtUpdate.setHours(dtUpdate.getHours() + 1);
    const timeDiff = dtUpdate.valueOf() - dtNow.valueOf();
    info.potentialEarningTime.hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    info.potentialEarningTime.minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    info.potentialEarningTime.seconds = Math.floor((timeDiff / 1000) % 60);
    return info;
  }

  refreshPage(): void {
    window.location.reload();
  }

  checkRoute(): boolean {
    if (
      this.route.includes('login') ||
      this.route.includes('forgot') ||
      this.route.includes('register') ||
      this.route.includes('signup')
    ) {
      return false;
    }
    return true;
  }

  openPromptSubmissionDialog(): void {
    localStorage.setItem('stop_submission_prompt', 'true');
    this.dialogService.open(PromptSubmissionDialogComponent);
  }
}
