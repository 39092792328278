import { FilterConfig } from 'app/app.datatypes';

export const statusTypesConfig: FilterConfig[] = [
  {
    slug: 'all',
    name: 'All',
    id: 'all',
    selected: true,
  },
  {
    slug: 'approved',
    name: 'Approved',
    id: 'id_approved',
    selected: false,
  },
  {
    slug: 'waiting-for-submission',
    name: 'Draft',
    id: 'id_waiting-for-submission',
    selected: false,
  },
  {
    slug: 'denied',
    name: 'Denied',
    id: 'id_denied',
    selected: false,
  },
  {
    slug: 'to-be-moderated',
    name: 'To be moderated',
    id: 'id_to-be-moderated',
    selected: false,
  },
  {
    slug: 'finished',
    name: 'Finished',
    id: 'id_finished',
    selected: false,
  },
  {
    slug: 'cancelled',
    name: 'Cancelled',
    id: 'id_cancelled',
    selected: false,
  },
];

export const orderTypesConfig: FilterConfig[] = [
  {
    slug: 'newest-created',
    name: 'Newest',
    id: 'latest',
    selected: true,
  },
  {
    slug: 'latest-created',
    name: 'Oldest',
    id: 'latest',
    selected: false,
  },
];

export const sortingTypesConfig: FilterConfig[] = [
  { slug: 'latest', name: 'Latest', id: 'latest', selected: true },
  {
    slug: 'popularity',
    name: 'Popularity',
    id: 'popularity',
    selected: false,
  },
  { slug: 'rating', name: 'Rating', id: 'rating', selected: false },
  { slug: 'bounty', name: 'Rewards Pool', id: 'bounty', selected: false },
];

export const reviewedTypesConfig: FilterConfig[] = [
  {
    slug: 'all',
    name: 'All',
    id: 'all',
    selected: true,
  },
  {
    slug: 'reviewed_only',
    name: 'Reviewed',
    id: 'id_reviewed_only1',
    selected: false,
  },
  {
    slug: 'non_reviewed_only',
    name: 'Non reviewed',
    id: 'non_reviewed_only',
    selected: false,
  },
];

export const likedTypesConfig: FilterConfig[] = [
  {
    slug: 'all',
    name: 'All',
    id: 'all',
    selected: true,
  },
  {
    slug: 'liked_only',
    name: 'Liked',
    id: 'id_liked_only',
    selected: false,
  },
  {
    slug: 'non_liked_only',
    name: 'Non liked',
    id: 'non_liked_only',
    selected: false,
  },
];

export const EditorConfig = {
  placeholder: '',
  toolbar: ['Bold', 'link', 'Italic', 'BulletedList', 'NumberedList'],
  allowedContent: true,
};
export const DefaultFilters = {
  field: 'latest',
  reviewed: 'all',
  liked: 'all',
  genres: [],
  proposal_rounds: [],
  search: '',
  status: '',
  type: 'all',
};
