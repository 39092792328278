import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
  standalone: true,
})
export class OnlyNumberDirective implements OnInit {
  @Input() maxDecimals = 6;
  @Input() maxValue: number;
  // Allow decimal numbers. The \. is only allowed once to occur
  private regex: RegExp;

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {
    if (el.nativeElement.type === 'number') {
      // This will allow selection in form inputs of type number by adding setSelectionRange.
      // We can then get selectionStart and selectionEnd below to allow overwriting the selection
      (function (originalFn) {
        el.nativeElement.setSelectionRange = function () {
          this.type = 'text';
          originalFn.apply(this, arguments);
          this.type = 'number';
        };
      })(el.nativeElement.setSelectionRange);
    }
  }

  ngOnInit(): void {
    this.regex = new RegExp(`^\\d+.?\\d{0,${this.maxDecimals}}$`);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // This will allow copy and select all to happen without any issues.
    if (event.metaKey) {
      if (event.key.toLowerCase() === 'a' || event.key.toLowerCase() === 'c' || event.key.toLowerCase() === 'v') {
        return;
      }
    }
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const nativeEl = this.el.nativeElement;
    const current: string = nativeEl.value;
    let next: string;

    if (current.length > 0 && nativeEl.selectionStart === 0 && nativeEl.selectionEnd === current.length) {
      // Full selection, overwrite when all selected
      next = event.key;
    } else if (!this.regex.test(String(event.key)) && event.key != '.') {
      event.preventDefault();
    } else {
      next = current.concat(event.key);
    }

    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    if ((next && !this.regex.exec(next)) || (this.maxValue && Number(next) > this.maxValue)) {
      event.preventDefault();
    }
  }

  @HostListener('ngModelChange')
  onChange(): void {
    // If greater than MAX_DECIMALS, truncate to the proper number of decimals on paste
    // or on number input arrow clicks (anything that's not a keyboard input change)
    if (this.countDecimalDigits() > this.maxDecimals) {
      this.el.nativeElement.value = Number(Number(this.el.nativeElement.value).toFixed(this.maxDecimals));
    } else if (this.el.nativeElement.value < 0) {
      this.el.nativeElement.value = 0;
    }
  }

  countDecimalDigits(): number {
    const value = this.el.nativeElement.value;
    if (!value || Math.floor(value).toString() === value) {
      return 0;
    }
    return value.toString().split('.')[1]?.length || 0;
  }

  @HostListener('blur')
  onBlur(): void {
    if (this.el.nativeElement.value.toString().endsWith('0')) {
      // remove trailing zeros
      this.el.nativeElement.value = Number(this.el.nativeElement.value);
    }
  }
}
