import { Filters, Proposal } from '../../../app.datatypes';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { DefaultFilters } from 'app/shared/constant';

export interface ProposalState extends EntityState<Proposal>, ActiveState {
  ui: {
    showFilters: boolean;
  };

  moderationFilters: {
    unlockedOnly: boolean;
  };

  filters: Filters;

  addedProposal?: string;

  // Review Proposals  specific
  skip: number;
  scroll: number;
  apiEndReached: boolean;
  loaded: boolean;

  // Moderation Proposals  specific
  moderationProposalSkip: number;
  moderationProposalScroll: number;
  moderationProposalApiEndReached: boolean;
  moderationProposalLoaded: boolean;

  // User proposals specific
  userProposalSkip: number;
  userProposalScroll: number;
  userProposalActive: string;
  userProposalApiEndReached: boolean;
  userProposalLoaded: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'proposal', idKey: 'id', resettable: true })
export class ProposalStore extends EntityStore<ProposalState> {
  constructor() {
    super({
      ui: { showFilters: false },
      moderationFilters: {
        unlockedOnly: false,
      },
      filters: { ...DefaultFilters },
      skip: 0,
      scroll: 1,
      apiEndReached: false, // will track proposal which is not in state list

      loaded: false,
      moderationProposalLoaded: false,
      moderationProposalSkip: 0,
      moderationProposalScroll: 1,
      moderationProposalApiEndReached: false,

      userProposalLoaded: false,
      userProposalSkip: 0,
      userProposalScroll: 1,
      userProposalApiEndReached: false,
      // prevent loader showing on state initialization
      loading: false,
    });
  }

  resetShowHide(showFilters: boolean) {
    this.update({
      ui: { showFilters },
      skip: 0,
      scroll: 1,
      apiEndReached: false,
      filters: { ...DefaultFilters },
    });
  }
}
