import { Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { DistributionService } from '../../../../shared/services/distribution.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { panelIn, fade } from '../../../../app.animations';
import {
  CheckoutData,
  Distribution,
  ExternalCheckoutAction,
  ExternalCheckoutRequest,
  PricingFees,
  ProcessStatus,
  SubmitProposalRequest,
} from '../../../../app.datatypes';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticatedUser,
  AuthenticatedUserQuery,
  PaymentStateService,
  TezosWalletService,
  ToasterService,
} from '../../../../shared';
import { EMPTY, Observable, catchError, from, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../../../../../environments';
import { DistributionQuery } from '../../../../shared/state/distribution';
import { GalleryService } from '../../../../shared/services/gallery.service';

@Component({
  selector: 'app-distribution-create',
  templateUrl: './distribution-create.component.html',
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
    panelIn,
    fade,
  ],
})
export class DistributionCreateComponent implements OnInit {
  @Output() closeCreateSidebar = new EventEmitter();
  distribution: Distribution;
  isSavedDraft: boolean;
  createMode = true;
  user: AuthenticatedUser;
  submitFeeDollars: number;
  submitFeeFilm: number;
  resubmit: boolean;
  moderationFee: number;

  constructor(
    private readonly distributionService: DistributionService,
    protected route: ActivatedRoute,
    private readonly router: Router,
    private readonly paymentStateService: PaymentStateService,
    private readonly authenticatedUserQuery: AuthenticatedUserQuery,
    private readonly destroyRef: DestroyRef,
    private readonly toastService: ToasterService,
    private readonly tezosWalletService: TezosWalletService,
    protected distributionQuery: DistributionQuery,
    private readonly galleryService: GalleryService
  ) {
    this.user = this.authenticatedUserQuery.user;
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          if (params['id']) {
            this.createMode = false;
            this.isSavedDraft = true;
            return this.distributionService.getDistribution(params['id']).pipe(
              switchMap((disribution: Distribution) => {
                this.distribution = { ...disribution };
                this.distribution.isStepOneValid = true;
                this.isSavedDraft = true;
                this.resubmit = this.distribution?.blockchain_confirmed && !this.createMode;
                return this.getSubmissionFeePrice();
              })
            );
          } else {
            this.distribution = new Distribution();
            this.galleryService.setImageScaled(false);
            this.createMode = true;
            return this.getSubmissionFeePrice();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
        catchError(() => EMPTY)
      )
      .subscribe();
  }

  getSubmissionFeePrice(): Observable<PricingFees | []> {
    return this.paymentStateService.getFees().pipe(
      tap((fees) => {
        let fee = fees.video_distribution_submission_fee_in_dollar;
        if (this.resubmit) {
          fee = fees.video_distribution_resubmission_fee_in_dollar;
        }
        this.submitFeeDollars = fee;
        this.moderationFee = this.paymentStateService.calculateDollarToFilmPricing(
          fees.video_distribution_moderation_fee_in_dollar
        );
        this.submitFeeFilm = this.paymentStateService.calculateDollarToFilmPricing(this.submitFeeDollars);
      }),
      catchError(() => {
        return of<PricingFees | []>([]);
      })
    );
  }

  setStepOneData(data: Distribution): void {
    this.distribution = { ...this.distribution, ...data };
    if (this.distribution.isStepOneValid) {
      this.galleryService.setProposal(this.distribution);
    }
  }

  setStepThreeData(data: CheckoutData): void {
    this.distribution.isStepTwoValid = data.isValid;
    this.distribution['is_external_checkout'] = data.is_external_checkout;
  }

  moveToTab(tab: string): void {
    document.getElementById(tab).click();
  }

  saveDraft(): void {
    this.handleAccessType();
    const submission_fee = Number(this.submitFeeFilm?.toFixed(8));
    const merged = {
      ...this.distribution,
      submission_fee,
    };
    const errorHandler = () => {
      this.toastService.openToastr('There was a problem saving your project', 'Saving Error');
    };
    let observable = this.distributionService.saveDistributionAsDraft(merged);
    if (this.isSavedDraft) {
      observable = this.distributionService.updateDistributionDraft(this.distribution.id, merged);
    }
    observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (distribution: Distribution) => {
        this.distribution = distribution;
        this.distribution.isStepOneValid = true;
        this.isSavedDraft = true;
      },
      error: () => errorHandler,
    });
  }

  handleAccessType(): void {
    if (this.distribution['access_type'] === 'pay-to-watch') {
      delete this.distribution['reward_contribution'];
    } else {
      delete this.distribution['rental_price'];
      delete this.distribution['purchase_price'];
      delete this.distribution['royalty_map'];
    }
    this.distribution.languages = this.distribution.languages?.map((language) => {
      if (typeof language === 'object') {
        return language.iso;
      } else return language;
    });
    this.distribution.genres = this.distribution.genres?.map((genre) => {
      if (typeof genre === 'object') {
        return genre.slug;
      } else return genre;
    });
  }

  submit(): void {
    if (this.authenticatedUserQuery.isRegistrationComplete) {
      this.handleAccessType();
      this.getSubmissionFeePrice()
        .pipe(
          switchMap(() => {
            const submission_fee = this.submitFeeFilm ? Number(this.submitFeeFilm.toFixed(6)) : 0;
            const merged = {
              ...this.distribution,
              [this.resubmit ? 'resubmission_fee' : 'submission_fee']: submission_fee,
            };

            if (!merged.distribution_id) {
              merged.distribution_id = this.distribution.id;
            }
            return this.distributionService.prepareForSubmit(this.distribution.id, merged, this.resubmit).pipe(
              switchMap((distribution: Distribution) => {
                this.distribution = { ...distribution, isStepOneValid: true, isStepTwoValid: true };
                this.isSavedDraft = true;
                const submitReq = new SubmitProposalRequest();
                submitReq.proposalId = this.distribution.id;
                submitReq.nonce = TezosWalletService.randomNonce;

                if (environment.readOnlyMode) {
                  this.authenticatedUserQuery.openMaintainanceModal();
                  return;
                }

                return from(
                  this.tezosWalletService.submitResubmitVideoDistribution(
                    distribution.blockchain_id,
                    distribution.title,
                    this.user.wallet_address,
                    this.submitFeeFilm || 0,
                    distribution.moderation_fee,
                    distribution.reward_contribution || 0,
                    distribution.royalty_map,
                    {
                      title: distribution.title,
                      transactionId: !this.resubmit
                        ? distribution.submission_transaction
                        : distribution.resubmission_transaction,
                    },
                    submitReq.nonce
                  )
                ).pipe(
                  switchMap((signature) => {
                    submitReq.signature = signature.sig;
                    submitReq.pk = this.tezosWalletService.connectedWalletPublicKey;
                    return this.distributionService.submitDistribution(submitReq, this.resubmit);
                  })
                );
              })
            );
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe({
          next: (distribution: Distribution) => this.processSubmit(distribution),
          error: (error) => this.submitErrorHandler(error),
        });
    } else {
      this.authenticatedUserQuery.unAuthAction.next({ slug: 'Create Disribution', action: 'verify' });
    }
  }

  private processSubmit(distribution: Distribution): void {
    if (distribution?.status === ProcessStatus.WAITING_FOR_PAYMENT) {
      const externalCheckoutRequest = new ExternalCheckoutRequest();
      externalCheckoutRequest.action =
        this.createMode || !distribution.blockchain_confirmed
          ? ExternalCheckoutAction.DISTRIBUTION_SUBMIT
          : ExternalCheckoutAction.DISTRIBUTION_RESUBMIT;
      externalCheckoutRequest.reference_id = distribution.id;
      externalCheckoutRequest.payment_id = distribution.pending_payment_id;
      this.paymentStateService.externalCheckout$.emit(externalCheckoutRequest);
    } else {
      this.submitSuccessHandler(distribution);
    }
  }

  submitSuccessHandler(disribution: Distribution): void {
    this.toastService.openToastr(
      this.resubmit
        ? 'Your edit to your DCP+ Project has been submitted for moderation. Your changes will be merged once approved by a moderator. '
        : 'Thank you for your submission. Your project must be moderated before becoming visible to other users.',
      'Project Submitted',
      'info',
      null
    );
    this.closeCreateSidebar.emit();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/dcp-plus', disribution.id]));
  }

  submitErrorHandler(error: Error): void {
    this.toastService.openToastr(error['error']['message'], 'Submission Error', 'error', 3000);
  }
}
