import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { dropdown } from '../../../../app.animations';
import { ToasterService, WindowSizeService } from '../../../services';
import { AuthenticatedUser } from '../../../../shared/state/authenticated-user';
type itemType = 'project' | 'dcp-plus' | 'awards';
@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  animations: [dropdown],
})
export class ShareButtonComponent implements OnChanges {
  @Input() type: itemType = 'project';
  @Input() user: AuthenticatedUser;
  @Input() affiliateMarketing = false;
  @Input() id: string;
  shareLink = '';
  @Input() actionName: string;
  @Output() actions: EventEmitter<void> = new EventEmitter();
  constructor(private windowSizeService: WindowSizeService, private toasterService: ToasterService) {}
  unAuthAction: string;

  ngOnChanges(): void {
    this.shareLink = this.windowSizeService.getHostname() + this.type + '/' + this.id;
    if (this.affiliateMarketing) {
      this.shareLink = this.shareLink + '?a=' + this.user.id;
    }
  }

  copyMessage(isAction = false): void {
    if (isAction) {
      this.shareLink = this.shareLink + '?action=' + this.actionName;
    }
    navigator.clipboard
      .writeText(this.shareLink)
      .then((r) => {
        this.toasterService.show('Link copied to clipboard', '', 2000);
      })
      .catch((err) => console.dir(err));
  }

  mailMe(): void {
    window.location.href =
      'mailto:?subject=Share round link&body= Check out this round in Decentralized Pictures ' + this.shareLink;
  }
}
