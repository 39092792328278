import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private url = environment.url;
  ignoreListForSnack = [`${this.url}api/username-check`, `${this.url}api/oauth/token`];

  constructor(private http: HttpClient, private toasterService: ToasterService) {}

  /**
   * Generic http functions
   */
  public get(uri: string, options?, handleErrors = true): Observable<any> {
    return this.http
      .get(this.url + uri, options)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError((error) => (handleErrors ? this.catchError(error) : throwError(error))));
  }

  public post(url: string, data, options = {}, handleErrors = true): Observable<any> {
    return this.http
      .post(this.url + url, data, options)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError((error) => (handleErrors ? this.catchError(error) : throwError(error))));
  }

  public put(url: string, data, handleErrors = true): Observable<any> {
    return this.http
      .put(this.url + url, data)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError((error) => (handleErrors ? this.catchError(error) : throwError(error))));
  }

  public delete(uri: string, handleErrors = true): Observable<any> {
    return this.http
      .delete(this.url + uri)
      .pipe(
        map((response: any) => {
          return response?.data || response;
        })
      )
      .pipe(catchError((error) => (handleErrors ? this.catchError(error) : throwError(error))));
  }

  public postWithAttachment(url: string, data): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.url + url, data, { headers: headers, observe: 'events', reportProgress: true });
  }

  /**
   * Error handling
   */
  public catchError(error: HttpErrorResponse | undefined | null) {
    /** Display 422 validation errors */
    if (error?.status === 422 && error?.error?.errors) {
      this.toasterService.openValidationErrorsToastr('The given data was invalid.', Object.values(error.error.errors));
      return throwError(() => ({ ...error, error: { ...error.error, message: 'The given data was invalid.' } }));
    }
    return throwError(() => error);
  }

  getQueryString(filters: object): string {
    filters = Object.fromEntries(Object.entries(filters).filter(([, value]) => !!value && value != ''));
    return new URLSearchParams(filters as Record<string, string>).toString();
  }
}
