import { Injectable } from '@angular/core';
import { ProposalStore, ProposalState } from './proposal.store';
import { QueryEntity, Order } from '@datorama/akita';
import { ProcessStatus, Proposal } from '../../../app.datatypes';

@Injectable({ providedIn: 'root' })
export class ProposalQuery extends QueryEntity<ProposalState> {
  selectFilters$ = this.select('filters');
  selectModerationFilters$ = this.select('moderationFilters');
  showFilter$ = this.select((state) => state.ui.showFilters);

  // skips
  selectSkip$ = this.select('skip');
  selectModerationProposalSkip$ = this.select('moderationProposalSkip');
  selectUserProposalSkip$ = this.select('userProposalSkip');

  // Scroll
  selectScroll$ = this.select('scroll');
  selectModerationProposalScroll$ = this.select('moderationProposalScroll');
  selectUserProposalScroll$ = this.select('userProposalScroll');

  // Loaded status
  selectLoaded$ = this.select('loaded');
  selectModerationProposalLoaded$ = this.select('moderationProposalLoaded');
  selectUserProposalLoaded$ = this.select('userProposalLoaded');

  // Active
  selectUserProposalActive$ = this.select('userProposalActive');

  reviews$ = this.select((state) => state.reviews);
  proposalDetails$ = this.select((state) => state.proposalDetails);

  get filters() {
    return this.getValue().filters;
  }

  get moderationFilters() {
    return this.getValue().moderationFilters;
  }

  constructor(protected store: ProposalStore) {
    super(store);
  }

  selectActiveProposal() {
    return this.selectActive();
  }

  proposalSelectionQuery(): object {
    return {
      sortBy: (a, b) => {
        switch (this.getValue().filters.field) {
          case 'popularity':
            return a.votes_score < b.votes_score ? 1 : -1;
          case 'rating':
            return a.score < b.score ? 1 : -1;
          case 'bounty':
            return a.balance < b.balance ? 1 : -1;
        }
      },
      sortByOrder: Order.DESC,
      filterBy: (entity) => {
        let returnValue = entity.status === 'approved' || entity.status === 'finished';
        if (
          this.getValue().filters.genres?.length > 0 &&
          this.getValue().filters.genres[0] !== 'all' &&
          returnValue &&
          entity.genres?.length
        ) {
          returnValue = entity.genres?.some((genre) => this.getValue().filters.genres.includes(genre.slug));
        }

        if (
          this.getValue().filters.proposal_rounds?.length > 0 &&
          this.getValue().filters.proposal_rounds[0] !== 'all' &&
          returnValue
        ) {
          returnValue = this.getValue().filters.proposal_rounds.includes(entity.proposal_round_id);
        }

        // Search Filter
        if (this.getValue().filters.search?.length && returnValue) {
          const searchTerm = this.getValue().filters.search.toLowerCase();
          returnValue =
            entity.summary.toLowerCase().includes(searchTerm) ||
            entity.content.toLowerCase().includes(searchTerm) ||
            entity.title.toLowerCase().includes(searchTerm);
        }

        return returnValue;
      },
    };
  }

  // Select
  getAllApprovedProposal() {
    return this.selectAll(this.proposalSelectionQuery());
  }

  getAllModerationProposal(userId) {
    return this.selectAll({
      filterBy: (entity) => {
        let returnValue = entity.status === ProcessStatus.TO_BE_MODERATED && entity.user_id !== userId;

        if (this.getValue().moderationFilters.unlockedOnly) {
          returnValue = returnValue && !entity.locked_by;
        }
        return returnValue;
      },
      limitTo: this.getValue().moderationProposalSkip,
    });
  }

  getAllUserProposal(userId, limit = this.getValue().userProposalSkip) {
    return this.selectAll({
      filterBy: (entity) => entity.user_id === userId,
      limitTo: limit,
    });
  }

  getAllByRound(roundId) {
    return this.selectAll({
      filterBy: (entity) => entity.proposal_round_id === roundId && entity.status === 'approved',
    });
  }

  // GET Value
  getAllApprovedProposalValue() {
    return this.getAll(this.proposalSelectionQuery());
  }

  getAllModerationProposalValue() {
    return this.getAll({
      filterBy: (entity) => entity.status === 'to-be-moderated',
    });
  }

  getAllUserProposalValue(userId) {
    return this.getAll({ filterBy: (entity) => entity.user_id === userId });
  }

  getAllProposalIds() {
    return this.getAll().map((proposal) => proposal.id);
  }
  handleNextPreviousValues(id: string, allValues: Proposal[], next = true) {
    let currentIndex = allValues.findIndex((item) => item.id === id);

    if (next && currentIndex + 1 < allValues?.length) {
      return allValues[currentIndex + 1].id;
    } else if (!next && currentIndex - 1 >= 0) {
      return allValues[currentIndex - 1].id;
    } else {
      return null;
    }
  }

  getNextProposalValue(id: string) {
    return this.handleNextPreviousValues(id, this.getAllApprovedProposalValue());
  }

  getPreviousProposalValue(id: string) {
    return this.handleNextPreviousValues(id, this.getAllApprovedProposalValue(), false);
  }

  getNextUserProposalValue(id, userId) {
    return this.handleNextPreviousValues(id, this.getAllUserProposalValue(userId));
  }

  getPreviousUserProposalValue(id, userId) {
    return this.handleNextPreviousValues(id, this.getAllUserProposalValue(userId), false);
  }

  getActiveIndex() {
    return this.getAll().indexOf(this.getActive());
  }

  isLast() {
    return this.getAll().indexOf(this.getActive()) === this.getAll()?.length - 1;
  }

  /**
   *
   * @param id : Proposal Id
   */
  getReviews(id) {
    return this.selectEntity(id, (proposal) => proposal.reviews);
  }

  getReviewScroll(id: string) {
    return this.selectEntity(id, (proposal) => proposal.reviews_scroll);
  }

  getReviewSkip(id: string) {
    return this.selectEntity(id, (proposal) => proposal.reviews_skip);
  }
}
