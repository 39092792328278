import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AuthenticatedUser,
  AuthenticatedUserQuery,
  AuthenticatedUserService,
  SEOService,
  ToasterService,
  UserService,
  UserVerificationStatus,
} from '../../../shared';
import { debounceTime } from 'rxjs/operators';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { fade, openDown, panelIn } from '../../../app.animations';
import { ProfileUpdateRequest } from '../../../app.datatypes';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { countryCodes } from '../../../../assets/data/country-code';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  host: {
    class: 'fixed top-0 left-0 w-full h-full z-10',
  },
  animations: [openDown, fade, panelIn],

  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
  form: UntypedFormGroup;
  unamePattern = '^[a-zA-Z0-9._-]+$';
  loading = false;
  suggestion: string;
  isRegisterLoading = false;
  selectedCountryCode = 'us';
  countryCodes: string[];
  mobileVerificationCodeSent = false;
  emailVerificationCodeSent = false;
  phoneCode = '1';
  smsVerificationStatus: UserVerificationStatus;
  emailVerificationStatus: UserVerificationStatus;
  personalDetailsComplete: boolean;
  user: AuthenticatedUser;

  constructor(
    private authenticatedUserService: AuthenticatedUserService,
    private authenticatedUserQuery: AuthenticatedUserQuery,
    private userService: UserService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private toasterService: ToasterService,
    private _seoService: SEOService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) {}

  codeRegex = /^\d{6}$/;

  ngOnInit(): void {
    this._seoService.createTitleForPage();
    this.countryCodes = countryCodes.map((code) => code.twoLetterCode.toLowerCase());
    this.init();
  }

  init(): void {
    this.user = this.authenticatedUserQuery.user;
    this.smsVerificationStatus = this.user.sms_verification_status;
    this.emailVerificationStatus = this.user.email_verification_status;
    this.personalDetailsComplete = this.authenticatedUserQuery.isPersonalDetailsComplete;
    if (!this.user?.name) {
      this.form = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(this.unamePattern)]],
        termsAccepted: [false, [Validators.required]],
      });
      this.onChanges();
    } else {
      if (this.smsVerificationStatus === UserVerificationStatus.INCOMPLETE) {
        this.form = this.formBuilder.group({
          mobile: [null, [Validators.required]],
          mask: ['', Validators.required],
        });
      } else if (this.emailVerificationStatus === UserVerificationStatus.INCOMPLETE) {
        this.form = this.formBuilder.group({
          email: [this.user.email, [Validators.email, Validators.required]],
        });
        if (this.user.email && !this.emailVerified()) {
          this.form.addControl(
            'emailCode',
            new FormControl('', [Validators.pattern(this.codeRegex), Validators.required])
          );
          this.emailVerificationCodeSent = true;
        }
      } else if (this.personalDetailsComplete) {
        this.closePanel();
      }
    }
    this.form?.controls['mask']?.valueChanges.subscribe((value) => {
      this.setNewNumber(value);
    });
  }

  public smsVerified(): boolean {
    return this.smsVerificationStatus === UserVerificationStatus.COMPLETE;
  }

  public emailVerified(): boolean {
    return this.emailVerificationStatus === UserVerificationStatus.COMPLETE;
  }

  private setNewNumber(value): void {
    if (!value) {
      return;
    }
    this.form.controls['mobile'].setValue('+' + this.phoneCode + '' + value.replace(/[\D/]/g, ''));
  }

  closePanel(): void {
    if (this.router.url.includes('/login')) {
      this.router.navigate(['/']);
    }
    this.authenticatedUserQuery.unAuthAction.next(null);
  }

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
    this.phoneCode = countryCodes.find(
      (x) => x.twoLetterCode.toLowerCase() === this.selectedCountryCode.toLowerCase()
    ).countryCode;
    this.setNewNumber(this.form.value.mask);
  }

  confirmSMSVerificationCode(): void {
    this.isRegisterLoading = true;
    const code = this.form.value.code;
    if (code.toString().match(this.codeRegex)) {
      this.authenticatedUserService
        .confirmSMSMobileVerification(code)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.toasterService.openToastr(
              'SMS mobile phone has been verified successfully.',
              'Verified',
              'info',
              3000
            );
            this.isRegisterLoading = false;
            this.mobileVerificationCodeSent = false;
            this.init();
            this.cdr.detectChanges();
          },
          error: () => {
            this.form.get('code').setValue(null);
            this.form.get('code').markAsTouched();
            this.isRegisterLoading = false;
            this.cdr.detectChanges();
          },
        });
    }
  }

  sendSMSVerificationMessage(): void {
    this.isRegisterLoading = true;
    let mobile = this.form.value.mobile;
    if (!mobile.startsWith('+')) {
      mobile = `+${mobile}`;
    }
    this.authService
      .sendSMSMobileVerification(mobile)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isRegisterLoading = false;
          this.form.addControl('code', new FormControl('', [Validators.pattern(this.codeRegex), Validators.required]));
          this.mobileVerificationCodeSent = true;
          this.toasterService.openToastr(
            'SMS text message sent. Please check your phone for the code.',
            'Message Sent',
            'info',
            3000
          );
          this.cdr.detectChanges();
        },
        error: (error) => {
          if (error?.status !== 422) {
            this.toasterService.openErrorToastr(error.error.message, '', 'error', 3000);
          }
          this.isRegisterLoading = false;
          this.cdr.detectChanges();
        },
      });
  }

  sendEmailCode(): void {
    this.isRegisterLoading = true;
    let email = this.form.value.email;
    this.authService
      .sendEmailCode(email)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isRegisterLoading = false;
          this.form.addControl(
            'emailCode',
            new FormControl('', [Validators.pattern(this.codeRegex), Validators.required])
          );
          this.emailVerificationCodeSent = true;
          this.toasterService.openToastr('Email has been sent.', 'Email Sent', 'info', 3000);
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.toasterService.openErrorToastr(error.error.message, '', 'error', 3000);
          this.isRegisterLoading = false;
          this.cdr.detectChanges();
        },
      });
  }

  confirmEmailCode(): void {
    this.isRegisterLoading = true;
    const code = this.form.value.emailCode;
    if (code.toString().match(this.codeRegex)) {
      this.authService
        .verifyEmailCode(code)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.toasterService.openToastr('Email has been verified successfully.', 'Verified', 'info', 3000);
            this.authenticatedUserService.setUserEmailVerificationStatus(UserVerificationStatus.COMPLETE);
            this.isRegisterLoading = false;
            this.init();
            this.cdr.detectChanges();
          },
          error: () => {
            this.form.get('emailCode').setValue(null);
            this.form.get('emailCode').markAsTouched();
            this.isRegisterLoading = false;
            this.cdr.detectChanges();
          },
        });
    }
  }

  submit(): void {
    const inputEmail = this.form.value.email?.toLowerCase();
    const name = this.form.value.name.toLowerCase();
    const authRequest: ProfileUpdateRequest = {
      email: inputEmail,
      name: name,
      mobile: this.form.value.mobile,
    } as ProfileUpdateRequest;
    this.isRegisterLoading = true;
    this.authenticatedUserService
      .updateMissingProfile(authRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (user: AuthenticatedUser) => {
          this.isRegisterLoading = false;
          this.init();
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.isRegisterLoading = false;
          this.cdr.detectChanges();
        },
      });
  }

  onChanges(): void {
    this.form.controls['name'].valueChanges.pipe(debounceTime(500)).subscribe((val) => {
      if (val) {
        this.userService
          .checkUserName({ name: val })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
            (res) => {},
            (error) => {
              this.form.controls['name'].setErrors({
                notAvailable: true,
              });
            }
          );
      }
    });
  }

  suggestName(): void {
    this.loading = true;
    this.userService
      .suggestUserName({ name: this.form.controls['name'].value })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.suggestion = res.name_suggestion;
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.loading = false;
          this.cdr.detectChanges();
        },
      });
  }

  useSuggestedName(event): void {
    event.stopPropagation();
    this.form.controls['name'].setValue(this.suggestion);
  }
}
