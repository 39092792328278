import { Injectable } from '@angular/core';
import { AuthenticatedUserStore } from './authenticated-user.store';
import {
  AuthenticatedUser,
  AuthenticatedUserState,
  AuthenticationPassword,
  AuthenticationTokens,
  UnAuthAction,
} from './authenticated-user.state';
import { UserTypes, UserVerificationStatus } from '../../enumerations';
import { Query } from '@datorama/akita';
import { ToasterService } from '../../services/toaster.service';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TezosWalletType } from '../../services';

@Injectable({ providedIn: 'root' })
export class AuthenticatedUserQuery extends Query<AuthenticatedUserState> {
  unAuthAction: Subject<UnAuthAction> = new Subject();
  session$: Observable<AuthenticationTokens> = this.select((state) => state.tokens);
  isLoggedIn$: Observable<boolean> = this.select((state) => !!state.tokens?.access_token);

  authenticatedUser$: Observable<AuthenticatedUser> = this.select((state) => state.user).pipe(
    filter((value) => value !== undefined)
  );
  authenticatedUserNoFilter$: Observable<AuthenticatedUser | undefined> = this.select((state) => state.user);

  profileUrl$: Observable<string> = this.select((state) => state.user?.avatar_url);

  passwordDetails$: Observable<AuthenticationPassword> = this.select((state) => state.password);

  constructor(protected store: AuthenticatedUserStore, private readonly toastService: ToasterService) {
    super(store);
  }

  get user(): AuthenticatedUser {
    return this.getValue().user;
  }

  get tokens(): AuthenticationTokens {
    return this.getValue().tokens;
  }

  get password(): AuthenticationPassword {
    return this.getValue().password;
  }

  get balance() {
    return this.getValue().user?.balance ? this.getValue().user?.balance : null;
  }

  get externalBalance() {
    return this.getValue().user?.external_balance ? this.getValue().user?.external_balance : null;
  }

  get isEmailVerified() {
    return this.getValue().user?.email_verification_status === UserVerificationStatus.COMPLETE;
  }

  get isPhoneVerified(): boolean {
    return this.getValue().user?.sms_verification_status === UserVerificationStatus.COMPLETE;
  }

  get isZeroDayPaymentCompleted(): boolean {
    return this.getValue().user?.zero_day_payment_status === 'completed';
  }

  get isKycRegistered() {
    return this.getValue().user?.kyc_verification_status === UserVerificationStatus.COMPLETE;
  }

  get isModerator() {
    return this.getValue().user?.user_type_id >= UserTypes.MODERATOR;
  }

  get isAdministrator() {
    return this.getValue().user?.user_type_id >= UserTypes.ADMINISTRATOR;
  }

  get role(): string {
    return this.getRoleById(this.getValue().user?.user_type_id);
  }

  get isDiscordEnabled() {
    return this.getValue().user?.discord_user_id !== null && this.getValue().user?.discord_user_id !== undefined;
  }

  get isRegistrationComplete(): boolean {
    return this.isEmailVerified && this.isPhoneVerified && this.isPersonalDetailsComplete;
  }

  get isPersonalDetailsComplete(): boolean {
    return !!(
      this.getValue() &&
      (this.user.age_group || this.user.dob) &&
      this.user.ethnicity &&
      this.user.country &&
      this.user.languages
    );
  }

  get isProfileComplete(): boolean {
    return !!(
      this.getValue() &&
      this.getValue().user?.first_name &&
      this.getValue().user?.last_name &&
      this.getValue().user?.sex &&
      this.getValue().user?.dob &&
      this.getValue().user?.zip_postal &&
      this.getValue().user?.country &&
      this.getValue().user?.street
    );
  }

  getRoleById(user_type_id: number): string {
    switch (user_type_id) {
      case UserTypes.USER:
        return 'member';
      case UserTypes.MODERATOR:
        return 'moderator';
      case UserTypes.ADMINISTRATOR:
        return 'administrator';
    }
  }

  isOwner(id): boolean {
    if (id === this.getValue().user?.id) {
      return true;
    } else {
      return false;
    }
  }

  openMaintainanceModal() {
    this.toastService.openErrorToastr(
      'The blockchain is temporarily unavailable/offline for maintenance.',
      'BlockChain Disabled'
    );
  }

  get walletPublicAddress() {
    return this.getValue() ? this.getValue().user?.wallet_address : null;
  }

  get clientWallet() {
    if (!this.getValue()) {
      return null;
    }
    return localStorage.getItem(`${this.getValue().user?.wallet_address}`);
  }

  get walletType(): string {
    return this.getValue().user?.wallet_type ? this.getValue().user?.wallet_type : TezosWalletType.KUKAI;
  }

  get visiblePublicProfileData() {
    return this.getValue().user?.visible_public_profile_data
      ? Object.values(this.getValue().user?.visible_public_profile_data)
      : [];
  }

  /**
   *  Utility functions
   */

  openLowBalanceSheet(minBuy: number, redirectUri = '/proposal'): void {
    const data = { redirectUri, minBuy };
    this.toastService.openToastrWithLowBalanceSheet('Low film balance!', 'error', data);
  }
}
