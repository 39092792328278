import { AuthGuardService } from './shared';
import { ProposalCreateComponent } from './containers/proposal/proposal-create/proposal-create.component';
import { QueryCreateComponent } from './containers/creative-query/query-create/query-create.component';
import { LimitRepGuardService } from './shared/services/limit-rep-guard.service';
import { LimitCqGuardService } from './shared/services/limit-cq-guard.service';
import { LimitProposalGuardService } from './shared/services/limit-proposal-guard.service';
import { LimitSwagGuardService } from './shared/services/limit-swag-guard.service';
import { DistributionCreateComponent } from './containers/distribution/distribution-create/distribution-create/distribution-create.component';
import { BetaTesterGuard } from './containers/distribution/betatester.guard';

export const AppRoutes = [
  {
    path: '',
    canActivate: [LimitProposalGuardService],
    loadChildren: () => import('./containers/proposal/proposal.module').then((m) => m.ProposalModule),
    data: {
      state: 'app-proposal-create',
      title: 'DCP - Edit Proposal',
      description: 'Edit a Proposal',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'project/:id/edit',
    component: ProposalCreateComponent,
    canActivate: [AuthGuardService, LimitProposalGuardService],
    data: {
      state: 'app-proposal-create',
      title: 'DCP - Edit Proposal',
      description: 'Edit a Proposal',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'proposal/:id/edit',
    redirectTo: 'project/:id/edit',
  },
  {
    path: 'dcp-plus/:id/edit',
    canActivate: [BetaTesterGuard],
    component: DistributionCreateComponent,
    data: {
      title: 'DCP - Distribution',
      description: 'distribution',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'creative-query/:id/edit',
    component: QueryCreateComponent,
    canActivate: [AuthGuardService, LimitCqGuardService],
    data: {
      title: 'DCP - Creative query create',
      description: 'Creative query create',
      ogUrl: 'http://localhost/',
    },
  },
  {
    path: 'leader-board',
    loadChildren: () => import('./containers/reputation-board/reputation.module').then((m) => m.ReputationModule),
    canActivate: [AuthGuardService, LimitRepGuardService],
  },
  {
    path: 'academy',
    loadChildren: () => import('./containers/academy/academy.module').then((m) => m.AcademyModule),
  },
  {
    path: 'moderate',
    loadChildren: () => import('./containers/moderation/moderation.module').then((m) => m.ModerationModule),
    canActivate: [AuthGuardService, LimitProposalGuardService],
  },
  {
    path: 'quiz',
    loadChildren: () => import('./containers/quiz/quiz.module').then((m) => m.QuizModule),
  },
  {
    path: 'administration',
    loadChildren: () => import('./containers/administration/administration.module').then((m) => m.AdministrationModule),
  },
  {
    path: 'awards',
    loadChildren: () => import('./containers/proposal-round/proposal-round.module').then((m) => m.ProposalRoundModule),
  },
  {
    path: 'round',
    redirectTo: 'awards',
  },
  {
    path: 'auth',
    loadChildren: () => import('./containers/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./containers/account/accountnew.module').then((m) => m.AccountnewModule),
  },
  {
    path: 'verify',
    loadChildren: () => import('./containers/verify/verify.module').then((m) => m.VerifyModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./containers/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'creative-query',
    loadChildren: () => import('./containers/creative-query/query.module').then((m) => m.QueryModule),
  },
  {
    path: 'publicprofile/:id',
    loadChildren: () =>
      import('./shared/components/public-profile/public-profile.module').then((m) => m.PublicProfileModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./containers/proposal/user-proposals-list/user-proposals.module').then((m) => m.UserProposalsModule),
  },
  {
    path: 'dcp-plus',
    canActivate: [BetaTesterGuard],
    loadChildren: () => import('./containers/distribution/distribution.module').then((m) => m.DistributionModule),
  },
  {
    path: 'store',
    canActivate: [LimitSwagGuardService],
    loadChildren: () => import('./containers/swag-store/swag-store.module').then((m) => m.SwagStoreModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./containers/cart-checkout/cart.module').then((m) => m.CartModule),
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: '404',
    loadChildren: () => import('./containers/errors/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
];
